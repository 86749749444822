import styled from 'styled-components'
import { ListGroup, ListGroupItem, Row, Col } from 'react-bootstrap'
import { Typography, colors } from '../../style'

export const TOCListGroup = styled(ListGroup)`
  padding-top: 40px;

  &#scroll-to-top {
    position: fixed;
    bottom: 0;
    left: 2rem;
    margin-left: auto;
    width: 400px;
    filter: drop-shadow(0 ${(props) => (props.offset ? 24 : 0)}px 0.85rem ${colors.TERTIARY_CHARCOAL_20});
    z-index: 2;
  }
`

export const TOCListGroupItem = styled(ListGroupItem)`
  border: 0;
  border-left: 4px solid ${colors.PRIMARY_RED_100};
  border-radius: 0;
  background-color: transparent;

  :first-child {
    border-top-left-radius: 0;
    padding-top: 0 !important;
  }

  &.list-item-anchor {
    text-decoration: none;
    color: ${colors.TERTIARY_CHARCOAL_140};
  }

  &.list-item-anchor-general {
    text-decoration: none;
    color: ${colors.TERTIARY_CHARCOAL_140};
    border: 0 !important;
    border-left: 0 !important;
    margin-bottom: 20px;
  }

  :last-child {
    border-bottom-left-radius: 0;
    border: 0 !important;
    border-left: 0 !important;
  }
`

export const TOCGeneralHeader = styled(Typography.NavHeader)`
  padding: 0;

  :hover {
    text-decoration: underline;
    color: ${colors.PRIMARY_RED_100};
  }
`

export const TOCSectionHeader = styled(Typography.BodyBold)`
  padding: 0 0 0 35px;

  :hover {
    text-decoration: underline;
    color: ${colors.PRIMARY_RED_100};
  }
`

export const TOCImgGeneral = styled.img`
  position: relative;
  left: -10px;
  top: 0;
`

export const TOCRow = styled(Row)``

export const TOCCol = styled(Col)`
  vertical-align: middle;
`
