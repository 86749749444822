import { Col, Container, Row } from 'react-bootstrap'
import { AccreditedImage, Body, DesktopCTA, Images, MobileCTA, RailcardImage, Section, Subtitle, Title } from './RailcardsSection.styles'
import { formatImageSet } from '../../../util/helpers/image'
import railcard1x from '../../../img/railcards-graphic.png'
import railcard2x from '../../../img/railcards-graphic@2x.png'
import railcard3x from '../../../img/railcards-graphic@3x.png'
import nationalRailAccredited from '../../../img/graphic-national-rail-accredited-blue.svg'
import { PATHS } from '../../../util/helpers/paths'

export const RailcardsSection = () => (
  <Section>
    <Container>
      <Row>
        <Col>
          <Title>Ready for a more rewarding Railcard?</Title>
          <Subtitle>We are an official retailer of Railcards.</Subtitle>
          <Body>
            Buy from us to get 1/3 off eligible train tickets. Plus, you’ll earn Virgin Points which can save you money on future travel, as
            well as unlocking a mountain of rewards with Virgin Red. Be a Railcard smarty pants!
          </Body>
          <DesktopCTA to={PATHS.RAILCARDS}>Learn more about Railcards</DesktopCTA>
        </Col>
        <Col lg={{ span: 5, offset: 1 }}>
          <Images>
            <RailcardImage srcSet={formatImageSet([railcard1x, railcard2x, railcard3x])} alt="Mockup of a digital railcard" />
            <AccreditedImage src={nationalRailAccredited} alt="National Rail accredited" />
            <MobileCTA to={PATHS.RAILCARDS}>Learn more about Railcards</MobileCTA>
          </Images>
        </Col>
      </Row>
    </Container>
  </Section>
)
