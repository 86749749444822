import { colors, media, Typography } from '../../style'
import styled from 'styled-components'
import { Col } from 'react-bootstrap'

export const HeaderSection = styled.section`
  background: ${colors.PRIMARY_RED_100};
  box-shadow: 0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  padding-bottom: 36px;
  position: relative;
  z-index: 100;

  @media ${media.desktopAndHigher} {
    padding-bottom: 48px;
  }
`

export const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
`

export const HeaderTitle = styled(Typography.H1White)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 32px;
  }
`

export const HeaderText = styled(Typography.IntroBoldWhite)`
  margin-bottom: 24px;
`

export const HeaderImage = styled.img`
  height: 138px;
  margin: 24px 0 38px;
`

export const HeaderImageMobile = styled.img`
  margin: 18px 0 30px;
`

export const HeaderOfficialRetailerImage = styled.img`
  align-self: center;

  @media ${media.desktopAndHigher} {
    align-self: flex-start;
  }
`

export const HeaderIllustration = styled.img`
  position: absolute;
  bottom: 48px;
  right: 0;
  width: 40%;
  max-width: 610px;

  @media ${media.belowDesktop} {
    display: none;
  }
`

export const HeaderPromoIllustration = styled.img`
  position: absolute;
  bottom: -12px;
  width: 40%;
  max-width: 550px;
  margin-left: 48px;

  @media ${media.belowDesktop} {
    display: none;
  }
`

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
`

export const CTAButton = styled(Typography.Button1)`
  margin-top: 36px;
  margin-bottom: 12px;
  width: 267px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 14px;
  }
`

export const WhiteCTAButton = styled(Typography.Button1White)`
    margin-top: 32px;
    @media ${media.desktopAndHigher} {
        margin: 0 0 0 36px;
        width: 267px;  
    }
`
