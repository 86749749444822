const {
  REACT_APP_ENV: ENV,
  REACT_APP_ANDROID_STORE_URL: ANDROID_STORE_URL,
  REACT_APP_IOS_STORE_URL: IOS_STORE_URL,
  REACT_APP_COOKIE_DOMAIN: COOKIE_DOMAIN,
  REACT_APP_CHEAP_TICKETS_LINK: CHEAP_TICKETS_LINK,
  REACT_APP_SPLIT_TICKETS_LINK: SPLIT_TICKETS_LINK,
  REACT_APP_REDEMPTION_LINK: REDEMPTION_LINK,
  REACT_APP_RAILCARDS_LINK: RAILCARDS_LINK,
  REACT_APP_RAILCARDS_OPEN_OR_DOWNLOAD_LINK: RAILCARDS_OPEN_OR_DOWNLOAD_LINK,
  REACT_APP_PURCHASE_RAILCARD_LINK: PURCHASE_RAILCARD_LINK,
  REACT_APP_FEATURE_CHEAP_TICKETS: FEATURE_CHEAP_TICKETS,
  REACT_APP_FEATURE_SPLIT_TICKETS: FEATURE_SPLIT_TICKETS,
  REACT_APP_FEATURE_REDEMPTION: FEATURE_REDEMPTION,
  REACT_APP_FEATURE_RAILCARDS: FEATURE_RAILCARDS,
  REACT_APP_FEATURE_RAILCARDS_PROMO: FEATURE_RAILCARDS_PROMO,
  REACT_APP_RAILCARD_TANDC_LINK: RAILCARD_TANDC_URL
} = process.env

const FEATURE_FLAGS = {
  cheapTicketsPage: FEATURE_CHEAP_TICKETS === 'true',
  splitTicketsPage: FEATURE_SPLIT_TICKETS === 'true',
  redemptionPage: FEATURE_REDEMPTION === 'true',
  railcardsPage: FEATURE_RAILCARDS === 'true',
  railcardsPromo: FEATURE_RAILCARDS_PROMO === 'true',
}

export {
  ANDROID_STORE_URL,
  ENV,
  IOS_STORE_URL,
  COOKIE_DOMAIN,
  FEATURE_FLAGS,
  CHEAP_TICKETS_LINK,
  SPLIT_TICKETS_LINK,
  REDEMPTION_LINK,
  RAILCARDS_LINK,
  RAILCARDS_OPEN_OR_DOWNLOAD_LINK,
  PURCHASE_RAILCARD_LINK,
  RAILCARD_TANDC_URL,
}
