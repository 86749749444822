import React from 'react'
import {
  FixedContentContainer,
  CtaTextDesktop,
  FixedContainer,
  Section,
  StoreImage,
  StoreButtonsContainer,
  VTTLogoSmall,
  VTTLogoLarge,
  VTTLogoLink,
  BreadcrumbContainer,
} from './FixedHeader.styles'
import { ANDROID_STORE_URL, IOS_STORE_URL } from '../../config'
import vttLogoHorizontalOnRed from '../../img/vtt-logo-horizontal-red-gradient.png'
import vttLogoSmallHorizontalOnRed from '../../img/tarrow-mini.svg'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import { isIOS, isAndroid } from '../../util/helpers/os'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { Col, Container, Row } from 'react-bootstrap'

export const FixedHeader = () => {
  return (
    <>
      <FixedContainer isVisible={true} transitionSpeed={0}>
        <FixedContentContainer>
          <Section className="d-lg-inline">
            <VTTLogoLink to={'/'} title={'Virgin Trains Ticketing'} className="d-none d-lg-inline">
              <VTTLogoLarge
                src={vttLogoHorizontalOnRed}
                alt="VTT Logo"
                isVisible={true}
                transitionSpeed={0}
              />
            </VTTLogoLink>
            <VTTLogoLink aria-label='VTT Logo' to={'/'} className="d-lg-none d-lg-inline">
              <VTTLogoSmall
                src={vttLogoSmallHorizontalOnRed}
                alt="VTT Logo"
                isVisible={true}
                transitionSpeed={0}
              />
            </VTTLogoLink>
            <CtaTextDesktop className="d-lg-none d-lg-inline">Download the&nbsp;app&nbsp;now!</CtaTextDesktop>
          </Section>
          <Section></Section>
          <Section>
            <CtaTextDesktop className="d-none d-lg-inline">Download the Virgin Trains Ticketing&nbsp;app&nbsp;now!</CtaTextDesktop>
            <StoreButtonsContainer>
              {!isAndroid() && (
                <a aria-label='Download on the App Store' href={IOS_STORE_URL} target="_blank" rel="noopener noreferrer">
                <StoreImage
                  src={appStoreButton}
                  alt="Download on the App Store"
                />
              </a>
              )}
              {!isIOS() && (
                <a aria-label='Get it on Google Play' href={ANDROID_STORE_URL} target="_blank" rel="noopener noreferrer">
                  <StoreImage
                    src={googlePlayButton}
                    alt="Get it on Google Play"
                  />
                </a>
              )}
            </StoreButtonsContainer>
          </Section>
        </FixedContentContainer>
        <BreadcrumbContainer>
          <Container>
            <Row>
              <Col>
                <Breadcrumb items={[{ text: 'General Terms and Conditions', url: '' }]} inHeader />
              </Col>
            </Row>
          </Container>
        </BreadcrumbContainer>
      </FixedContainer>
    </>
  )
}
