import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import { FontFamilies } from '../../style/styles'

export const StyledSection = styled.footer`
  background-color: ${colors.COLOUR_WHITE};
  padding-bottom: 59px;
  @media ${media.desktopAndHigher} {
    padding-bottom: 55px;
  }
`

export const LinkContainer = styled.div`
  margin-top: 61px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 34px;
  }
`

export const VTTLogo = styled.img`
  width: 150px;
  height: 169px;
  margin: -13px 0 24px 0;
  @media ${media.desktopAndHigher} {
    margin: -18px 0 0 -12px
  }
}
`

export const StyledBody = styled(Typography.Body)`
  @media ${media.desktopAndHigher} {
    max-width: 364px;
  }
`

export const VirginButton = styled(Typography.Button1Outline)`
  margin-top: 32px;

  @media ${media.desktopAndHigher} {
    margin-top: 28px;
    width: 269px;
  }
`

export const LinkWrapper = styled.a`
  text-decoration: none;
`

export const LinkText = styled(Typography.BodyBold)`
  text-align: center;
  @media ${media.desktopAndHigher} {
    text-align: unset;
  }

  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    color: ${colors.PRIMARY_RED_100};
  }

  &:active {
    color: ${colors.PRIMARY_RED_A60};
  }
`

export const FooterLink = styled.a`
  text-align: center;
  display: block;
  margin-bottom: 12px;
  cursor: pointer;
  text-decoration: none;
  font-family: ${FontFamilies.barlow};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
  color: ${colors.TERTIARY_CHARCOAL_140};

  &:hover {
    color: ${colors.PRIMARY_RED_100};
  }

  &:active {
    color: ${colors.PRIMARY_RED_A60};
  }

  @media ${media.desktopAndHigher} {
    text-align: unset;
  }
`

export const CopyrightText = styled(Typography.Body)`
  text-align: center;
  margin-top: 40px;
`
