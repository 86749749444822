import React from 'react'
import { ScrollableBar } from '../../components/ScrollableBar/ScrollableBar'
import { Col, Container, Row } from 'react-bootstrap'
import { Typography } from '../../style'
import {
  HeaderButtonContainer,
  HeaderSection,
  HeaderTitle,
  ScrollContainer,
  Section1,
  Section1Images,
  Section1Intro,
  Section1IntroBold,
  Section1Title,
  Section2,
  Section2Body,
  Section2ItemTitle,
  Section2Title,
  Section3,
  Section3Item,
  Section3Title,
  Section4,
  Section4Button,
  Section4Image,
  Section4Title,
} from './SplitTickets.styles'
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb'
import { formatImageSet } from '../../util/helpers/image'
import { FooterSection } from '../../components/FooterSection'
import { StyledStoreImage, VTTLogo, VTTLogoPlaceholder } from '../../components/HeaderSection/HeaderSection.styles'
import { SPLIT_TICKETS_LINK } from '../../config'
import { StickyHeader } from '../../components/StickyHeader'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'
import manWithFlowers from '../../img/illus-man-with-flowers.png'
import manWithFlowers2x from '../../img/illus-man-with-flowers@2x.png'
import manWithFlowers3x from '../../img/illus-man-with-flowers@3x.png'
import splitTicketDesktop from '../../img/virgin-trains-ticketing-split-ticket-desktop.png'
import splitTicketDesktop2x from '../../img/virgin-trains-ticketing-split-ticket-desktop@2x.png'
import splitTicketDesktop3x from '../../img/virgin-trains-ticketing-split-ticket-desktop@3x.png'
import splitTicketMobile from '../../img/virgin-trains-ticketing-split-ticket-mobile.svg'
import iconSplitTicketing from '../../img/icon-split-ticketing-vtt-st-identity.svg'
import { ScrollImage, ScrollItem } from '../../components/ScrollableBar/ScrollableBar.styles'
import iconBrandTicket from '../../img/icon-brand-ticket.svg'
import threePointsPer from '../../img/graphic-3-points-per.svg'
import iconBrandVttVrPoints from '../../img/icon-brand-vrvtt-points.svg'
import splitTicketingIcon from '../../img/icon-vtt-split-ticket.svg'
import illustrationRunningHero from '../../img/need-help-illustration-running-hero.svg'
import splitTicketScreenshots from '../../img/split-ticket-image.png'
import splitTicketScreenshots2x from '../../img/split-ticket-image@2x.png'
import splitTicketScreenshots3x from '../../img/split-ticket-image@3x.png'
import { AppLinksSection } from '../../components/AppLinksSection/AppLinksSection'
import { PATHS } from '../../util/helpers/paths'
import { MetaTags } from '../../components/MetaTags'
import { isAndroid, isIOS } from '../../util/helpers/os'

const breadcrumbItems = [
  { text: 'Find cheap train tickets', url: PATHS.CHEAP_TICKETS },
  { text: 'Split tickets', url: '' },
]

export const SplitTickets = () => (
  <>
    <MetaTags
      title="Virgin Trains Ticketing | Split tickets"
      description="Split tickets, trainsplit or split train tickets? It's all the same to us. Get the same journey for less with split tickets from Virgin Trains Ticketing."
    />
    <StickyHeader
      breadcrumb={breadcrumbItems}
      desktopText={
        <>
          Get the same journey for less with split tickets
          <br />
          Download the app today!
        </>
      }
      isLogoClickable
      storeUrl={SPLIT_TICKETS_LINK}
    />
    <HeaderSection>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center justify-content-lg-start">
            <a aria-label='VTT Logo' href="/">
              <VTTLogo className="d-lg-none" src={vttLogoDiagonal} alt="VTT Logo" />
            </a>
            <VTTLogoPlaceholder className="d-none d-lg-block" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6}>
            <HeaderTitle>Split tickets</HeaderTitle>
            <Typography.IntroBoldWhite>
              Get the same journey for less with split tickets from Virgin Trains Ticketing. Download the app and book today!
            </Typography.IntroBoldWhite>
          </Col>
          <Col lg={{ span: 4, offset: 2 }} className="justify-content-center">
            <HeaderButtonContainer>
              {!isAndroid() && (
                <a aria-label='Download on the App Store' href={SPLIT_TICKETS_LINK} target="_blank" rel="noopener noreferrer">
                  <StyledStoreImage src={appStoreButton} alt="Download on the App Store" />
                </a>
              )}
              {!isIOS() && (
                <a aria-label='Get it on Google Play' href={SPLIT_TICKETS_LINK} target="_blank" rel="noopener noreferrer">
                  <StyledStoreImage src={googlePlayButton} alt="Get it on Google Play" />
                </a>
              )}
            </HeaderButtonContainer>
          </Col>
        </Row>
      </Container>
    </HeaderSection>
    <ScrollContainer>
      <ScrollableBar>
        <ScrollItem>
          <ScrollImage src={iconBrandTicket} alt="" />
          <Typography.BodyBold>Book tickets for any train across Great Britain</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={threePointsPer} alt="" />
          <Typography.BodyBold>Earn Virgin Points with every £1 you spend</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={iconBrandVttVrPoints} alt="" />
          <Typography.BodyBold>Use your points to unlock discounts on future train travel</Typography.BodyBold>
        </ScrollItem>
      </ScrollableBar>
    </ScrollContainer>
    <Section1>
      <Container>
        <Row>
          <Col>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="d-none d-lg-block">
            <Section1Images>
              <img
                srcSet={formatImageSet([manWithFlowers, manWithFlowers2x, manWithFlowers3x])}
                width="510"
                height="574"
                alt="App screenshot showing split ticket saving and discounting cost with Red points"
              />
            </Section1Images>
          </Col>
          <Col lg={6}>
            <Section1Title>What is split ticketing?</Section1Title>
            <Section1Images className="d-lg-none">
              <img
                srcSet={formatImageSet([manWithFlowers, manWithFlowers2x, manWithFlowers3x])}
                width="510"
                height="313"
                alt="App screenshot showing split ticket saving and discounting cost with Red points"
              />
            </Section1Images>
            <Section1IntroBold>
              Split ticketing is when you reduce the cost of a train journey by splitting it into multiple cheaper tickets, instead of just
              one.
            </Section1IntroBold>
            <Section1Intro>
              It might sound weird at first, but the split tickets feature in our app makes it super simple to use split ticketing to get
              big discounts on train travel.
            </Section1Intro>
          </Col>
        </Row>
      </Container>
    </Section1>
    <Section2>
      <Container>
        <Row>
          <Col lg={{ span: 6 }}>
            <Section2Title>How do split tickets work?</Section2Title>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 5 }} className="d-flex flex-column">
            <Section2ItemTitle>
              <img src={iconSplitTicketing} alt="Split Tickets" />
            </Section2ItemTitle>
            <Typography.BodyBold>
              <p>Let’s use a real-life example of a journey from Doncaster to London King’s Cross.</p>
            </Typography.BodyBold>
            <img
              className="d-lg-none align-self-center"
              src={splitTicketMobile}
              width="324"
              height="205"
              alt="Illustration showing the breakdown of a split ticket"
            />
            <Section2Body>
              <p>
                Instead of getting one ticket from Doncaster to London King’s Cross, a split ticket gets you two train tickets for the same
                journey. One from Doncaster to Grantham and the other from Grantham to London King’s Cross.
              </p>
              <p>
                You won’t need to change trains at Grantham, your train just needs to stop there along the way. The journey is the same as
                if you bought a regular ticket, but this time gets you a saving of 23%!
              </p>
            </Section2Body>
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="d-none d-lg-block">
            <img
              srcSet={formatImageSet([splitTicketDesktop, splitTicketDesktop2x, splitTicketDesktop3x])}
              width="562"
              height="262"
              alt="Illustration showing the breakdown of a split ticket"
            />
          </Col>
        </Row>
      </Container>
    </Section2>
    <Section3>
      <Container>
        <Row>
          <Col xs={{ order: 2 }} lg={{ span: 6, order: 1 }} className="d-flex flex-column">
            <img
              className="align-self-center align-self-lg-auto"
              srcSet={formatImageSet([splitTicketScreenshots, splitTicketScreenshots2x, splitTicketScreenshots3x])}
              width="501"
              height="548"
              alt="Illustration showing the breakdown of a split ticket"
            />
          </Col>
          <Col xs={{ order: 1 }} lg={{ span: 6, order: 2 }}>
            <Row>
              <Col>
                <Section3Title>How to book split tickets in the Virgin Trains Ticketing app</Section3Title>
              </Col>
            </Row>
            <Row>
              <Col lg={10}>
                <Section3Item>
                  <Typography.H3Red as="span">1.</Typography.H3Red>
                  <Typography.Body>
                    Open up the Virgin Trains Ticketing app or download the app if you don’t have it already.
                  </Typography.Body>
                </Section3Item>
                <Section3Item>
                  <Typography.H3Red as="span">2.</Typography.H3Red>
                  <Typography.Body>Search for your journey as normal.</Typography.Body>
                </Section3Item>
                <Section3Item>
                  <Typography.H3Red as="span">3.</Typography.H3Red>
                  <Typography.Body>
                    If split tickets are available on your journey, you’ll see them in your search results — look out for the split ticket
                    icon: <img src={splitTicketingIcon} alt="Split Ticketing icon" />
                  </Typography.Body>
                </Section3Item>
                <Section3Item>
                  <Typography.H3Red as="span">4.</Typography.H3Red>
                  <Typography.Body>
                    We’ll show you a breakdown of your journey in the app before and after you buy your split tickets. Make sure you show
                    the right ticket for each part of your journey.
                  </Typography.Body>
                </Section3Item>
                <Section3Item>
                  <Typography.H3Red as="span">5.</Typography.H3Red>
                  <Typography.Body>
                    Store your split tickets as eTickets in our app and add them to your Apple or Google Wallet for easy access.
                  </Typography.Body>
                </Section3Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section3>
    <Section4>
      <Container>
        <Row>
          <Col className="d-flex flex-column">
            <Section4Title>Need help on split tickets?</Section4Title>
            <Typography.BodyBoldWhite>
              Read answers to some of the most frequently asked questions about split ticketing and split tickets.
            </Typography.BodyBoldWhite>
            <img className="d-lg-none" src={illustrationRunningHero} alt="" width="560" height="321" />
            <Section4Button href="https://trains.virgin.com/hc/en-gb/sections/11169147530129-Split-Tickets">
              Learn more about split tickets
            </Section4Button>
          </Col>
          <Col className="d-none d-lg-block">
            <Section4Image src={illustrationRunningHero} alt="" />
          </Col>
        </Row>
      </Container>
    </Section4>
    {SPLIT_TICKETS_LINK && <AppLinksSection url={SPLIT_TICKETS_LINK} />}
    <FooterSection />
  </>
)
