import React from 'react'
import {
  EarnCardContainer,
  InnerCardContainer,
  ImageSection,
  Title,
  Description,
  CardBackground,
  TarrowImage,
  CardTop,
  DividerContainer,
  DividerSpacer,
  LeftNotchTriangle,
  RightNotchTriangle,
  CardBottom,
  LeftNotchContainer,
  RightNotchContainer,
} from './EarnCard.styles'
import { ScrollAnimationContainer } from '../ScrollAnimationContainer'
import { formatImageSet } from '../../util/helpers/image'
import { useIsDesktop } from '../../util/hooks/screen'
import notch from '../../img/Notch_WarmWhiteBG.png'
import notch2x from '../../img/Notch_WarmWhiteBG@2x.png'
import notch3x from '../../img/Notch_WarmWhiteBG@3x.png'

export const EarnCard = ({
  title,
  description,
  imageSet,
  imageAlt,
  animationDelay,
  lightMode = false,
}: {
  title: string
  description: string
  imageSet: string[]
  imageAlt: string
  animationDelay: number
  lightMode?: boolean
}) => (
  <EarnCardContainer>
    <InnerCardContainer>
      <ImageSection>
        <ScrollAnimationContainer
          animationName={useIsDesktop() ? 'bounceIn' : 'flyIn'}
          threshold={0.25}
          animationDuration={0.6}
          style={{
            animationDelay: `${animationDelay}s`,
          }}>
          <TarrowImage srcSet={formatImageSet(imageSet)} alt={imageAlt} width='200' height='239' />
        </ScrollAnimationContainer>
      </ImageSection>
      <CardBackground lightMode={lightMode}>
        <CardTop />
        <DividerContainer>
          {lightMode ? (
            <LeftNotchContainer>
              <img srcSet={formatImageSet([notch, notch2x, notch3x])} alt="" />
            </LeftNotchContainer>
          ) : (
            <LeftNotchTriangle />
          )}
          <DividerSpacer />
          {lightMode ? (
            <RightNotchContainer>
              <img srcSet={formatImageSet([notch, notch2x, notch3x])} alt="" />
            </RightNotchContainer>
          ) : (
            <RightNotchTriangle />
          )}
        </DividerContainer>
        <CardBottom>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </CardBottom>
      </CardBackground>
    </InnerCardContainer>
  </EarnCardContainer>
)
