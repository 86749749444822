import styled from 'styled-components'
import { media, Typography } from '../../../style'

interface CookieTrackerContainerProps {
  isLast?: boolean
}

export const CookieTrackerContainer = styled.div<CookieTrackerContainerProps>`
  ${(props) => !props.isLast && 'margin-bottom: 16px;'}
  @media ${media.tabletAndHigher} {
    .cookie-tracker {
      ${(props) => !props.isLast && 'margin-bottom: 24px;'}
    }
`
export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -14px;
`

export const ToggleSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
`

export const TitleText = styled(Typography.BodyBold)`
  margin: 16px 0 12px;
`

export const Body = styled(Typography.Body)`
  margin: 16px 0 12px;
`
