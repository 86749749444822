import { Col, Container, Row } from 'react-bootstrap'
import {
  DesktopOnlyRow,
  MobileOnlySectionText,
  Section,
  SectionCol,
  SectionColText,
  SectionImageDesktopOnly,
  SectionImageMobileOnly,
  SectionText,
  SectionTitle,
} from './FourSteps.styles'
import { formatImageSet } from '../../util/helpers/image'
import { Typography } from '../../style'
import React, { PropsWithChildren, ReactElement } from 'react'

interface FourStepsProps {
  title: string
  text1: string | ReactElement
  imageSet1: string[]
  imageSet1Mobile?: string[]
  text2: string | ReactElement
  imageSet2: string[]
  imageSet2Mobile?: string[]
  text3: string | ReactElement
  imageSet3: string[]
  imageSet3Mobile?: string[]
  text4: string | ReactElement
  imageSet4: string[]
  imageSet4Mobile?: string[]
}

export const FourSteps = ({
  title,
  text1,
  imageSet1,
  imageSet1Mobile = imageSet1,
  text2,
  imageSet2,
  imageSet2Mobile = imageSet2,
  text3,
  imageSet3,
  imageSet3Mobile = imageSet3,
  text4,
  imageSet4,
  imageSet4Mobile = imageSet4,
  children,
}: PropsWithChildren<FourStepsProps>) => (
  <Section>
    <Container>
      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <SectionTitle>{title}</SectionTitle>
        </Col>
      </Row>
      <Row>
        <SectionCol lg={{ span: 3 }}>
          <SectionImageMobileOnly srcSet={formatImageSet(imageSet1Mobile)} alt="" />
          <SectionImageDesktopOnly srcSet={formatImageSet(imageSet1)} alt="" />
          <MobileOnlySectionText>
            <Typography.H3Red as="span">1.</Typography.H3Red>
            <Typography.Body>{text1}</Typography.Body>
          </MobileOnlySectionText>
        </SectionCol>
        <SectionCol lg={{ span: 3 }}>
          <SectionImageMobileOnly srcSet={formatImageSet(imageSet2Mobile)} alt="" />
          <SectionImageDesktopOnly srcSet={formatImageSet(imageSet2)} alt="" />
          <MobileOnlySectionText>
            <Typography.H3Red as="span">2.</Typography.H3Red>
            <Typography.Body>{text2}</Typography.Body>
          </MobileOnlySectionText>
        </SectionCol>
        <SectionCol lg={{ span: 3 }}>
          <SectionImageMobileOnly srcSet={formatImageSet(imageSet3Mobile)} alt="" />
          <SectionImageDesktopOnly srcSet={formatImageSet(imageSet3)} alt="" />
          <MobileOnlySectionText>
            <Typography.H3Red as="span">3.</Typography.H3Red>
            <Typography.Body>{text3}</Typography.Body>
          </MobileOnlySectionText>
        </SectionCol>
        <SectionCol lg={{ span: 3 }}>
          <SectionImageMobileOnly srcSet={formatImageSet(imageSet4Mobile)} alt="" />
          <SectionImageDesktopOnly srcSet={formatImageSet(imageSet4)} alt="" />
          <MobileOnlySectionText>
            <Typography.H3Red as="span">4.</Typography.H3Red>
            <Typography.Body>{text4}</Typography.Body>
          </MobileOnlySectionText>
        </SectionCol>
      </Row>
      <DesktopOnlyRow>
        <SectionColText lg={{ span: 3 }}>
          <SectionText>
            <Typography.H3Red as="span">1.</Typography.H3Red>
            <Typography.Body>{text1}</Typography.Body>
          </SectionText>
        </SectionColText>
        <SectionColText lg={{ span: 3 }}>
          <SectionText>
            <Typography.H3Red as="span">2.</Typography.H3Red>
            <Typography.Body>{text2}</Typography.Body>
          </SectionText>
        </SectionColText>
        <SectionColText lg={{ span: 3 }}>
          <SectionText>
            <Typography.H3Red as="span">3.</Typography.H3Red>
            <Typography.Body>{text3}</Typography.Body>
          </SectionText>
        </SectionColText>
        <SectionColText lg={{ span: 3 }}>
          <SectionText>
            <Typography.H3Red as="span">4.</Typography.H3Red>
            <Typography.Body>{text4}</Typography.Body>
          </SectionText>
        </SectionColText>
      </DesktopOnlyRow>
      {children}
    </Container>
  </Section>
)
