import { HeaderButtonContainer } from '../../pages/CheapTickets/CheapTickets.styles'
import { Section, Image, Title, Content } from './AppLinksSection.styles'
import { HandWithQRCode } from '../HandWithQRCode/HandWithQRCode'
import { StyledStoreImage } from '../HeaderSection/HeaderSection.styles'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { isAndroid, isIOS } from '../../util/helpers/os'

interface AppLinksSectionProps {
  url: string
}

export const AppLinksSection = ({ url }: AppLinksSectionProps) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col lg={{ span: 10, offset: 1 }}>
            <Content>
              <Image className="d-none d-lg-flex">
                <HandWithQRCode url={url} />
              </Image>
              <div>
                <Title>
                  Download, save and get rewarded on your next journey.
                  <br />
                  <br />
                  Book now.
                </Title>
                <HeaderButtonContainer>
                  {!isAndroid() && (
                    <a aria-label='Download on the App Store' href={url} target="_blank" rel="noopener noreferrer">
                      <StyledStoreImage src={appStoreButton} alt="Download on the App Store" />
                    </a>
                  )}
                  {!isIOS() && (
                    <a aria-label='Get it on Google Play' href={url} target="_blank" rel="noopener noreferrer">
                      <StyledStoreImage src={googlePlayButton} alt="Get it on Google Play" />
                    </a>
                  )}
                </HeaderButtonContainer>
                <Image className="d-lg-none">
                  <HandWithQRCode url={url} />
                </Image>
              </div>
            </Content>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
