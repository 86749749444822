import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage'
import { createBrowserRouter, RouterProvider, Navigate, RouteObject, ScrollRestoration, Outlet } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { CheapTickets } from './pages/CheapTickets'
import { FEATURE_FLAGS } from './config'
import { SplitTickets } from './pages/SplitTickets'
import { PATHS } from './util/helpers/paths'
import { Redemption } from './pages/Redemption'
import { WeatherWarning } from './pages/WeatherWarning'
import { IndustrialAction } from "./pages/IndustrialAction";
import {Railcards} from "./pages/Railcards";
import {useStoreFRTQueryParameters} from "./util/hooks/useStoreFRTQueryParameters";

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <Navigate replace to={'/'} />,
  },
  {
    path: PATHS.TERMS_AND_CONDITIONS,
    element: <TermsAndConditionsPage />,
  },
  {
    path: '/terms-conditions.pdf',
    element: <Navigate replace to={PATHS.TERMS_AND_CONDITIONS} />,
  },
  {
    path: '/weather-warnings',
    element: <WeatherWarning />,
    errorElement: <WeatherWarning />,
  },
  {
    path: '/industrial-action',
    element: <IndustrialAction />,
    errorElement: <IndustrialAction />,
  },
]

if (FEATURE_FLAGS.cheapTicketsPage) {
  routes.push({
    path: PATHS.CHEAP_TICKETS,
    element: <CheapTickets />,
  })
}

if (FEATURE_FLAGS.splitTicketsPage) {
  routes.push({
    path: PATHS.SPLIT_TICKETS,
    element: <SplitTickets />,
  })
}

if (FEATURE_FLAGS.redemptionPage) {
  routes.push({
    path: PATHS.REDEMPTION,
    element: <Redemption />,
  })
}

if (FEATURE_FLAGS.railcardsPage) {
  routes.push({
    path: PATHS.RAILCARDS,
    element: <Railcards />,
  })
}

const Layout = () => {
  useStoreFRTQueryParameters()
  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  )}

const rootRoute: RouteObject = {
  element: <Layout />,
  children: routes,
}
const router = createBrowserRouter([rootRoute])

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
