import styled from "styled-components";
import { colors, Typography } from '../../style'

export const WeatherWarningContainer = styled.div`
  background: ${colors.BACKGROUND_GREY};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px
`
export const WeatherWarningSection = styled.section`
  box-shadow: 0 1px 3px 0 rgba(34, 36, 78, 0.2), 0 2px 2px 0 rgba(34, 36, 78, 0.12), 0 0 2px 0 rgba(34, 36, 78, 0.14);
  padding: 20px 15px;
  background: ${colors.COLOUR_WHITE};
`
export const WeatherWarningHeaderText = styled(Typography.H2)`
  color: ${colors.TERTIARY_CHARCOAL_140};
  font-weight: 800;
  font-size: 16px;
`

export const WeatherWarningHeaderParagraph = styled(Typography.Body)``

export const WeatherWarningLink = styled(Typography.anchorStyle)``
