import {
  StyledSection,
  LinkContainer,
  VTTLogo,
  StyledBody,
  VirginButton,
  FooterLink,
  LinkText,
  CopyrightText,
} from './FooterSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import vttLogo from '../../img/vtt-logo-diagonal-red-gradient.png'
import { PATHS } from '../../util/helpers/paths'
import { useCookiePolicy } from '../../context/CookiePolicyProvider'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { colors } from '../../style'

export const FooterSection = () => {
  const { showCookiePolicyModal } = useCookiePolicy()
  const copyrightYear = new Date().getFullYear()
  const location = useLocation()
  const [isTandCActive, setIsTandCActive] = useState(false)

  useMemo(() => {
    location.pathname === PATHS.TERMS_AND_CONDITIONS ? setIsTandCActive(true) : setIsTandCActive(false)
  }, [location.pathname])

  return (
    <StyledSection>
      <Container>
        <LinkContainer as={Row}>
          <Col lg={2} className="text-center">
            <VTTLogo src={vttLogo} alt="VTT Logo" />
          </Col>
          <Col lg={2}>
            <FooterLink aria-label='Privacy Policy' href={PATHS.PRIVACY} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </FooterLink>
            <FooterLink aria-label='Cookie Policy' href={PATHS.COOKIES} target="_blank" rel="noopener noreferrer">
              Cookie Policy
            </FooterLink>
            <LinkText aria-label='Cookie Policy Settings' onClick={showCookiePolicyModal}>Cookie Policy Settings</LinkText>
            <FooterLink
              aria-label='Virgin Trains Ticketing Terms & Conditions'
              style={{
                color: isTandCActive ? colors.PRIMARY_RED_100 : '',
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'auto' })
              }}
              href={PATHS.TERMS_AND_CONDITIONS}>
              Virgin Trains Ticketing Terms & Conditions
            </FooterLink>
          </Col>
          <Col lg={2}>
            <FooterLink aria-label='Customer Support' href={PATHS.SUPPORT} target="_blank" rel="noopener noreferrer">
              Customer Support
            </FooterLink>
            <FooterLink aria-label='Virgin Red Modern Slavery Statement' href={PATHS.MODERN_SLAVERY_STATEMENT} target="_blank" rel="noopener noreferrer">
              Virgin Red Modern Slavery Statement
            </FooterLink>
          </Col>
          <Col lg={6}>
            <StyledBody>If you require information on any other Virgin brands or partners, please visit the Virgin website</StyledBody>
            <VirginButton aria-label='Go to Virgin.com' href={PATHS.VIRGIN} target="_blank" rel="noopener noreferrer">
              Go to Virgin.com
            </VirginButton>
          </Col>
        </LinkContainer>
        <Row>
          <CopyrightText>© Virgin {copyrightYear}. All rights reserved.</CopyrightText>
        </Row>
      </Container>
    </StyledSection>
  )
}
