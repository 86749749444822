import React from 'react'
import { CardContainer, StyledSection, TitleDark, TitleLight } from './EarnSection.styles'
import { EarnCard } from '../EarnCard'
import { Col, Container, Row } from 'react-bootstrap'
import { useIsDesktop } from '../../util/hooks/screen'

interface EarnSectionProps {
  lightMode?: boolean
  imageSet1: string[]
  imageSet2: string[]
  imageSet3: string[]
}

export const EarnSection = ({ lightMode = false, imageSet1, imageSet2, imageSet3 }: EarnSectionProps) => {
  const animationDelay = useIsDesktop() ? 0.2 : 0
  return (
    <StyledSection lightMode={lightMode}>
      <Container>
        <Row>
          <Col className="col-lg">
            {lightMode ? (
              <TitleLight as="h2">Train travel that helps pay for itself</TitleLight>
            ) : (
              <TitleDark as="h2">Train travel that helps pay for itself</TitleDark>
            )}
          </Col>
        </Row>
        <CardContainer as={Row}>
          <Col lg={4}>
            <EarnCard
              title="The more you travel"
              description="Search for the best price, then buy your train ticket in the app"
              imageSet={imageSet1}
              imageAlt=""
              animationDelay={2 * animationDelay}
              lightMode={lightMode}
            />
          </Col>
          <Col lg={4}>
            <EarnCard
              title="The more you earn"
              description="Earn 3 Virgin Points for every £1 you spend by joining Virgin Red"
              imageSet={imageSet2}
              imageAlt=""
              animationDelay={animationDelay}
              lightMode={lightMode}
            />
          </Col>
          <Col lg={4}>
            <EarnCard
              title="The more you save"
              description={'A mere 200 points is all you need to unlock your first discount on train travel'}
              imageSet={imageSet3}
              imageAlt=""
              animationDelay={0}
              lightMode={lightMode}
            />
          </Col>
        </CardContainer>
      </Container>
    </StyledSection>
  )
}
