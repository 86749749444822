import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import { FontFamilies } from './../../style/styles'
import { Container, Col, Row } from 'react-bootstrap'

interface TandCsColProps {
  leftPadding?: number
}

export const TandCsWrapper = styled(Container)`
  padding: 24px 0;
  background-color: ${colors.BACKGROUND_GREY};
`

export const TandCsContainer = styled(Container)`
  margin-top: 100px;
  background-color: ${colors.BACKGROUND_GREY};

  @media ${media.desktopAndHigher} {
    padding-bottom: 0;
  }
`

interface TancCsColRightProps {
  background?: boolean
}

export const TandCsRow = styled(Row)``

export const TandCsCol = styled(Col)<TandCsColProps>`
  scroll-margin: 80px;
  padding: 40px 10px 40px ${(props) => (props.leftPadding ? props.leftPadding : 10)}px;
  margin-bottom: 24px;
`

export const TandCsPageTitle = styled.h2`
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.37px;
  padding: 0;
  margin-bottom: 25px;
  color: ${colors.PRIMARY_RED_100};
  font-family: ${FontFamilies.spartan};

  @media ${media.desktopAndHigher} {
    font-size: 40px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    margin-bottom: 64px;
    padding: 0 10px;
  }
`

export const TandCsSectionNumber = styled.h2`
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: -0.37px;
  color: ${colors.PRIMARY_RED_100};
  font-family: ${FontFamilies.spartan};
  margin-bottom: 0;

  @media ${media.desktopAndHigher} {
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.2px;
    padding: 0 10px;
  }
`

export const TandCsPageTopSection = styled(Typography.Intro)`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: -0.2px;
  color: ${colors.TERTIARY_CHARCOAL_140};
  margin-bottom: 26px;
  @media ${media.desktopAndHigher} {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.25px;
    padding: 0 10px;
  }

  .TandCLink {
    color: ${colors.TERTIARY_CHARCOAL_140};
  }
`

export const TandCsPageParagraph = styled(Typography.Paragraph)`
  margin-bottom: 16px;
  font-size: 16px;
`

export const TandCsPageText = styled(Typography.Body)`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${colors.TERTIARY_CHARCOAL_140};
  @media ${media.desktopAndHigher} {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    padding: 0 10px;
  }

  &b {
    font-weight: 600;
  }

  .TandCLink {
    color: ${colors.TERTIARY_CHARCOAL_140};
  }
`

export const TandCsPageTextExtraDetail = styled(Typography.Body)`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${colors.TERTIARY_CHARCOAL_140};
  @media ${media.desktopAndHigher} {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
  }
`

export const TandCsContentCol = styled(Col)<TancCsColRightProps>`
  background-color: ${(props) => (props.background ? colors.COLOUR_WHITE : 'transparent')};
  padding: 34px 25px 25px 25px;
  margin-bottom: 24px;
  box-shadow: 0 1px 5px 0 rgb(34 36 78 / 20%), 0 3px 4px 0 rgb(34 36 78 / 12%), 0 2px 4px 0 rgb(34 36 78 / 14%);
  scroll-margin: 150px;
  @media ${media.desktopAndHigher} {
    padding: 64px 40px 40px 40px;
  }
`

export const TandCsUl = styled.ul``

export const TandCsLi = styled(Typography.BodyList)`
  margin-bottom: 24px;
`
