import { useEffect, useState } from 'react'

export function useOnScroll(callback: () => void) {
  useEffect(() => {
    const scrollHandler = () => {
      callback()
    }
    window.addEventListener('scroll', scrollHandler)

    // Remove the event listener on unmount
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [callback])
}

export function usePageScrollPosition(): number {
  const [scrollPosition, setScrollPosition] = useState(window.scrollY)
  useOnScroll(() => setScrollPosition(window.scrollY))
  return scrollPosition
}

export function usePageHasScrolled(offset = 0): boolean {
  return usePageScrollPosition() > offset
}
