import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const StyledSection = styled.section`
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0px 0px 0px;
  border-style: solid;
  box-shadow: 0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  background-color: ${colors.PRIMARY_RED_100};
  padding-bottom: 48px;
`

export const Title = styled(Typography.H1White)`
  margin-top: 36px;

  @media ${media.desktopAndHigher} {
    margin-top: 64px;
  }
`

export const StyledBody = styled(Typography.BodyBold)`
  color: ${colors.COLOUR_WHITE};
  margin-top: 37px;

  @media ${media.desktopAndHigher} {
    margin-top: 28px;
  }
`

export const StyledRunningImg = styled.img`
  width: 100%;
  margin-top: 36px;

  @media ${media.desktopAndHigher} {
    margin-top: -28px;
  }
`

export const CustomerSupportButton = styled(Typography.Button1White)`
  margin-top: 40px;

  @media ${media.desktopAndHigher} {
    width: 269px;
  }
`
