import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const StyledSection = styled.section<{ lightMode: boolean }>`
  border-image: ${(p) =>
    p.lightMode
      ? 'linear-gradient(to top,rgba(34,36,78,0) 0,35%,rgba(34,36,78,0.2)) 100% 0 0 0 repeat'
      : 'linear-gradient(to top, rgba(0, 0, 0, 0) 0, 10%, rgba(0, 0, 0, 0.45)) 100% 0 0 0 repeat'};
  border-width: ${(p) => (p.lightMode ? 7 : 10)}px 0 0 0;
  border-style: solid;
  box-shadow: 0 1px 5px 0 rgba(34, 36, 78, 0.2), 0 3px 4px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  background: ${(p) => (p.lightMode ? colors.COLOUR_WARM_WHITE_100 : colors.PRIMARY_NAVY_100)};
  padding-bottom: 40px;

  @media ${media.desktopAndHigher} {
    padding-bottom: 64px;
  }
`

export const TitleLight = styled(Typography.H1Red)`
  margin-top: 64px;
  text-align: center;
`

export const TitleDark = styled(Typography.H1White)`
  margin-top: 64px;
`

export const CardContainer = styled.section`
  display: flex;
  margin-top: 16px;
  align-items: center;
  justify-content: center;
`
