import React, { useState } from 'react'
import {
  TandCsContainer,
  TandCsRow,
  TandCsWrapper,
  TandCsCol,
  TandCsPageTitle,
  TandCsSectionNumber,
  TandCsPageText,
  TandCsPageTopSection,
  TandCsUl,
  TandCsLi,
  TandCsPageTextExtraDetail,
  TandCsPageParagraph,
} from './TandCsSection.style'
import { TandCsAccordion } from '../TandCsAccordion'
import { TandCsSectionItem } from '../TandCsSectionItem'

import { TandCsTableOfContents } from '../TandCTableOfContents'
import { FEATURE_FLAGS, RAILCARD_TANDC_URL } from '../../config'

export const TandCsSection = () => {
  const [activeItem, setActiveItem] = useState(['0'])
  const handleActiveItem = (item: string) => {
    setActiveItem((activeItem) => [...activeItem, item])
  }

  const handleRemoveItem = (item: string) => {
    activeItem.filter((itemToGo) => itemToGo !== item)
    setActiveItem(activeItem.filter((itemToGo) => itemToGo !== item))
  }

  return (
    <TandCsWrapper fluid={true} id={'top-of-screen'}>
      <TandCsContainer fluid={false}>
        <TandCsRow>
          <TandCsCol lg={4} className="d-none d-xl-block" leftPadding={40}>
            <TandCsTableOfContents onClick={handleActiveItem} />
          </TandCsCol>
          <TandCsCol xl={8} lg={12}>
            <TandCsCol>
              <TandCsSectionItem id={'G'}>
                <TandCsPageTitle as="h1">General Terms and Conditions</TandCsPageTitle>
                <TandCsPageTopSection>
                  <TandCsPageParagraph>
                    Hello there, train traveller! These terms and conditions cover everything you need to know when using and booking train tickets through the Virgin Train Ticketing app and/or website (or VTT Platform for short). They apply when you’re using the VTT Platform, whether or not you book a train ticket.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    These terms also incorporate some other terms and policies that we’ve listed below. When you use the VTT Platform, you’re giving us a thumbs-up that you’ve read, understood and agreed to these terms. If you don’t agree with any of the terms, you should not use the VTT Platform.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Got questions? We’re here to help. We’ve worked hard to keep these T&Cs as clear as possible. If anything’s not clear or if you have any burning questions, drop us a message at{' '}
                    <a aria-label='VTT Terms and conditions' className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                      vttsupport@virgin.com
                    </a>
                    .
                  </TandCsPageParagraph>
                </TandCsPageTopSection>
                <TandCsPageText>
                  <TandCsPageParagraph>
                    Now, these terms have two main sections. First up, we've got the <b>Booking Service Terms (Section 1)</b>. These are all about the nitty-gritty when you're booking train tickets through the VTT Platform - changes, refunds, and all the other details you need to know. Then, we've got the <b>Terms of Use (Section 2)</b>, which are the general rules for using the VTT Platform, no matter if you're buying train tickets or just exploring.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    To hop on this train and become a registered user of Virgin Trains Ticketing, you have to be a member of our reward programme, Virgin Red. Once you're in, you can earn Virgin Points on train tickets you buy through the VTT Platform. Membership of Virgin Red and the earning and spending of Virgin Points is all detailed in the{' '}
                    <a
                      aria-label='Virgin Red Programme Terms and conditions'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.virgin.com/virgin-red/terms-and-conditions'}>
                      Virgin Red Programme Terms and Conditions
                    </a>. The rate at which you can earn Virgin Points on ticket purchases may change from time to time.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Already got some Virgin Points saved up? You can use them to snag discounts on your train tickets or even discount the cost of the train ticket in full. If you're ready to spend those points, make sure you're signed into your Virgin Red account on the VTT Platform before you book your tickets. Please note you will only earn Virgin Points on the cash element of your ticket purchase, and not on any points element used to discount it.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    And if you're not a Virgin Red member, no sweat - you can still book train tickets as a guest.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Heads up, any train tickets you buy via the VTT Platform are also governed by the{' '}
                    <a
                      aria-label='National Rail Conditions of Travel'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf'}>
                      National Rail Conditions of Travel.
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    And any personal information you give us while using the VTT Platform will be dealt with in line with the{' '}
                    <a aria-label='Virgin Red Privacy Policy.' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://www.virgin.com/virgin-red/privacy-policy'}>
                      Virgin Red Privacy Policy.
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Payment for your train tickets is processed by Braintree, a Paypal service and our payment services provider. Braintree manage your data in accordance with their own{' '}
                    <a
                      aria-label='Privacy Policy'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.braintreepayments.com/gb/legal/braintree-privacy-policy'}>
                      privacy policy.
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    The VTT Platform is run by us - Virgin Red Limited, trading as Virgin Trains Ticketing. You can find us at 66 Porchester Road, London, W2 6ET. Our company number is 11490861, and our VAT number is (GB) 425216184.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Customer support queries can be directed to{' '}
                    <a aria-label='Virgin Trains Ticketing Support' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                      Virgin Trains Ticketing Support
                    </a>{' '} or{' '}
                    <a aria-label='Terms and Conditions Phone number' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'tel:0333 241 9128'}>
                      0333 241 9128
                    </a>{' '} or{' '}
                    <a aria-label='Trains Terms and Conditions Link' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://trains.virgin.com'}>
                      https://trains.virgin.com.
                    </a>{' '} For urgent assistance between the hours of 7am and 10pm please use the phone number. Further information about cancellations, changes and refunds is set out below.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Got questions or need help? Check out <a aria-label='Trains Terms and Conditions Link' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://trains.virgin.com'}>
                    https://trains.virgin.com.
                  </a>{' '} or get in touch with our support genies at Virgin Trains Ticketing Support or on 0333 241 9128. For urgent assistance between 7am and 10pm, give the phone number a ring. And if you’re looking for information about cancellations, changes, and refunds, we've got the lowdown below.
                  </TandCsPageParagraph>
                  <TandCsPageTextExtraDetail>
                    This version of the Virgin Trains Ticketing Terms and Conditions is effective from 28 August 2024
                  </TandCsPageTextExtraDetail>
                </TandCsPageText>
              </TandCsSectionItem>
              <TandCsSectionItem sectionNumber={'1'}>
                <TandCsSectionNumber>Section 1</TandCsSectionNumber>
                <TandCsPageTitle>Booking Service Terms</TandCsPageTitle>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  section={'1.1'}
                  title={'Your agreement with us'}
                  active={activeItem}
                  onClick={handleActiveItem}>
                  <TandCsPageParagraph>
                    These Booking Service Terms are the agreement between you and us when it comes to any rail tickets you buy using the Booking Service. If you don’t agree with any of the terms, you should not use the Booking Service.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    The train operating companies you can book tickets with through this Booking Services are the ones who’ll take care of you when you’re on board and are responsible for the journey you’ve booked. All bookings are subject to the{' '}
                    <a
                      aria-label='National Rail Conditions of Travel'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf'}>
                      National Rail Conditions of Travel
                    </a>{' '}
                    (more detail on this below). There may also be specific restrictions set by the train operating companies that vary by ticket type.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.2'}
                  title={'Your use of the Booking Service'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    The VTT Platform gives you the ability to book tickets for rail journeys all around Great Britain (this is what we mean when we say Booking Service).
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    At the moment, we don’t have tickets for travel outside Great Britain, for travel by any means other than rail, for season tickets or rover/ranger tickets.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    All prices quoted on the VTT Platform are in Pounds Sterling (£) and tickets must be paid for using a UK credit or debit card.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    And if you're a Virgin Red member, you can use your Virgin Points to snag discounts on your tickets or even discount the cost of your train ride in full. Just make sure you're logged into your Virgin Red account on the VTT Platform to use your points. Please note you will only earn Virgin Points on the cash element of your ticket purchase, and not on any points element used to discount it.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Young travellers get some love too! Children between 5 and 15 years old get to ride at a cool discount of up to 50% off the adult fare. And children up to 4 can travel for free with an adult (aged 16 and above) holding a valid ticket.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    You need to be at least 16 years or above to use the Booking Service. If you're 16 or 17, you’ll need permission from your parent or guardian to use it and you'll need to do so as a guest user. To be a registered user of Virgin Trains Ticketing, you’ll need to be 18 or above. This is because you’ll also need to be a member of Virgin Red, which is only possible if you’re 18 or over. If things aren't adding up or we have reason to believe you haven’t met these requirements, we might have to reject or cancel your booking(s).
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    You confirm that you have the authority to use the credit or debit card details you provide for the purpose of settling any payments due to us. Payment for your booking is processed by PayPal (Europe) S.a.r.l. et Cie, S.C.A, trading as Braintree, in line with their{' '}
                    <a
                      aria-label='privacy policy'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.braintreepayments.com/gb/legal/braintree-privacy-policy'}>
                      privacy policy
                    </a>. The transaction on your card payment statement will appear as ‘Srt*VIRGINTRAINSTICKET’.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    When you use the Booking Service, you agree that all the information you share with us is accurate and that you won’t make any speculative, false or fraudulent reservations. You also agree to only use the Booking Service for bookings for yourself or someone else who has given your consent or on whose behalf you’re legally entitled to act and provide personal information as part of a booking or purchase.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you’re feeling extra friendly, you can use the Booking Service to buy train tickets for groups of people – even if you are not travelling as part of that group. Again, you need to be legally entitled to act on behalf of all the members of the group or have their consent to do so, including to provide their personal information.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If we get wind of any suspicious activity, we can refuse to sell tickets or reserve a seat, so no funny business.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Bear in mind that you are financially responsible for any bookings made through the Booking Service using your details.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Our rail booking service is operated by our partner, SilverRail Technologies UK Limited (SRT). SRT
                    acts as merchant of record and will process your payment on our behalf. As merchant of record SRT is
                    responsible for handling refunds and chargebacks. Any queries can be dealt with via our customer services team who can be contacted by email at {' '}<a aria-label='Virgin Trains Ticketing Support' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>vttsupport@virgin.com</a>. Additional help and support can be found here {' '} <a aria-label='https://trains.virgin.com' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://trains.virgin.com'}>https://trains.virgin.com</a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <ul>
                      <li>
                        SRT is not responsible for the underlying rail service that you are purchasing through the booking
                        service which is the responsibility of the applicable rail operator.
                      </li>
                      <li>
                        SRT’s registered office address is at 2 Minton Place, Victoria Road, Bicester, Oxfordshire OX26
                        6QB and its company number is 7070911.
                      </li>
                    </ul>
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.3'}
                  title={'National Rail Conditions of Travel'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Every booking you make through the Booking Service is subject to these terms and the{' '}
                    <a
                      aria-label='National Rail Conditions of Travel'
                      target={'_blank'}
                      rel="noreferrer"
                      className={'TandCLink'}
                      href={'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf'}>
                      National Rail Conditions of Travel
                    </a>, which may be updated from time to time.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    The National Rail Conditions of Travel tell you what to you’re entitled to expect on your train journey. They also set out your rights and responsibilities for any train journeys you make on the railway network in Great Britain. They cover everything from train company liability for delays and cancellations, missed connections or railway closures to lost or damaged luggage.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    In some cases, the rights in the National Rail Conditions of Travel are extended or restricted by the train operating companies you book tickets with. This will be detailed to you as part of your journey summary before you hit the “buy” button.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    And rest assured, whether you’re buying your ticket through our Booking Service or elsewhere, the National Rail Conditions of Travel entitle you to the same levels of compensation or refund entitlements if something goes wrong.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Finally, let’s not forget the all-important tickets. The National Rail Conditions of Travel include obligations to show a valid ticket on request and detail what happens if you or any person travelling is unable to do so. The consequences depend on the circumstances but could include a requirement to pay the full fare, a penalty fare or even a referral for prosecution, so make sure you keep that ticket safe.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.4'}
                  title={'Issue of rail tickets'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Here's the deal: we can't confirm the ticket price until you’ve completed all the details of your order. But don’t worry – you won’t be charged until you check out and your order has been processed.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Once you're booked, we'll email you a confirmation and send your ticket(s) or ticket collection number to you using whatever method you selected when you made your booking.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Now, if you're receiving your ticket(s) by e-mail, make sure to check the tickets as soon as they drop into your inbox. If something doesn’t look quite right, give us a ring on{' '}
                    <a aria-label='Terms and Conditions Phone number' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'tel:0333 241 9128'}>
                      0333 241 9128
                    </a>.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Before you hop onboard, double-check that you’ve got those tickets – whether they're real-life tickets or e-tickets. We, the train operating company and the ticket inspector reserve the right to refuse to accept any ticket that is not satisfactorily displayed, illegible or if we suspect any funny business, which might include fraudulent use of a booking confirmation, transfer to a different person or a fake or counterfeit ticket.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Bear in mind, all tickets are non-transferable, whatever the method of access or collection (see more on this below).
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.5'}
                  title={'Accessing your tickets'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    There are a few different ways to access or collect your tickets, depending on things like your ticket type, the train operator and how you bought your ticket. Hear are some of the most common options:
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Station Collection</b>: For many bookings, you can collect your tickets from a train station. Give yourself time to grab your tickets before you hop on the train. You’ll need to bring your ticket collection reference and a UK valid credit or debit card for identification. Take note of any special collection instructions during the booking process – this might be things like station opening hours. If the ticket machine's playing hooky or you’re otherwise struggling to collect your tickets, a member of station staff should be able to help you. If there are no staff at the station, hop on your booked train and have a word with the on-train staff as soon as you can.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Print at Home</b>: For some bookings, you can print your own ticket. Just print the ticket on A4 paper, making sure it’s clear, and then carry it with you when you travel. Keep your ticket safe and make sure no one else is able to print or make a copy of it. Some train operating companies have extra restrictions for self-printed tickets so look out for this during the booking process.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>E-Tickets</b>: For most bookings, you can now use paperless e-tickets that are sent to your mobile device when you book. Just make sure your phone's charged, functional, switched on and ready to display your e-ticket for inspection when needed (including at train stations). Remember, if your phone runs out of battery mid-journey or you can’t display your e-ticket for any other reason, you might have to pay the fare again, pay a penalty fare or face prosecution. Because of the wide variety of mobile devices and networks, we can’t offer technical assistance for e-tickets. Keep in mind that your mobile service provider charge data usage charges when you download e-tickets – best to check beforehand to avoid any surprises.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Oh, and guess what? Your e-ticket should still be accessible through the Virgin Trains Ticketing app, even if your phone's offline. But hey, it’s your responsibility to make sure you can display your e-ticket at all times right until the end of your journey, so make sure you have a backup plan just in case, like saving your e-ticket in your Apple Wallet or having a printout.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    When you buy an e-ticket, you agree to cooperate with the train inspector and let them clearly view the ticket on your mobile phone. They may want a closer look, so don’t be surprised if they ask for your phone. Bear in mind - if you don’t show your ticket or hand over your mobile phone when asked, the train inspector is entitled to treat you as travelling without a valid ticket.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Finally, be aware that e-tickets are one-time wonders – we can’t provide duplicates or replacements. Some train operating companies also have extra restrictions for e-tickets so look out for this during the booking process.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.6'}
                  title={'Fees and charges'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Let's talk money – when you're buying tickets through the Booking Service, there might be some extra costs payable on top of the ticket price. If so, we’ll let you know during the booking process. Some of these fees are fees imposed by the train operating company you’re booking with that we’re required to pass on to you.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    You're responsible for all charges and taxes payable as a result of your use the Booking Service, including internet access charges or mobile data charges you incur by accessing the Booking Service.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.7'}
                  title={'Cancellations, changes and refunds'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    We get it - life happens and sometimes plans change. If you need to change your travel date or time, it might be doable depending on your ticket type and available alternatives. Refunds and exchanges also depend on the ticket type, ticket conditions and may not be available for certain ticket types.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If your train is cancelled or severely disrupted, you may be entitled to compensation or a refund. We need permission to issue compensation or refund tickets for cancelled or disrupted journeys. If you still wish to travel you can jump on any of the two trains before or two trains after your scheduled service (depending on route restrictions for your type of ticket). Be sure to retain your original ticket as you may be required to present it on board.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you no longer wish to travel and the train company allows us to issue the refund on their behalf, we will. If not, we'll give you the contact details for the train company so you can get in touch with them directly to make a claim.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Be aware that most cancellations come with a charge per ticket, however in some circumstances, such as cancellation as a result of severe disruption, the charge maybe be waived. Exchanges will always incur a charge per transaction.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Refunds
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you've used Virgin Points to discount your ticket price, after deduction of the applicable charge(s), your refund will depend on the ticket price, the cash element and the number of points used to purchase the ticket. For example, and in the case of the £5 charge applicable from 1 April 2024:
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <ul>
                      <li>
                        Where the train ticket costs more than £5 and was partially discounted using Virgin Points, the cash element and the points will be refunded in the same ratio used to purchase the ticket. For example, £20 ticket, £15 cash paid, £5 points discount applied, £5 charge taken, £11.25 refunded in cash, £3.75 refunded in points;
                      </li>
                      <li>
                        Where the £5 charge equals or exceeds the cash element, the cash element and the points will be refunded in the same ratio used to purchase the ticket. For example, £10 ticket price, £2 cash paid, £8 points discount applied, £5 charge taken, £1 refunded as cash and £4 refunded in points;
                      </li>
                      <li>
                        Where the train ticket cost more than £5 and was fully discounted using Virgin Points, the balance remaining after deduction of the £5 charge will be refunded in Virgin Points. For example, £17 ticket price, £5 charge deducted in points, £12 refunded in points; and
                      </li>
                      <li>
                        Where the train ticket cost less than £5 and was fully discounted using Virgin Points, there is no balance remaining after deduction of the £5 charge so no refund will be given.
                      </li>
                    </ul>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Exchanges
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <ul>
                      <li>
                        Exchanges can be requested if you’ve bought a non-refundable ADVANCE ticket and want to change your booking.
                      </li>
                      <li>
                        You will be required to book your new journey first and then request a refund on your original journey via the online web form (accessible through the <a aria-label='https://virgintrains-refunds.fastrailticketing.com/?uid=SHOW-RAIL-HELP-FORM' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://virgintrains-refunds.fastrailticketing.com/?uid=SHOW-RAIL-HELP-FORM'}>online refund form</a>) including the new booking reference in the refund request.
                      </li>
                      <li>
                        You can change the date and time of the journey, but not the departure or destination stations.
                      </li>
                      <li>
                        A £10 charge per transaction will apply.
                      </li>
                    </ul>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    The Virgin Trains Ticketing Support team is ready to help with exchanges or cancellations and will be able to let you know how the charge will be applied based on your circumstances. Please note that we will cap the charges for requests made through the Virgin Trains Ticketing website and requests made to the Virgin Trains Ticketing Support team at £10 per transaction. So, in the case of 3 tickets the charge will be capped at £10, rather than £15. Unfortunately, we are not currently able to apply a cap for requests submitted via the Virgin Trains Ticketing app, so the charge will be £5 per ticket.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Where we issue you with a refund which is not subject to the charge, and where you have used Virgin Points to discount part or all of the cost of your train ticket(s), the refund will reflect this. So, any Virgin Points used will be refunded as Virgin Points and any cash element will be refunded as cash.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>Remember that all tickets are non-transferable.</TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you need help with making any changes to your booking, go to{' '} <a aria-label='https://trains.virgin.com' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://trains.virgin.com'}>
                    https://trains.virgin.com
                  </a>{' '} or get in touch via{' '} <a aria-label='Virgin Trains Ticketing Support' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                    Virgin Trains Ticketing Support
                  </a>{' '} or on{' '} <a aria-label='0333 241 9128' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'tel:03332419128'}>
                    0333 241 9128
                  </a>{' '} or, if you need urgent assistance between the hours of 7am and 10pm, give us a ring on the phone number above.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    As a consumer, you may have a legal right to cancel orders and to obtain a refund and/or replacement of products or services in certain circumstances. Be aware that train tickets purchased on the VTT Platform are not subject to the usual 14 day cooling off period that applies to most online purchases. You can find out more information about your consumer rights online, including at www.citizensadvice.org.uk.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.8'}
                  title={'Split ticketing'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Split tickets are a clever way to save money on your train travel.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you’re using split tickets, bear in mind that the train you're on must stop at the station(s) where you are changing from one ticket to another, as shown on your tickets, to allow passengers to alight and board that service.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                {FEATURE_FLAGS.railcardsPage && <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'1.9'}
                  title={'Railcards'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    We work with Fast Rail Ticketing to provide a range of digital Railcards which can be used to save money on your purchase of train tickets through the VTT Platform. Your purchase and use of a Railcard is subject to the general and specific terms and conditions applicable to that Railcard. Please use the links below for more details.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    When you purchase a Railcard you will be emailed a download code. You should then input the code into the VTT Platform and download the Railcard to your device.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    For now, you can only earn Virgin Points on the purchase of a Railcard (if you are 18 or above and a member of Virgin Red). You can't yet discount the cost of purchasing a Railcard with points.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    You can purchase a Railcard for use by someone else in conjunction with the VTT Platform. So, in the case of a 16-17 Saver Railcard or a 16-25 Railcard purchased for someone who is 16 or 17 years of age, you would earn Virgin Points on that purchase if you are 18 or above and a member of Virgin Red. The person you are buying it for should then use the download code to add the Railcard to the VTT Platform on their device.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    A 14 day cooling off period applies to purchases of digital Railcards, as long as the code has not been used to download the Railcard to the VTT Platform on any device. If you are eligible for a refund please contact{' '}
                    <a aria-label='Virgin Trains Ticketing Support' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                      vttsupport@virgin.com
                    </a>.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Payments for your Railcard purchase are processed by Fast Rail Ticketing, with the Railcard issued by ATOC who, by collecting the necessary information for the issuance of the Railcard, will act as an independent Data Controller. You can learn more about their Privacy Policy{' '}
                    <a aria-label="ATOC Privacy Policy" target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://www.railcard.co.uk/privacy-notice/'}>
                      here
                    </a>.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="16-17 Saver Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=STS`}>
                      16-17 Saver
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="16-25 Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=YNG`}>
                      16-25 Railcard
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="26-30 Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=TST`}>
                      26-30 Railcard
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="Family & Friends Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=FAF`}>
                      Family & Friends Railcard
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="Network Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=NTW`}>
                      Network Railcard
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="Two Together Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=2TR`}>
                      Two Together Railcard
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <a aria-label="Senior Railcard Terms and Conditions" target={'_blank'} rel="noreferrer" className={'TandCLink'}
                       href={`${RAILCARD_TANDC_URL}?r=SNR`}>
                      Senior Railcard
                    </a>
                  </TandCsPageParagraph>
                </TandCsAccordion>}
              </TandCsSectionItem>

              <TandCsSectionItem sectionNumber={'2'}>
                <TandCsSectionNumber>Section 2</TandCsSectionNumber>
                <TandCsPageTitle>Terms of Use</TandCsPageTitle>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.1'}
                  title={'Your responsibility to us'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    The VTT Platform and Booking Service have been created to give you travel information across the whole rail network in Great Britain and to enable you to buy rail tickets. When you use the VTT Platform and Booking Service, you agree to do this in good faith and to gather information and buy tickets for yourself or others. If you’re not onboard with that, you shouldn’t use the VTT Platform or the Booking Service.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Our brand and content</b>. Our brand and content makes us special and unique and we ask for your help protecting it. We ask that you help us protect our Content, by which we mean: all designs, text, graphics, audio, video or image files (including but not limited to photographs), content, data, software, applications and information displayed or available on or through the VTT Platform and Booking Service, and all copyright, trade mark rights, design rights and other intellectual property rights in them. Just so you know, all Content is owned by us, our licensors, partners or suppliers, and it will stay that way. This includes (but isn’t limited to) the organisation, layout and look and feel of the VTT Platform, the Booking Service and the underlying software. We're pretty protective of what we've created, so copying, reproducing, transmitting, publishing, displaying, distributing, reverse engineering, compiling, creating any derivative works or doing anything else funky with the Content or VTT Platform or the Booking Service (either on your own or assisting or facilitating a third party to do it) is a no-go. You also agree that you will not use or copy the Virgin name or any other Virgin company name, trade mark, logo or design in any way.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Using Our Content and the VTT Platform (including the Booking Service)</b>: Using Our Content and the VTT Platform (including the Booking Service): We're happy to let you use our Content and the VTT Platform for your personal use. But please, no commercial purposes or making money off it without our permission – and no mining or scraping data either. Using or introducing any automated device or process, program, software, tool, agent, agent, script, routine, spider, robot or virus to the VTT Platform and Booking Service is also a no-no.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Playing by the Rules</b>: We're all about doing things legally and fairly. So when you use the VTT Platform and the Booking Service, make sure it's for lawful purposes and in a lawful manner. Along with these terms, follow all the applicable laws and regulations related to the Booking Service, the VTT Platform and any transactions you make on it.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.2'}
                  title={'Accuracy and availability of the VTT Platform and Booking Service'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    <b>Accuracy</b>: We always strive to give you accurate and useful information, but hey, sometimes mistakes happen. We can't guarantee that everything on the VTT Platform or the Booking Service is spot-on at all times, and we will not be liable to you if you incur any loss, delay or damage as a result of an error by us or our partners or suppliers.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Availability</b>: We try our best to keep things up and running smoothly, but sometimes we need to do maintenance or updates. During those times, the VTT Platform, the Booking Service or associated services may be unavailable so we can’t guarantee the availability, uptime, functionality or accuracy of our services. We apologise for any inconvenience, but we can't be held responsible for any loss or damage caused by the unavailability of our services. We hope you understand.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.3'}
                  title={'Updates and other websites'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    <b>Updates</b>: Sometimes we'll update our platforms (including the VTT Platform and/or the Booking Service), change functions or services, or send out patches to keep things running smoothly. It's your responsibility to make sure you're using the latest version of the VTT Platform app. Stay up to date, and you'll be good to go!
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Other Websites</b>: We might link you to other websites or services operated by us or third parties, including our licensors, partners or suppliers (we refer to these as <b>Third Party Sites</b>). Just a heads up, we're not in control of those Third Party Sites, and we don't endorse their content. So, if anything goes wrong while you're on those sites or when you interact with the content, products, or services available on or through those Third Party Sites, it's not on us. Make sure you take a look at the terms and conditions that apply on Third Party Sites as these terms won’t apply there. Bear in mind that we can remove any link at any time.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.4'}
                  title={'Our responsibility to you'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    <b>
                      PAY ATTENTION TRAIN TRAVELLERS: THIS SECTION SETS OUT OUR RESPONSIBILITY TO YOU AND CONTAINS LIMITATIONS ON OUR LIABILITY AND OUR OBLIGATIONS RELATING TO VIRGIN TRAINS TICKETING, INCLUDING THE VTT PLATFORM AND BOOKING SERVICE.
                    </b>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Our Responsibility for Goods and Services we provide to you</b>: We take responsibility for any foreseeable loss or damage caused by our actions, like if we mess up, breach these terms or don't meet the standards we should when we’re providing the goods and/or services to you.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    As long as we have used reasonable care and skill in providing goods or services to you, we’re not responsible to you for any losses, claims or damages you incur that are not caused by our actions, for things that are beyond our reasonable control and for things we couldn’t or shouldn’t have been expected to know about.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>For instance, we won’t be liable to you:</TandCsPageParagraph>
                  <TandCsUl>
                    <TandCsLi>
                      if you deliberately or negligently didn’t keep your account details safe or gave them to another person
                    </TandCsLi>
                    <TandCsLi>
                      if you acted fraudulently
                    </TandCsLi>
                    <TandCsLi>
                      if you used Virgin Red for business purposes
                    </TandCsLi>
                    <TandCsLi>
                      for loss or corruption of data, information or material, whether arising from a breach of security of the VTT Platform or Booking Service, any virus or malicious program or otherwise
                    </TandCsLi>
                    <TandCsLi>
                      for loss or damage arising from your use of information or goods and/or services provided by a third party (including any train operating company) – but be aware that this doesn’t affect any rights you may have against that third party. The liability of train operating companies is set out in the{' '}
                      <a
                        aria-label='National Rail Conditions of Travel'
                        target={'_blank'}
                        className={'TandCLink'}
                        rel="noreferrer"
                        href={'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf'}>
                        National Rail Conditions of Travel.
                      </a>
                    </TandCsLi>
                  </TandCsUl>
                  <TandCsPageParagraph>
                    And keep in mind, we're not liable for any direct or indirect business or financial loss either, since goods and services provided to you via the VTT Platform are for your personal use only. This includes direct or indirect loss of profits, savings, revenue, contracts or wasted expenditure.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>
                      We're here to make things right, but we have our limits too. So, if you incur any loss or damage and it is foreseeable, our total liability to compensate you is limited to the price you paid or the number of Virgin Points you used for the relevant goods or services (for example, the cost of any train ticket(s) you bought).
                    </b>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Important Note</b>: Nothing in these terms is meant to exclude or limit our liability where it would be unlawful to do so. That means do not exclude our liability for things like injury caused by our negligence, for fraud or fraudulent misrepresentation, or breaching your legal rights.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    We hope this clarifies our responsibilities, but if you have any questions or need further assistance, reach out to us. We're always here to help.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.5'}
                  title={'Sometimes things change'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    We're constantly working behind the scenes to improve the VTT Platform and Booking Service and develop new features. There are also times when changes may be necessary to ensure security, meet legal requirements, for commercial reasons or to keep up with the ever-evolving operation of the VTT Platform, Booking Service and our other businesses.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    As a result, every now and then, we might need to tweak, replace, or temporarily pause certain aspects of the VTT Platform and/or the Booking Service. And you guessed it, that means these terms may need a little update too.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    But don't worry, we've got your back. If we make any significant changes that could affect you, we always do our best to give you a heads-up via the VTT Platform. And remember, the latest version of our terms will always be the version available on the VTT Platform.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    If you keep using the VTT Platform and/or the Booking Service after we've made those changes, we'll assume you accept the updated terms.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.6'}
                  title={'Accessibility'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    If you find the VTT Platform or Booking Service a bit tricky to use or if you have any genius ideas for how we can make it even more user-friendly, don't be shy! Reach out to us using the contact details below. Your valuable suggestions will help us improve our service and ensure a better experience not just for you, but for everyone else too.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.7'}
                  title={'A final word from our lawyers'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Let's wrap up with a few important points from the legal side of things:
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Severance and waiver</b>: If any part of these terms becomes unenforceable, don't worry, the rest of the terms still apply. And if we happen to delay or not enforce something, it doesn't mean we're giving up our rights that we may have. Any waivers of these terms must be in writing and signed by us.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Assignment</b>: We have the right to assign our rights or transfer or sub-contract our obligations under these terms to third parties. You can't transfer or assign your rights or obligations without our written consent.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Governing Law</b>: To the extent permitted by local law, these terms and your relationship with us and any resulting dispute or claim are governed by English law.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Jurisdiction</b>: You agree to submit to the jurisdiction of the English courts for any disputes or claims arising out of these Terms.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    <b>Entire Agreement</b>: These terms, along with any other terms we mention, cover everything about your use of the VTT Platform and the Booking Service. They replace any previous agreements on the subject.
                  </TandCsPageParagraph>
                </TandCsAccordion>
                <TandCsAccordion
                  onClickRemove={handleRemoveItem}
                  onClick={handleActiveItem}
                  section={'2.8'}
                  title={'Finally, if in doubt, get in touch'}
                  active={activeItem}>
                  <TandCsPageParagraph>
                    Got questions? Complaints? Feedback? Or maybe you just want to shower us with compliments? We're all ears!
                    Here's how you can reach out to us:
                  </TandCsPageParagraph>

                  <TandCsPageParagraph>
                    For questions about the Booking Service, email us at{' '}
                    <a aria-label='Virgin Trains Ticketing Support' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                      Virgin Trains Ticketing Support
                    </a>{' '} or call{' '}
                    <a aria-label='0333 241 9128' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'tel:0333 241 9128'}>
                      0333 241 9128
                    </a>{' '} , or visit{' '} <a aria-label='https://trains.virgin.com' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'https://trains.virgin.com'}>
                      https://trains.virgin.com
                    </a>. And hey, if it's really important and it's between 7am and 10pm, just dial that phone number for quick help.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    For questions about your account or signing up, or if it's about Virgin Red, drop us an email at{' '}
                    <a aria-label='vttsupport@virgin.com' target={'_blank'} rel="noreferrer" className={'TandCLink'} href={'mailto:vttsupport@virgin.com'}>
                      vttsupport@virgin.com
                    </a>
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Prefer the old-fashioned way? Grab a pen and paper and write to us at Virgin Red Support, Virgin Red Limited, 66 Porchester Road, London, W2 6ET, United Kingdom.
                  </TandCsPageParagraph>
                  <TandCsPageParagraph>
                    Happy train travels!
                  </TandCsPageParagraph>
                </TandCsAccordion>
              </TandCsSectionItem>
            </TandCsCol>
          </TandCsCol>
        </TandCsRow>
      </TandCsContainer>
    </TandCsWrapper>
  )
}
