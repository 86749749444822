import styled from 'styled-components'
import { colors, media } from '../../style'

export const Nav = styled.nav<{ inHeader: boolean }>`
  color: ${(p) => (p.inHeader ? colors.SECONDARY_COBOLT_50 : colors.SECONDARY_COBALT_120)};
  margin: ${(props) => (props.inHeader ? '12px' : '24px')} 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .breadcrumb-item,
  .breadcrumb-item > a {
    color: ${(p) => (p.inHeader ? colors.SECONDARY_COBOLT_50 : colors.SECONDARY_COBALT_120)};
  }

  .breadcrumb-item svg {
    margin: 0 4px;

    @media ${media.tabletAndHigher} {
      margin: 0 12px;
    }
  }
`
