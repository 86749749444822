import React, { useCallback, useEffect, useRef } from 'react'
import { Container, PagingDotsContainer, ScrollContainer } from './ScrollableBar.styles'

type Props = {
  children?: React.ReactNode[]
}

export const ScrollableBar: React.FC<Props> = ({ children }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const pagingDotsContainerRef = useRef<HTMLDivElement>(null)

  const hidePagingDotsIfNotNeeded = useCallback(() => {
    const scrollWidth = scrollContainerRef.current?.clientWidth || 0
    const itemWidth = scrollContainerRef.current?.children[0].clientWidth || 0
    const numberOfChildren = scrollContainerRef.current?.children.length || 0

    if (scrollWidth > itemWidth * numberOfChildren) {
      pagingDotsContainerRef.current?.classList.add('hidden')
      scrollContainerRef.current?.classList.add('no-paging-dots')
    } else {
      pagingDotsContainerRef.current?.classList.remove('hidden')
      scrollContainerRef.current?.classList.remove('no-paging-dots')
    }
  }, [])

  const scrollToPage = useCallback((page: number) => {
    scrollContainerRef.current?.children?.item(page)?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      hidePagingDotsIfNotNeeded()
    })
    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [hidePagingDotsIfNotNeeded])

  useEffect(() => {
    hidePagingDotsIfNotNeeded()
  }, [hidePagingDotsIfNotNeeded])

  const onScroll = useCallback((event: React.UIEvent<HTMLElement>) => {
    const scrollOffset = event.currentTarget.scrollLeft
    const scrollWidth = scrollContainerRef.current?.clientWidth || 0
    const page = Math.floor((scrollOffset + scrollWidth * 0.5) / scrollWidth)

    for (let i = 0; i < (pagingDotsContainerRef.current?.children.length || 0); i++) {
      if (i === page) {
        pagingDotsContainerRef.current?.children[i].classList.add('active')
      } else {
        pagingDotsContainerRef.current?.children[i].classList.remove('active')
      }
    }
  }, [])

  return (
    <Container>
      <ScrollContainer ref={scrollContainerRef} onScroll={onScroll}>
        {children}
      </ScrollContainer>
      <PagingDotsContainer ref={pagingDotsContainerRef}>
        {children?.map((value, index) => (
          <div
            key={index}
            className={index === 0 ? 'active' : ''}
            onClick={() => {
              scrollToPage(index)
            }}>
            &nbsp;
          </div>
        ))}
      </PagingDotsContainer>
    </Container>
  )
}
