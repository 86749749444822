import styled from 'styled-components'
import { colors, media, Typography } from '../../../style'
import { Button, Col } from 'react-bootstrap'

export const WhyUsSection = styled.section`
  box-shadow: 0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  padding-bottom: 40px;

  @media ${media.desktopAndHigher} {
    padding-bottom: unset;
  }
`

export const WhyUsTitle = styled(Typography.H2Red)`
  margin: 28px 0 36px;

  @media ${media.desktopAndHigher} {
    margin: 8px 0 48px;
    text-align: center;
  }
`

export const WhyUsCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 40px;
  }
`

export const WhyUsImage = styled.img`
  margin-bottom: 32px;
`

export const WhyUsCaption = styled(Typography.Caption)`
  margin-bottom: 12px;
  text-align: center;
`

export const WhyUsText = styled(Typography.Paragraph)`
  text-align: center;
`

export const WhyUsTextEmphasised = styled(Typography.ParagraphItalic)`
  margin-bottom: 0;
  text-align: center;
`

export const SavingButton = styled(Button)`
  color: ${colors.TERTIARY_CHARCOAL_140};
  padding: 0;
  text-decoration-color: ${colors.SECONDARY_COBALT_120};
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;

  &:hover {
    color: ${colors.TERTIARY_CHARCOAL_140};
  }
`

export const Tooltip = styled(Typography.Body)`
  background: ${colors.ALERT_INFORMATION_10};
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(34, 36, 78, 0.2), 0 3px 4px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  padding: 16px;
  text-align: center;
  width: 358px;
`

export const CTAButton = styled(Typography.Button1)`
  margin-bottom: 36px;
  width: 267px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 64px;
  }
`
