import React, { Component, FC, ReactNode } from 'react'

import {
  CookieModalContainer,
  CookieModalHeader,
  CookieModalContent,
  CookieModalBox,
  CookieModalText,
  CookieModalBottom,
  Close,
  Copy,
  HeaderText,
} from './CookieModal.styles'

import {
  CookiePreferenceId,
  CookiePreferences,
  getCookiePreferences,
  getDefaultCookiePreferences,
  setCookiePreferences,
} from '../../util/helpers/cookiePreferences'
import { useCookiePolicy } from '../../context/CookiePolicyProvider'
import CookieTracker from './CookieTracker'
import { cookieDataMap, CookiePreferenceData, necessaryCookiesData } from './cookiePreferencesData'
import Cross from '../Cross'
import { colors, Typography } from '../../style'

interface CookieModalProps {
  close: (cookiesAccepted: boolean) => void
}

interface CookieModalState {
  cookiePreferences: CookiePreferences
}

class CookieModal extends Component<CookieModalProps, CookieModalState> {
  constructor(props: CookieModalProps) {
    super(props)
    this.state = {
      cookiePreferences: getCookiePreferences() ?? getDefaultCookiePreferences(),
    }
  }

  toggleCookiePreference = (cookiePreferenceId: CookiePreferenceId): void => {
    const newCookiePreferences = { ...this.state.cookiePreferences }
    newCookiePreferences[cookiePreferenceId] = !newCookiePreferences[cookiePreferenceId]
    this.setState({ cookiePreferences: newCookiePreferences })
  }

  saveAndClose = (): void => {
    const { close } = this.props
    setCookiePreferences(this.state.cookiePreferences)
    close(true)
  }

  render(): ReactNode {
    const { close } = this.props
    const { cookiePreferences } = this.state
    return (
      <CookieModalContainer>
        <CookieModalBox>
          <CookieModalHeader>
            <HeaderText>Cookie preferences</HeaderText>
            <Close onClick={(): void => close(false)} data-testid="close-cross">
              <Cross size={18} color={colors.TERTIARY_CHARCOAL_140} />
            </Close>
          </CookieModalHeader>
          <CookieModalContent>
            <CookieModalText>
              <Copy>
                We use cookies (yum!) and other technology to track how you interact with us, our partners and to understand you. They
                enable us to personalise your experience and provide you with more relevant content based on things you’ve shown an interest
                in or we think you might like. They also help us keep this site running smoothly. You can change these settings at any time
                by clicking the Cookie Policy Settings at the bottom of the page.
              </Copy>
            </CookieModalText>
            <CookieTracker
              title={necessaryCookiesData.title}
              content={necessaryCookiesData.content}
              isSelected={true}
              toggleCookiePreference={null}
            />
            {Object.entries<CookiePreferenceData>(cookieDataMap).map(([key, data]: [string, CookiePreferenceData]) => {
              const id = key as CookiePreferenceId
              return (
                <CookieTracker
                  key={id}
                  title={data.title}
                  content={data.content}
                  toggleCookiePreference={(): void => this.toggleCookiePreference(id)}
                  isSelected={cookiePreferences[id]}
                />
              )
            })}
          </CookieModalContent>
          <CookieModalBottom>
            <Typography.Button1 onClick={this.saveAndClose}>Save and close</Typography.Button1>
          </CookieModalBottom>
        </CookieModalBox>
      </CookieModalContainer>
    )
  }
}

const CookieModalWrapper: FC = ({ ...props }) => {
  const { isCookieModalVisible, hideCookiePolicyBanner, hideCookiePolicyModal } = useCookiePolicy()
  if (!isCookieModalVisible) return null
  return (
    <CookieModal
      close={(cookiesAccepted): void => {
        hideCookiePolicyModal()
        if (cookiesAccepted) {
          hideCookiePolicyBanner()
        }
      }}
      {...props}
    />
  )
}

export default CookieModalWrapper
