import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import { StyledSection } from '../../pages/Redemption/Redemption.styles'

export const Section = styled(StyledSection)`
  background: ${colors.PRIMARY_NAVY_100};
  padding: 28px 0 18px 0;

  img {
    align-self: center;
    max-width: 100%;

    @media ${media.desktopAndHigher} {
      width: unset;
    }
  }
`

export const Title = styled(Typography.H2White)`
  margin-top: 8px;
  margin-bottom: 28px;

  @media ${media.desktopAndHigher} {
    margin-top: 40px;
  }
`

export const MobileImage = styled.img`
  margin-top: 8px;
`

export const Image = styled.img`
  margin-left: 48px;
  margin-top: 50px;
`

export const Button = styled(Typography.Button1White)`
  margin-top: 40px;
  margin-bottom: 22px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 66px;
    width: 267px;
  }
`
