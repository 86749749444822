import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const Section = styled.section`
  background-color: ${colors.PRIMARY_RED_100};
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0 0 0;
  border-style: solid;
  box-shadow: 0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${media.desktopAndHigher} {
    flex-direction: row;
  }
`

export const Title = styled(Typography.H2White)`
  margin-top: 36px;

  @media ${media.desktopAndHigher} {
    margin-top: 64px;
  }
`

export const Image = styled.div`
  margin-top: 28px;
  display: flex;
  justify-content: center;

  @media ${media.desktopAndHigher} {
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 62px;
  }
`
