import styled from 'styled-components'
import { colors, media } from '../../style'

export const Container = styled.div`
  position: relative;
`

export const ScrollContainer = styled.div`
  display: flex;
  background: ${colors.COLOUR_WARM_WHITE_100};
  box-shadow: inset 0 13px 5px -10px rgba(34, 36, 78, 0.2), inset 0 -11px 8px -10px rgba(34, 36, 78, 0.2);
  padding: 24px 0 64px 0;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;

  ::-webkit-scrollbar {
    display: none;
  }

  &.no-paging-dots {
    padding-bottom: 28px;
  }

  @media ${media.desktopAndHigher} {
    justify-content: center;
  }
`

export const ScrollItem = styled.div`
  flex: none;
  padding: 0 24px;
  scroll-snap-align: center;
  text-align: center;
  width: 100%;

  @media ${media.desktopAndHigher} {
    width: 267px;
  }
`

export const ScrollImage = styled.img`
  margin-bottom: 8px;
`

export const PagingDotsContainer = styled.div`
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 24px;
  width: 100%;

  &.hidden {
    display: none;
  }

  div {
    background: ${colors.PRIMARY_RED_A60};
    border-radius: 10px;
    height: 20px;
    width: 20px;
  }

  div.active {
    background: ${colors.PRIMARY_RED_100};
  }

  div:not(:first-child) {
    margin-left: 20px;
  }
`
