import { CustomerSupportButton, StyledBody, Title, StyledRunningImg, StyledSection } from './HelpSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import illustrationRunningHero from '../../img/illustration-running-hero.svg'
import { ScrollAnimationContainer } from '../ScrollAnimationContainer'
import { PATHS } from '../../util/helpers/paths'
import { useCallback, useState } from 'react'

export const HelpSection = () => {
  const [showAnimation, setShowAnimation] = useState(true)

  const handleAnimationHasCompleted = useCallback(() => {
    setShowAnimation(false)
  }, [setShowAnimation])
  return (
    <StyledSection>
      <Container>
        <Row>
          <Col lg={6} className="d-none d-lg-block">
            <ScrollAnimationContainer
              animationName="fadeInLeft"
              doNotAnimate={!showAnimation}
              onAnimationCompleted={handleAnimationHasCompleted}>
              <StyledRunningImg src={illustrationRunningHero} alt="" />
            </ScrollAnimationContainer>
          </Col>
          <Col lg={6}>
            <Title as="h2">Need a bit of help?</Title>
            <div className="d-inline d-lg-none">
              <ScrollAnimationContainer
                animationName="fadeInLeft"
                doNotAnimate={!showAnimation}
                onAnimationCompleted={handleAnimationHasCompleted}>
                <StyledRunningImg src={illustrationRunningHero} alt="" />
              </ScrollAnimationContainer>
            </div>
            <StyledBody>
              Whether you’re having trouble with a booking, or you’ve got a query about your points, we’ve got the answer. Hit the button
              below and let’s get it sorted.
            </StyledBody>
            <CustomerSupportButton aria-label='Chat to Customer Support' href={PATHS.FAQ} target="_blank" rel="noopener noreferrer">
              Chat to Customer Support
            </CustomerSupportButton>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}
