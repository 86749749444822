import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const IndustrialActionContainer = styled.div`
  background: ${colors.BACKGROUND_GREY};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 20px;
`
export const IndustrialActionSection = styled.section`
  box-shadow: 0 1px 3px 0 rgba(34, 36, 78, 0.2), 0 2px 2px 0 rgba(34, 36, 78, 0.12), 0 0 2px 0 rgba(34, 36, 78, 0.14);
  padding: 20px 0;
  background: ${colors.COLOUR_WHITE};
`
export const IndustrialActionHeaderText = styled(Typography.H3)<{flushBottom?: boolean}>`
  font-weight: 800;
  line-height: 1.5;
  margin: ${(p) => p.flushBottom ? '0 15px' : '0 15px 24px 15px'};
`
export const IndustrialActionParagraph = styled(Typography.Body)<{flushTop?: boolean}>`
  color: ${colors.TERTIARY_CHARCOAL_140};
  margin: ${(p) => p.flushTop ? '0 15px 12px' : '12px 15px'};
`
export const WarningInfoboxContainer = styled.div``
export const WarningInfobox = styled.div<{ lastItem: boolean }>`
  margin: ${(p) => p.lastItem ? '0 15px' : '0 15px 8px 15px'};
  padding: 12px 16px;
  background: ${colors.ALERT_WARNING_10};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const WarningAlertText = styled(Typography.BodyBold)``
export const WarningBodyText = styled(Typography.Body2)``

export const DividerWrap = styled.div`
  margin: 28px 0;
`

export const NothingToSeeContainer = styled.div`
  text-align: center;
  margin: 0 15px;
`
export const NothingToSeeHeader = styled(Typography.NavHeader)``
export const NothingToSeeParagraph = styled(Typography.Body)`
  text-align: center;
  margin: 25px 0 44px 0;
`
export const NothingToSeeLink = styled(Typography.anchorStyle)``
export const NothingToSeeIcon = styled.img`
  width: 48px;
  height: 48px;
  margin: 12px 0 16px 0;
  object-fit: contain;
`

export const RefundTicketsContainer = styled.div`
  margin: 0 15px;
`
export const RefundTicketsTitle = styled(Typography.H3)`
  font-weight: 800;
  line-height: 1.5;
  margin: 0 15px 12px 0;
`
export const RefundTicketsBody = styled(Typography.Body)`
  white-space: pre-line;
`
export const RefundTicketsButton = styled(Typography.Button1)`
  margin-top: 20px;

  @media ${media.tabletAndHigher} {
    width: 269px;
  }
`
