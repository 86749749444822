import React, { ReactElement } from 'react'
import {
  ContentContainer,
  CtaTextDesktop,
  CtaTextMobile,
  FixedContainer,
  Section,
  StoreImage,
  StoreButtonsContainer,
  VTTSmallLogoContainer,
  VTTLogoSmall,
  VTTLogoLarge,
  BreadcrumbContainer,
} from './StickyHeader.styles'
import { ANDROID_STORE_URL, IOS_STORE_URL } from '../../config'
import vttLogoHorizontalOnRed from '../../img/vtt-logo-horizontal-red-gradient.png'
import vttLogoHorizontalOnWhite from '../../img/vtt-logo-horizontal-white-gradient.png'
import tarrowMini from '../../img/tarrow-mini.svg'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import { isIOS, isAndroid } from '../../util/helpers/os'
import { Col, Container, Row } from 'react-bootstrap'
import { usePageHasScrolled } from '../../util/hooks/scroll'
import { useScreenDimensions } from '../../util/hooks/screen'
import { breakpoints } from '../../style/responsive'
import { Breadcrumb, BreadcrumbItem } from '../Breadcrumb/Breadcrumb'

const DESKTOP_SCROLL_THRESHOLD = 20
const MOBILE_SCROLL_THRESHOLD = 80
const TRANSITION_SPEED = 0.6

interface StickyHeaderProps {
  breadcrumb?: BreadcrumbItem[]
  desktopText: ReactElement
  isLogoClickable: boolean
  storeUrl?: string
}

export const StickyHeader = ({ breadcrumb, desktopText, isLogoClickable, storeUrl }: StickyHeaderProps) => {
  const screenWidth = useScreenDimensions().width
  const transitionThreshold = screenWidth >= breakpoints.desktopMin ? DESKTOP_SCROLL_THRESHOLD : MOBILE_SCROLL_THRESHOLD
  const isVisible = usePageHasScrolled(transitionThreshold)
  return (
    <>
      <Container>
        {isLogoClickable ? (
          <a aria-label='VTT Logo' href="/">
            <VTTLogoLarge
              className="d-none d-lg-block"
              src={vttLogoHorizontalOnWhite}
              alt="VTT Logo"
              isVisible={!isVisible}
              transitionSpeed={TRANSITION_SPEED}
            />
          </a>
        ) : (
          <VTTLogoLarge
            className="d-none d-lg-block"
            src={vttLogoHorizontalOnWhite}
            alt="VTT Logo"
            isVisible={!isVisible}
            transitionSpeed={TRANSITION_SPEED}
          />
        )}
      </Container>
      <FixedContainer isVisible={isVisible} transitionSpeed={TRANSITION_SPEED / 2}>
        <ContentContainer>
          <Section>
            <VTTSmallLogoContainer>
              <VTTLogoSmall className="d-lg-none" src={tarrowMini} alt="VTT Small Logo" isVisible={isVisible} transitionSpeed={0} />
              <CtaTextMobile className="d-lg-none">Download&nbsp;the app&nbsp;now!</CtaTextMobile>
            </VTTSmallLogoContainer>
            {isLogoClickable ? (
              <a aria-label='VTT Logo' href="/">
                <VTTLogoSmall
                  className="d-none d-lg-inline"
                  src={vttLogoHorizontalOnRed}
                  alt="VTT Logo"
                  isVisible={isVisible}
                  transitionSpeed={TRANSITION_SPEED}
                />
              </a>
            ) : (
              <VTTLogoSmall
                className="d-none d-lg-inline"
                src={vttLogoHorizontalOnRed}
                alt="VTT Logo"
                isVisible={isVisible}
                transitionSpeed={TRANSITION_SPEED}
              />
            )}
          </Section>
          <Section>
            <CtaTextDesktop className="d-none d-lg-inline">{desktopText}</CtaTextDesktop>
            <StoreButtonsContainer>
              {!isAndroid() && (
                <a aria-label='Download on the App Store'  href={storeUrl || IOS_STORE_URL} target="_blank" rel="noopener noreferrer" tabIndex={-1}>
                  <StoreImage src={appStoreButton} alt="Download on the App Store" />
                </a>
              )}
              {!isIOS() && (
                <a aria-label='Get it on Google Play' href={storeUrl || ANDROID_STORE_URL} target="_blank" rel="noopener noreferrer" tabIndex={-1}>
                  <StoreImage src={googlePlayButton} alt="Get it on Google Play" />
                </a>
              )}
            </StoreButtonsContainer>
          </Section>
        </ContentContainer>
        {breadcrumb && (
          <BreadcrumbContainer>
            <Container>
              <Row>
                <Col>
                  <Breadcrumb items={breadcrumb || []} inHeader />
                </Col>
              </Row>
            </Container>
          </BreadcrumbContainer>
        )}
      </FixedContainer>
    </>
  )
}
