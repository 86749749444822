import React, { FC } from 'react'
import { Switch, Slider, Input } from './ToggleSwitch.styles'

interface ToggleSwitchProps {
  isSelected: boolean
  toggleCookiePreference: null | (() => void)
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({ isSelected, toggleCookiePreference }) => {
  return (
    <Switch>
      <Input
        isToggleable={toggleCookiePreference != null}
        checked={isSelected}
        onChange={
          toggleCookiePreference ??
          ((): void => {
            return
          })
        }
      />
      <Slider />
    </Switch>
  )
}

export default ToggleSwitch
