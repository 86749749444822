import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import { Col } from 'react-bootstrap'
import { FontFamilies } from '../../style/styles'

export const OuterContainer = styled.div`
  background: ${colors.PRIMARY_NAVY_100};
  box-shadow: 0 1px 5px 0 rgba(34, 36, 78, 0.2), 0 3px 4px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  padding: 16px 0 25px 0;
  @media ${media.desktopAndHigher} {
    padding: 16px 0;      
  }
`

export const InnerCol = styled(Col)`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const SmartyPantsImage = styled.img`
    width: 220px;
    margin-top: 4px;
    margin-bottom: 12px;
`

export const Image = styled.img`
    display: none;
    @media ${media.desktopAndHigher} {
        display: block;
        height: 76px;
        margin-right: 16px;
        width: 76px;
    }
`

export const Text = styled(Typography.BodyBoldWhite)`
    text-align: center;
  @media ${media.desktopAndHigher} {
      font-family: ${FontFamilies.spartan};
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.25px;
  }
`

export const TermsLink = styled(Typography.anchorStyle)`
    color: ${colors.COLOUR_WHITE};

    :hover {
        color: ${colors.COLOUR_WHITE};
    }

    :active {
        color: ${colors.COLOUR_WHITE};
    }
    
    @media ${media.desktopAndHigher} {
        font-family: ${FontFamilies.spartan};
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.25px;
    }
`
