import { media, Typography } from '../../style'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { StyledSection } from '../../pages/Redemption/Redemption.styles'

export const Section = styled(StyledSection)`
  padding: 36px 0;

  @media ${media.desktopAndHigher} {
    padding: 64px 0 50px 0;
  }
`

export const DesktopOnlyRow = styled(Row)`
  @media ${media.belowDesktop} {
    display: none;
  }
`

export const SectionCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-top: 36px;
  }

  @media ${media.desktopAndHigher} {
    &:not(:first-child) {
      margin-top: 0;
    }
  }
`

export const SectionColText = styled(SectionCol)`
  justify-content: flex-start;
`

export const SectionTitle = styled(Typography.H2)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 48px;
    text-align: center;
  }
`

export const SectionImage = styled.img`
  order: 2;

  @media ${media.desktopAndHigher} {
    order: 1;
  }
`

export const SectionImageMobileOnly = styled(SectionImage)`
  @media ${media.desktopAndHigher} {
    display: none;
  }
`

export const SectionImageDesktopOnly = styled(SectionImage)`
  @media ${media.belowDesktop} {
    display: none;
  }
`

export const SectionText = styled.div`
  margin-bottom: 28px;
  order: 1;
  width: 100%;

  @media ${media.desktopAndHigher} {
    margin-top: 36px;
    order: 2;
  }

  > :first-child {
    margin-right: 18px;
    width: 23px;
  }

  > :last-child {
    flex: 1;
  }

  img {
    vertical-align: baseline;
  }

  @media ${media.desktopAndHigher} {
    display: flex;
  }
`

export const MobileOnlySectionText = styled(SectionText)`
  @media ${media.desktopAndHigher} {
    display: none;
  }
`
