import { Col, Container, Row } from 'react-bootstrap'
import { Break, Section, Body, ItemTop, Title } from './DiscoverMoreWays.styles'
import { Typography } from '../../style'
import React from 'react'

export type DiscoverMoreWaysItem = {
  icon: string
  title: string
  text: string
}

export type DiscoverMoreWaysProps = {
  items: DiscoverMoreWaysItem[]
}

export const DiscoverMoreWays = ({ items }: DiscoverMoreWaysProps) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col lg={{ span: 6 }}>
            <Title>Discover even more ways to get cheap train tickets</Title>
          </Col>
        </Row>
        <Row>
          <Col lg={{ span: 1, order: 1 }} className="d-none d-lg-block">
            <img src={items[0].icon} alt={items[0].title} width="72px" />
          </Col>
          <Col lg={{ span: 4, order: 1 }}>
            <ItemTop>
              <img src={items[0].icon} alt={items[0].title} width="48px" className="d-lg-none" />
              <Typography.H3Red>{items[0].title}</Typography.H3Red>
            </ItemTop>
            <Body>{items[0].text}</Body>
          </Col>
          <Break />
          <Col lg={{ span: 1, order: 3 }} className="d-none d-lg-block">
            <img src={items[1].icon} alt={items[1].title} width="72px" />
          </Col>
          <Col lg={{ span: 4, order: 3 }}>
            <ItemTop>
              <img src={items[1].icon} alt={items[1].title} width="48px" className="d-lg-none" />
              <Typography.H3Red>{items[1].title}</Typography.H3Red>
            </ItemTop>
            <Body>{items[1].text}</Body>
          </Col>
          <Col lg={{ offset: 1, span: 1, order: 2 }} className="d-none d-lg-block">
            <img src={items[2].icon} alt={items[2].title} width="72px" />
          </Col>
          <Col lg={{ span: 4, order: 2 }}>
            <ItemTop>
              <img src={items[2].icon} alt={items[2].title} width="48px" className="d-lg-none" />
              <Typography.H3Red>{items[2].title}</Typography.H3Red>
            </ItemTop>
            <Body>{items[2].text}</Body>
          </Col>
          <Col lg={{ offset: 1, span: 1, order: 4 }} className="d-none d-lg-block">
            <img src={items[3].icon} alt={items[3].title} width="72px" />
          </Col>
          <Col lg={{ span: 4, order: 4 }}>
            <ItemTop>
              <img src={items[3].icon} alt={items[3].title} width="48px" className="d-lg-none" />
              <Typography.H3Red>{items[3].title}</Typography.H3Red>
            </ItemTop>
            <Body>{items[3].text}</Body>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
