import {setStoredFRTParameters} from "../helpers/storedFRTParameters";
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const useStoreFRTQueryParameters = () => {

    let location = useLocation()
    let search = location.search

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        const campaign = searchParams.get("utm_campaign")
        const source = searchParams.get("utm_source")
        const medium = searchParams.get("utm_medium")
        const vttId = searchParams.get("vtt-id")
        const redId = searchParams.get("red-id")

        if (campaign || source || medium || vttId || redId) {
            const frtParameters: Map<string, string> = new Map()
            if (campaign) frtParameters.set("utm_campaign", campaign)
            if (source) frtParameters.set("utm_source", source)
            if (medium) frtParameters.set("utm_medium", medium)
            if (vttId) frtParameters.set("vtt-id", vttId)
            if (redId) frtParameters.set("red-id", redId)
            setStoredFRTParameters(frtParameters)
        }
    }, [search])
}
