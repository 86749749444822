import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const EarnCardContainer = styled.div`
  align-content: center;
`

export const InnerCardContainer = styled.div`
  margin: auto;
  max-width: 325px;
  @media ${media.desktopAndHigher} {
    max-width: 364px;
  }
`

export const ImageSection = styled.div`
  display: flex;
  justify-content: center;
  height: 141px;
`

export const CardBackground = styled.div<{ lightMode: boolean }>`
  background: ${(p) => (p.lightMode ? colors.COLOUR_WHITE : colors.COLOUR_WARM_WHITE_100)};
  border-radius: 6px;
  box-shadow: ${(p) =>
    p.lightMode
      ? '0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14)'
      : '0 1px 10px 0 rgba(0, 0, 0, 0.4), 0 4px 5px 0 rgba(0, 0, 0, 0.45), 0 2px 4px 0 rgba(0, 0, 0, 0.35)'};
`

export const CardTop = styled.div`
  height: 94px;
`

export const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const DividerSpacer = styled.div`
  height: 1px;
  width: 100%;
`

export const LeftNotchTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid ${colors.PRIMARY_NAVY_100};
  box-shadow: -5px 0 0 0 ${colors.PRIMARY_NAVY_100};
`

export const LeftNotchContainer = styled.div`
  margin-left: -10px;
`

export const RightNotchTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 14px solid ${colors.PRIMARY_NAVY_100};
  box-shadow: 5px 0 0 0 ${colors.PRIMARY_NAVY_100};
`

export const RightNotchContainer = styled.div`
  margin-right: -10px;
  transform: scaleX(-1);
`

export const CardBottom = styled.div`
  text-align: center;
  padding: 18px 16px 32px 16px;
  @media ${media.desktopAndHigher} {
    padding: 18px 24px 32px 24px;
  }
`

export const Title = styled(Typography.IntroBold)`
  color: ${colors.PRIMARY_RED_100_ACCESSIBILITY};
  margin-bottom: 8px;
`
export const Description = styled(Typography.BodyBold)`
  color: ${colors.TERTIARY_CHARCOAL_140};
`

export const TarrowImage = styled.img`
  margin-top: 22px;
  height: 241px;
  width: 200px;
`
