import styled from "styled-components";
import { Typography, colors } from "../../style";

export const HelpFooterBlockContainer = styled.div`
  width: 100%;
  background: ${colors.COLOUR_WHITE};
  box-shadow: 0 1px 3px 0 rgba(34, 36, 78, 0.2), 0 2px 2px 0 rgba(34, 36, 78, 0.12), 0 0 2px 0 rgba(34, 36, 78, 0.14);
`
export const HelpFooterBlockHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 15px 0 15px;
`
export const HelpFooterBlockHeaderText = styled(Typography.NavHeader)`
  padding: 2px 0 0 8px;
`
export const HelpFooterBlockHeaderIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 0;
  object-fit: contain;
`

export const HelpFooterBlockParagraph = styled(Typography.Body)`
  padding: 7px 15px 15px 15px;
`

export const HelpFooterBlockLink = styled(Typography.anchorStyle)`
  color: ${colors.SECONDARY_COBALT_120};
`
