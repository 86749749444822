import React, { FC } from 'react'
import { TOCCol, TOCImg, TOCListGroupItem, TOCRow, TOCSectionItemText } from './TandCTableOfContentsItem.style'

interface TandCsTableOfContentsItemProps {
  section: string
  text: string
  last?: boolean
  sectionLast?: boolean
  onClick: () => void
}

export const TandCsTableOfContentsItem: FC<TandCsTableOfContentsItemProps> = (props) => {
  return (
    <TOCListGroupItem className={'list-item-anchor'} as={'a'} href={`#${props.section}`} onClick={props.onClick}>
      <TOCRow>
        <TOCCol xs={1}>
          {!props.last && !props.sectionLast && <TOCImg src={'/nifty-little-arrow-pointing-right.svg'} alt=""/>}
          {props.sectionLast && <TOCImg width={14} className={'lastItem'} src={'/change-station-fill.svg'} alt=""/>}
          {props.last && <TOCImg width={14} className={'lastItem'} src={'/terminus.svg'} alt=""/>}
        </TOCCol>
        <TOCCol xs={11}>
          <TOCSectionItemText>
            {props.section} {props.text}
          </TOCSectionItemText>
        </TOCCol>
      </TOCRow>
    </TOCListGroupItem>
  )
}
