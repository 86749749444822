import React from 'react'
import { HeaderSection } from '../../components/HeaderSection'
import { WelcomeSection } from '../../components/WelcomeSection'
import { VirginRedSection } from '../../components/VirginRedSection'
import { StickyHeader } from '../../components/StickyHeader'
import { EarnSection } from '../../components/EarnSection'
import { HelpSection } from '../../components/HelpSection'
import { FooterSection } from '../../components/FooterSection'
import bookTicketTarrow from '../../img/booking-journey-tarrow-graphics-01-book.png'
import bookTicketTarrow2x from '../../img/booking-journey-tarrow-graphics-01-book@2x.png'
import bookTicketTarrow3x from '../../img/booking-journey-tarrow-graphics-01-book@3x.png'
import earnPointsTarrow from '../../img/booking-journey-tarrow-graphics-02-earn.png'
import earnPointsTarrow2x from '../../img/booking-journey-tarrow-graphics-02-earn@2x.png'
import earnPointsTarrow3x from '../../img/booking-journey-tarrow-graphics-02-earn@3x.png'
import spendPointsTarrow from '../../img/booking-journey-tarrow-graphics-03-spend.png'
import spendPointsTarrow2x from '../../img/booking-journey-tarrow-graphics-03-spend@2x.png'
import spendPointsTarrow3x from '../../img/booking-journey-tarrow-graphics-03-spend@3x.png'
import { RailcardsSection } from './RailcardsSection/RailcardsSection'
import { FEATURE_FLAGS } from '../../config'

export const LandingPage = () => (
  <>
    <StickyHeader
      desktopText={<>Download the Virgin Trains Ticketing app and book&nbsp;your&nbsp;first&nbsp;ticket&nbsp;now!</>}
      isLogoClickable={false}
    />
    <HeaderSection />
    <WelcomeSection />
    <EarnSection
      imageSet1={[bookTicketTarrow, bookTicketTarrow2x, bookTicketTarrow3x]}
      imageSet2={[earnPointsTarrow, earnPointsTarrow2x, earnPointsTarrow3x]}
      imageSet3={[spendPointsTarrow, spendPointsTarrow2x, spendPointsTarrow3x]}
    />
    {FEATURE_FLAGS.railcardsPage && <RailcardsSection />}
    <VirginRedSection />
    <HelpSection />
    <FooterSection />
  </>
)
