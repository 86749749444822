import styled from 'styled-components'
import { media } from './responsive'
import { Link } from 'react-router-dom'

export const colors = {
  PRIMARY_RED_A60: '#f08283',
  PRIMARY_RED_A80: '#eb595a',
  PRIMARY_RED_10: '#FCEAEA',
  PRIMARY_RED_100: '#e10a0a',
  PRIMARY_RED_100_ACCESSIBILITY: '#cb0909',
  PRIMARY_NAVY_100: '#22244e',
  SECONDARY_COBALT_120: '#006acc',
  SECONDARY_COBALT_140: '#005099',
  SECONDARY_COBOLT_50: '#80c2ff',
  TERTIARY_CHARCOAL_140: '#2d2d2d',
  TERTIARY_CHARCOAL_100: '#4b4b4b',
  TERTIARY_CHARCOAL_20: '#dbdbdb',
  TERTIARY_CHARCOAL_30: '#c9c9c9',
  TERTIART_CHARCOAL_80: '#6f6f6f',
  COLOR_LIGHT_PEACH: '#ffeedb',
  COLOUR_PEACH: '#ffc988',
  COLOUR_WARM_WHITE_100: '#f4f2ee',
  COLOUR_WHITE: '#ffffff',
  COLOUR_WHITE_A60: '#ffffff99',
  COLOUR_WHITE_A80: '#ffffffcc',
  BACKGROUND_GREY: '#f3f3f3',
  ALERT_WARNING_10: '#fff6e4',
  ALERT_INFORMATION_10: '#e5efff',
  COLOUR_MINT_A40: '#aae2bc66',
}

export const FontFamilies = {
  barlow: 'Barlow, sans-serif',
  spartan: 'Spartan, sans-serif',
}

const BaseTypography = styled.div`
  color: ${colors.TERTIARY_CHARCOAL_140};
`

const H1 = styled.h1`
  font-family: ${FontFamilies.spartan};
  font-size: 25px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: -0.37px;
  color: ${colors.TERTIARY_CHARCOAL_140};
  margin-bottom: 0;

  @media ${media.tabletAndHigher} {
    font-size: 40px;
    line-height: 56px;
    letter-spacing: -0.2px;
  }
`

const H2 = styled.h2`
  font-family: ${FontFamilies.spartan};
  font-size: 25px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: -0.37px;
  color: ${colors.TERTIARY_CHARCOAL_140};
  margin-bottom: 0;

  @media ${media.tabletAndHigher} {
    font-size: 34px;
    line-height: 48px;
    letter-spacing: -0.17px;
  }
`

const H3 = styled.h3`
  color: ${colors.TERTIARY_CHARCOAL_140};
  font-family: ${FontFamilies.spartan};
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.2px;

  @media ${media.tabletAndHigher} {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.25px;
  }
`

const IntroBold = styled.h2`
  font-family: ${FontFamilies.spartan};
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: -0.2px;
  margin-bottom: 0;

  @media ${media.tabletAndHigher} {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.25px;
  }
`

const Button1 = styled.a`
  background-color: ${colors.PRIMARY_RED_100};
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(45, 45, 45, 0.25);
  color: ${colors.COLOUR_WHITE};
  cursor: pointer;
  font-family: ${FontFamilies.barlow};
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  line-height: 1.5;
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${colors.PRIMARY_RED_A80};
    color: ${colors.COLOUR_WHITE};
  }
  &:active {
    background-color: ${colors.PRIMARY_RED_A60};
    color: ${colors.COLOUR_WHITE};
  }
`

const BodyBold = styled(BaseTypography)`
  font-family: ${FontFamilies.barlow};
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0;
`

export const Typography = {
  H1,
  H1Bold: styled(H1)`
    font-weight: 900;
  `,
  H1White: styled(H1)`
    color: ${colors.COLOUR_WHITE};
  `,
  H1Red: styled(H1)`
    color: ${colors.PRIMARY_RED_100};
  `,
  H2,
  H2Red: styled(H2)`
    color: ${colors.PRIMARY_RED_100};
  `,
  H2White: styled(H2)`
    color: ${colors.COLOUR_WHITE};
  `,
  H3,
  H3Red: styled(H3)`
    color: ${colors.PRIMARY_RED_100_ACCESSIBILITY};
  `,
  HeaderCTA: styled(BaseTypography)`
    color: ${colors.PRIMARY_RED_100};
    font-family: ${FontFamilies.spartan};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.14px;
    line-height: 20px;

    @media ${media.desktopAndHigher} {
      line-height: 24px;
    }
  `,
  NavHeader: styled(BaseTypography)`
    font-family: ${FontFamilies.spartan};
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.2px;
  `,
  Caption: styled(BaseTypography)`
    font-family: ${FontFamilies.barlow};
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
  `,
  Intro: styled(BaseTypography)`
    font-family: ${FontFamilies.spartan};
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.2px;

    @media ${media.tabletAndHigher} {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.25px;
    }
  `,
  IntroBold,
  IntroBoldWhite: styled(IntroBold)`
    color: ${colors.COLOUR_WHITE};
  `,
  IntroBoldRed: styled(IntroBold)`
    color: ${colors.PRIMARY_RED_100};
  `,
  Body: styled(BaseTypography)`
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0;
  `,
  BodyBold,
  BodyBoldWhite: styled(BodyBold)`
    color: ${colors.COLOUR_WHITE};
  `,
  Body2: styled(BaseTypography)`
    font-family: ${FontFamilies.barlow};
    font-size: 14px;
    line-height: 20px;
  `,
  Button1,
  Button1White: styled(Button1)`
    background-color: ${colors.COLOUR_WHITE};
    color: ${colors.PRIMARY_RED_100};

    &:hover {
      background-color: ${colors.COLOUR_WHITE_A80};
      color: ${colors.PRIMARY_RED_100};
    }
    &:active {
      background-color: ${colors.COLOUR_WHITE_A60};
      color: ${colors.PRIMARY_RED_100};
    }
  `,
  Button1Outline: styled(Button1)`
    background-color: ${colors.COLOUR_WHITE};
    border: solid 1px ${colors.PRIMARY_RED_100};
    box-shadow: unset;
    color: ${colors.PRIMARY_RED_100};

    &:hover {
      background-color: ${colors.PRIMARY_RED_100};
      border: none;
      color: ${colors.COLOUR_WHITE};
    }
    &:active {
      background-color: ${colors.PRIMARY_RED_A60};
      border: none;
      color: ${colors.COLOUR_WHITE};
    }
  `,
  ButtonLink: styled(Link)`
    background-color: ${colors.PRIMARY_RED_100};
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(45, 45, 45, 0.25);
    color: ${colors.COLOUR_WHITE};
    cursor: pointer;
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
    font-weight: 600;
    height: 36px;
    line-height: 1.5;
    text-decoration: none;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${colors.PRIMARY_RED_A80};
      color: ${colors.COLOUR_WHITE};
    }
    &:active {
      background-color: ${colors.PRIMARY_RED_A60};
      color: ${colors.COLOUR_WHITE};
    }
  `,
  LinkStyle: styled(Link)`
    color: ${colors.SECONDARY_COBALT_140};
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }

    :active {
      text-decoration: underline;
      opacity: 0.8;
    }
  `,
  anchorStyle: styled.a`
    color: ${colors.SECONDARY_COBALT_140};
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }

    :active {
      text-decoration: underline;
      opacity: 0.8;
    }
  `,
  IntroLink: styled(Link)`
    color: ${colors.SECONDARY_COBALT_120};
    font-family: ${FontFamilies.spartan};
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.2px;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }

    :active {
      text-decoration: underline;
      opacity: 0.8;
    }

    @media ${media.tabletAndHigher} {
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.25px;
    }
  `,
  Paragraph: styled.p`
    font-family: ${FontFamilies.barlow};
  `,
  ParagraphItalic: styled.p`
    font-family: ${FontFamilies.barlow};
    font-style: italic;
  `,
  BodyList: styled.li`
    font-family: ${FontFamilies.barlow};
    font-size: 16px;
  `,
  OrderedList: styled.ol`
    font-family: ${FontFamilies.barlow};
  `,
}
