import styled from 'styled-components'
import { colors, Typography, media } from '../../style'

import { Accordion, Card, Row, Col } from 'react-bootstrap'

interface cornerAngleRadiansProps {
  cornerAngleRadians: any
}

interface BevelSadowProps {
  offset?: boolean
}

export const TandCsAccordionContainer = styled(Accordion)`
  margin-bottom: 24px;
  scroll-margin: 200px;
`

export const TandCsAccordionItem = styled(Accordion.Item)`
  flex: 1;
  flex-direction: row;
  border-color: transparent;
  background-color: transparent !important;
`

export const TandCsAccordionHeader = styled(Card.Header)`
  position: relative;
  display: flex;

  &.card-header {
    border-bottom: 0 !important;
    background-color: transparent !important;
    padding: 0;
    z-index: 0;
    @media ${media.desktopAndHigher} {
      padding: 0 1rem;
    }
  }
`

export const TandCsAccordionHeaderButton = styled(Accordion.Button)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  color: #212529;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  padding: 2.5rem 0;
  margin-left: -11px;

  :after {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url('./components-desktop-expand-32-px-active.svg');
    background-size: 32px;
  }

  :focus {
    background-color: transparent;
    box-shadow: none;
  }

  :not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    background-size: 32px;
  }

  :not(.collapsed)::after {
    content: '';
    background-image: url('/components-desktop-contract-32-px-active.svg');
    transform: rotate(-360deg);
    background-size: 32px;
  }
`

export const TandCsAccordionHeaderTextContainer = styled.div`
  position: relative;
  flex-direction: row;
  flex: 1;
  width: 100%;
  padding: 1rem;
`

export const TandCsAccordionHeaderSection = styled(Typography.IntroBold)`
  padding-top: 10px;
`

export const TandCsAccordionHeaderTitle = styled(Typography.IntroBoldRed)`
  margin-bottom: 0;
`

export const TandCsAccordionBody = styled(Accordion.Body)`
  background-color: ${colors.COLOUR_WHITE};
  padding: 0;
  @media ${media.desktopAndHigher} {
    padding: 0 1rem;
  }
`

export const BeveledDiv = styled.span<cornerAngleRadiansProps>`
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 0.1rem 1.4rem;
  background-color: ${colors.COLOUR_WHITE};
  border: 2px solid ${colors.TERTIARY_CHARCOAL_20});
  ${({ cornerAngleRadians }) => `
  clip-path: polygon(
    ${Math.sin(cornerAngleRadians[0])}rem 0%,
    0% ${Math.cos(cornerAngleRadians[0])}rem,
  
    0% calc(100% - ${Math.cos(cornerAngleRadians[3])}rem),
    ${Math.sin(cornerAngleRadians[3])}rem 100%,
  
    calc(100% - ${Math.sin(cornerAngleRadians[2])}rem) 100%,
    100% calc(100% - ${Math.cos(cornerAngleRadians[2])}rem),
  
    100% ${Math.cos(cornerAngleRadians[1])}rem,
    calc(100% - ${Math.sin(cornerAngleRadians[1])}rem) 0%
  );
`} @media ${media.desktopAndHigher} {
    padding: 1.4rem;
  }
`

export const TandCsAccordionRow = styled(Row)``

export const TandCsAccordionCol = styled(Col)`
  padding: 0;
  @media ${media.desktopAndHigher} {
    padding: 0 1rem;
  }
`

export const TandCsAccordionHeaderBevelShadow = styled.span<BevelSadowProps>`
  filter: drop-shadow(0 ${(props) => (props.offset ? 24 : 0)}px 0.13rem rgb(34 36 78 / 20%));
  width: 100%;
`
export const TandCsAccordionHeaderBevelShadowOpen = styled.span<BevelSadowProps>`
  filter: drop-shadow(0 4px 0.13rem rgb(34 36 78 / 13%));
  width: 100%;
`

export const TandCsAccordionBodyContainer = styled.div`
  padding: 1rem 0;
  @media ${media.desktopAndHigher} {
    padding: 1.5rem 1rem 1rem 1rem;
  }

  .TandCLink {
    color: ${colors.TERTIARY_CHARCOAL_140};
  }
`
