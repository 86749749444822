import React from 'react'
import { StyledFamilyImg, Title, StyledIntro, StyledIntroBold, StyledSection, VRButton, VRLogo } from './VirginRedSection.styles'
import { Col, Container, Row } from 'react-bootstrap'
import vrLogo from '../../img/vr-logo-red-circle.svg'
import mobileIllustrationFamily from '../../img/illustration-family.svg'
import { ScrollAnimationContainer } from '../ScrollAnimationContainer'
import { PATHS } from '../../util/helpers/paths'

export const VirginRedSection = () => {
  return (
    <StyledSection>
      <Container>
        <Row>
          <Col lg={7}>
            <VRLogo src={vrLogo} className="d-lg-none" alt="Virgin Red Logo" />
            <Title as="h2">Get set for a world of adventure with Virgin Red.</Title>
          </Col>
          <Col lg={5} className="d-none d-lg-flex justify-content-end align-items-top">
            <VRLogo src={vrLogo} className="d-none d-lg-inline" alt="Virgin Red Logo" />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <StyledIntroBold>Looking for a catch? We can’t give you one. But we can pile on the perks.</StyledIntroBold>
            <StyledIntro>You can also earn and spend points on flights, holidays, gifts, going out and more with Virgin Red.</StyledIntro>
            <ScrollAnimationContainer animationName="fadeInRight">
              <StyledFamilyImg className="d-lg-none" src={mobileIllustrationFamily} alt="" />
            </ScrollAnimationContainer>
            <VRButton href={PATHS.VIRGIN_RED} target="_blank" rel="noopener noreferrer">
              Join Virgin Red
            </VRButton>
          </Col>
          <Col>
            <ScrollAnimationContainer animationName="fadeInRight">
              <StyledFamilyImg className="d-none d-lg-block" src={mobileIllustrationFamily} alt="" />
            </ScrollAnimationContainer>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}
