import React, { FC, useEffect, useState } from 'react'
import {
  TOCCol,
  TOCGeneralHeader,
  TOCListGroup,
  TOCListGroupItem,
  TOCRow,
  TOCSectionHeader,
  TOCImgGeneral,
} from './TandCTableOfContents.style'
import { TandCsTableOfContentsItem } from '../TandCTableOfContentsItem'
import { FEATURE_FLAGS } from '../../config'

interface TOCProps {
  onClick: (item: string) => void
}

type TandCsItem = {
  text: string
  section: string
}

const termsAndConditionItemsSection1: TandCsItem[] = [
  {
    text: 'Status of Booking Service Terms',
    section: '1.1',
  },
  {
    text: 'Your use of the Booking Service',
    section: '1.2',
  },
  {
    text: 'National Rail Conditions of Travel',
    section: '1.3',
  },
  {
    text: 'Issue of rail tickets',
    section: '1.4',
  },
  {
    text: 'Accessing your tickets',
    section: '1.5',
  },
  {
    text: 'Fees and charges',
    section: '1.6',
  },
  {
    text: 'Cancellations, changes and refunds',
    section: '1.7',
  },
  {
    text: 'Split ticketing',
    section: '1.8',
  },
  ...FEATURE_FLAGS.railcardsPage ? [{
    text: 'Railcards',
    section: '1.9',
  }] : [],
]

const termsAndConditionItemsSection2: TandCsItem[] = [
  {
    text: 'Your responsibility to us',
    section: '2.1',
  },
  {
    text: 'Accuracy and availability of the VTT Platform and Booking Service',
    section: '2.2',
  },
  {
    text: 'Updates and other websites',
    section: '2.3',
  },
  {
    text: 'Our responsibility to you',
    section: '2.4',
  },
  {
    text: 'Sometimes things change',
    section: '2.5',
  },
  {
    text: 'Accessibility',
    section: '2.6',
  },
  {
    text: 'A final word from our lawyers',
    section: '2.7',
  },
  {
    text: 'Finally, if in doubt, get in touch',
    section: '2.8',
  },
]

export const TandCsTableOfContents: FC<TOCProps> = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollPosition, setScrollPosition])
  return (
    <aside>
      <TOCListGroup flush>
        <TOCListGroupItem className={'list-item-anchor-general'} as={'a'} href={'#G'}>
          <TOCRow>
            <TOCCol xs={1}>
              <TOCImgGeneral src={'/icon-account-t-cs-24-px.svg'} alt=""/>
            </TOCCol>
            <TOCCol xs={11}>
              <TOCGeneralHeader>General Terms and Conditions</TOCGeneralHeader>
            </TOCCol>
          </TOCRow>
        </TOCListGroupItem>
        <TOCListGroupItem className={'list-item-anchor'} as={'a'} href={'#1'}>
          <TOCSectionHeader>
            Section 1 <br />
            Booking Service
          </TOCSectionHeader>
        </TOCListGroupItem>
        {termsAndConditionItemsSection1.map((termsItem, index) => (
          <TandCsTableOfContentsItem
            onClick={() => props.onClick(termsItem.section)}
            section={termsItem.section}
            text={termsItem.text}
            sectionLast={index === termsAndConditionItemsSection1.length - 1}
            key={index}
          />
        ))}
      </TOCListGroup>

      <TOCListGroup flush>
        <TOCListGroupItem className={'list-item-anchor'} as={'a'} href={'#2'}>
          <TOCSectionHeader>
            Section 2 <br />
            Terms of Use
          </TOCSectionHeader>
        </TOCListGroupItem>
        {termsAndConditionItemsSection2.map((termsItem, index) => (
          <TandCsTableOfContentsItem
            onClick={() => props.onClick(termsItem.section)}
            section={termsItem.section}
            text={termsItem.text}
            last={index === termsAndConditionItemsSection2.length - 1}
          />
        ))}
      </TOCListGroup>
      {scrollPosition > 100 && (
        <TOCListGroup id={'scroll-to-top'} flush>
          <TOCListGroupItem className={'list-item-anchor-general'} as={'a'} href={'#top-of-screen'}>
            <TOCRow>
              <TOCCol xs={1}>
                <TOCImgGeneral src={'/icon-account-t-cs-24-px.svg'} alt=""/>
              </TOCCol>
              <TOCCol xs={11}>
                <TOCGeneralHeader>Scroll To Top</TOCGeneralHeader>
              </TOCCol>
            </TOCRow>
          </TOCListGroupItem>
        </TOCListGroup>
      )}
    </aside>
  )
}
