import styled from 'styled-components'
import { colors, media, Typography } from '../../../style'

export const Outer = styled.section`
  background: ${colors.COLOUR_MINT_A40};
  box-shadow: 0 1px 10px 0 rgba(34, 36, 78, 0.2), 0 4px 5px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  padding: 44px 0 54px;

  @media ${media.desktopAndHigher} {
    padding: 64px 0 76px;
  }
`

export const Title = styled(Typography.H2Red)`
  margin-bottom: 28px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 36px;
  }
`

export const SubTitle = styled(Typography.Caption)`
  margin-bottom: 12px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 16px;
  }
`

export const Body = styled(Typography.Paragraph)`
  margin-bottom: 40px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 72px;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const CardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 40px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 64px;
  }
`

export const CardCol = styled.div`
  width: calc(50% - 12px);

  @media ${media.desktopAndHigher} {
    width: calc(25% - 18px);
  }
`

export const RailcardCard = styled.div`
  background: ${colors.COLOUR_WHITE};
  border-radius: 8px;
  padding: 14px 12px 12px;

  @media ${media.desktopAndHigher} {
    padding: 18px 16px 16px;
  }
`

export const RailcardCardDisabled = styled(RailcardCard)`
  background: ${colors.TERTIARY_CHARCOAL_20};
`

export const RailcardTitle = styled(Typography.H3)`
  font-size: 15px;
  line-height: 22px;

  @media ${media.desktopAndHigher} {
    font-size: 18px;
    line-height: 26px;
  }
`

export const RailcardPill = styled.div<{ backgroundColor: string }>`
  width: 40px;
  height: 8px;
  border-radius: 6px;
  background-color: ${(p) => p.backgroundColor};
`

export const CTAButton = styled(Typography.Button1)`
  margin-bottom: 48px;
  width: 267px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 96px;
  }
`

export const NotAvailableTitle = styled(Typography.Caption)`
  margin-bottom: 24px;
`

export const NotAvailableCardRow = styled(CardRow)`
  margin-bottom: 24px;
  row-gap: 24px;
`
