import styled from 'styled-components'
import { colors, media, Typography, ZIndex } from '../../style'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const FixedContainer = styled.div<{ isVisible: boolean; transitionSpeed: number }>`
  position: fixed;
  background-color: ${colors.COLOUR_WHITE};
  box-shadow: 0 1px 5px 0 rgba(34, 36, 78, 0.2), 0 3px 4px 0 rgba(34, 36, 78, 0.12), 0 2px 4px 0 rgba(34, 36, 78, 0.14);
  width: ${(p) => (p.isVisible ? '100%' : 0)};
  overflow: hidden;
  z-index: ${ZIndex.stickyHeader};
`

export const FixedContentContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding-top: 22px;
  padding-bottom: 22px;

  @media ${media.desktopAndHigher} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

export const Section = styled.div`
  display: flex;

  @media ${media.desktopAndHigher} {
    align-items: center;
  }
`

export const VTTSmallLogoContainer = styled.div`
  display: flex;
`

export const VTTLogoSmall = styled.img<{ isVisible: boolean; transitionSpeed: number }>`
  height: 25px;
`

export const VTTLogoLarge = styled.img<{ isVisible: boolean; transitionSpeed: number }>`
  height: 100px;

  @media ${media.desktopAndHigher} {
    height: 120px;
    align-self: center;
    // Fade the logo in/out with a delay
    transition: opacity ${(p) => p.transitionSpeed}s ${(p) => (p.isVisible ? p.transitionSpeed / 2 : 0)}s;
    opacity: ${(p) => (p.isVisible ? 1 : 0)};
  }
`

export const VTTLogoLink = styled(Link)``

export const CtaTextDesktop = styled(Typography.HeaderCTA)`
  margin-left: 15px;
  text-align: left;
  @media ${media.desktopAndHigher} {
    margin-right: 25px;
    text-align: right;
    display: inline;
  }
`

export const StoreButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${media.tabletAndHigher} {
    align-self: center;
    flex-direction: row;
  }
`

export const StoreImage = styled.img`
  height: 40px;
  cursor: pointer;

  &:hover,
  &:active {
    border-radius: 6px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  }

  &:first-child {
    margin-right: 0;
    margin-bottom: 5px;
  }

  @media ${media.tabletAndHigher} {
    &:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`

export const FixedHeaderRow = styled(Row)`
  padding: 0 1rem;
`

export const FixedHeaderCol = styled(Col)`
  padding-left: 0;
`

export const BreadcrumbContainer = styled.div`
  background: ${colors.PRIMARY_NAVY_100};
`
