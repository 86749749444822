import React, { LegacyRef, useCallback, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  ImageContainer,
  Image,
  ImagesWrapper,
  StyledSection,
  Subtitle,
  Title,
  MiddleImagesWrapper,
  ImagesContainer,
  RedText,
  BodyItem,
} from './WelcomeSection.styles'
import appMockupHomepage from '../../img/app-mockups/Bezel-iPhone_X_Homepage.png'
import appMockupHomepage2x from '../../img/app-mockups/Bezel-iPhone_X_Homepage@2x.png'
import appMockupSearchResults from '../../img/app-mockups/Screen-iPhone_X_SearchResults.png'
import appMockupSearchResults2x from '../../img/app-mockups/Screen-iPhone_X_SearchResults@2x.png'
import appMockupConfirmation from '../../img/app-mockups/Screen-iPhone_X_Confirmation.png'
import appMockupConfirmation2x from '../../img/app-mockups/Screen-iPhone_X_Confirmation@2x.png'
import appMockupETickets from '../../img/app-mockups/Screen-iPhone_X_eTickets.png'
import appMockupETickets2x from '../../img/app-mockups/Screen-iPhone_X_eTickets@2x.png'
import iconSplitTicketing from '../../img/icon-split-ticketing-vtt-st-identity.svg'
import { formatImageSet } from '../../util/helpers/image'
import { ScrollAnimationContainer } from '../ScrollAnimationContainer'
import { useIsDesktop } from '../../util/hooks/screen'
import { Typography } from '../../style'
import { FEATURE_FLAGS } from '../../config'
import { PATHS } from '../../util/helpers/paths'

const animationDelay = 0.1

export const WelcomeSection = () => {
  const animationContainerRef = useRef<HTMLDivElement>()
  const isDesktop = useIsDesktop()
  const animationThreshold = isDesktop ? 0.5 : 0

  const getBoundingRect = useCallback(() => {
    return animationContainerRef.current?.getBoundingClientRect()
  }, [])

  const animatedImage = useCallback(
    (imageSet: string[], delay: number, altText: string) => (
      <ImageContainer>
        <ScrollAnimationContainer
          animationName="fadeInUp"
          getBoundingRect={isDesktop ? getBoundingRect : undefined}
          threshold={animationThreshold}
          style={{ animationDelay: `${delay}s` }}>
          <Image srcSet={formatImageSet(imageSet)} alt={altText} />
        </ScrollAnimationContainer>
      </ImageContainer>
    ),
    [isDesktop, getBoundingRect, animationThreshold]
  )

  return (
    <StyledSection>
      <Container>
        <Row>
          <Col lg={6}>
            <Title as="h2">Why choo-choose Virgin Trains Ticketing?</Title>
            <Subtitle>
              Book your train tickets for <RedText>any</RedText> train, on <RedText>any</RedText> route, to <RedText>anywhere</RedText> on
              the National Rail network and get rewarded.
            </Subtitle>
            <BodyItem>
              <Typography.H3Red as="span">1.</Typography.H3Red>
              <Typography.Intro>Booking fees? Never. Zero, zilch, nada.</Typography.Intro>
            </BodyItem>
            <BodyItem>
              <Typography.H3Red as="span">2.</Typography.H3Red>
              <Typography.Intro>
                Rewards? Always. Join Virgin Red and earn Virgin Points on every journey, then use them to{' '}
                {FEATURE_FLAGS.redemptionPage ? (
                  <Typography.IntroLink aria-label='cut the cost of future travel' to={PATHS.REDEMPTION}>cut the cost of future travel.</Typography.IntroLink>
                ) : (
                  <>cut the cost of future travel.</>
                )}
              </Typography.Intro>
            </BodyItem>
            <BodyItem>
              <Typography.H3Red as="span">3.</Typography.H3Red>
              <div className="d-flex">
                <img src={iconSplitTicketing} alt="" />
                &nbsp;
                {FEATURE_FLAGS.splitTicketsPage ? (
                  <Typography.IntroLink aria-label='wallet happy savings!' to={PATHS.SPLIT_TICKETS}>= wallet happy savings!</Typography.IntroLink>
                ) : (
                  <Typography.Intro>= wallet happy savings!</Typography.Intro>
                )}
              </div>
            </BodyItem>
            <BodyItem>
              <Typography.H3Red as="span">4.</Typography.H3Red>
              <Typography.Intro>
                Plus,{' '}
                {FEATURE_FLAGS.cheapTicketsPage ? (
                  <Typography.IntroLink aria-label={"discover ways to save even more"} to={PATHS.CHEAP_TICKETS}>discover ways to save even more…</Typography.IntroLink>
                ) : (
                  <>discover ways to save even more…</>
                )}
              </Typography.Intro>
            </BodyItem>
          </Col>
          <Col lg={6}>
            <div ref={animationContainerRef as LegacyRef<HTMLDivElement>}>
              <ImagesContainer>
                <ImagesWrapper>
                  {animatedImage([appMockupHomepage, appMockupHomepage2x], animationDelay, 'App mockup illustration')}
                  <MiddleImagesWrapper>
                    {animatedImage([appMockupSearchResults, appMockupSearchResults2x], 0, '')}
                    {animatedImage([appMockupConfirmation, appMockupConfirmation2x], 3 * animationDelay, '')}
                  </MiddleImagesWrapper>
                  {animatedImage([appMockupETickets, appMockupETickets2x], 2 * animationDelay, '')}
                </ImagesWrapper>
              </ImagesContainer>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}
