import { DiscoverMoreWaysItem } from './DiscoverMoreWays'
import iconBrandAdvance from '../../img/icon-brand-advance.svg'
import iconBrandRailcard from '../../img/icon-brand-railcard.svg'
import iconBrandOffPeak from '../../img/icon-brand-offpeak.svg'
import iconBrandGroup from '../../img/icon-brand-group.svg'
import iconSplitTicket from '../../img/icon-split-ticketing-vtt-st-lcon.svg'

export const bookTicketsInAdvance: DiscoverMoreWaysItem = {
  icon: iconBrandAdvance,
  title: 'Book tickets in advance',
  text: 'Did you know you can book Advance tickets up to 3 months before the day you want to travel? Don’t worry, booking a week or even a few days in advance can still get you good last minute discounts.',
}

export const useARailcard: DiscoverMoreWaysItem = {
  icon: iconBrandRailcard,
  title: 'Use a Railcard',
  text: 'If you have a National Railcard, you can save up to 1/3 on train tickets on most routes. Add your existing Railcard to any journey in our app and see the savings roll on in.',
}

export const travelOffPeak: DiscoverMoreWaysItem = {
  icon: iconBrandOffPeak,
  title: 'Travel Off-Peak',
  text: 'We know it’s not always possible, but it’s cheaper to travel Off-Peak. If you can, avoid travelling between 6:30am - 9:30 and 4pm - 7pm. (Who wants to be stuck on a crowded train anyway?)',
}

export const groupSave: DiscoverMoreWaysItem = {
  icon: iconBrandGroup,
  title: 'Travel as a group and use GroupSave',
  text: 'You can save up to 34% when you travel in a group of 3 - 9 people and buy a GroupSave train ticket. Not all trains offer GroupSave discounts, but it’s a great way to get cheap train travel without a Railcard.',
}

export const splitTickets: DiscoverMoreWaysItem = {
  icon: iconSplitTicket,
  title: 'Split tickets',
  text: 'Save on journeys across Great Britain with our nifty split tickets. They save you money by splitting one train journey into multiple tickets, so you get the same journey, for less.',
}
