import { Col, Container, Row } from 'react-bootstrap'
import { Section, Button, Image, Title, MobileImage } from './VirginRedHero.styles'
import { Typography } from '../../style'
import { formatImageSet } from '../../util/helpers/image'
import virginRed from '../../img/graphic-virgin-red.png'
import virginRed2x from '../../img/graphic-virgin-red@2x.png'
import virginRed3x from '../../img/graphic-virgin-red@3x.png'
import React, { ReactElement } from 'react'

interface VirginRedHeroProps {
  title: string
  body: string | ReactElement
  buttonTitle: string
}

export const VirginRedHero = ({ title, body, buttonTitle }: VirginRedHeroProps) => (
  <Section>
    <Container>
      <Row>
        <Col className="d-flex flex-column">
          <Title>{title}</Title>
          <Typography.BodyBoldWhite>{body}</Typography.BodyBoldWhite>
          <MobileImage className="d-lg-none" srcSet={formatImageSet([virginRed, virginRed2x, virginRed3x])} alt="" />
          <Button href="https://virgin.com/virgin-red" target="_blank">
            {buttonTitle}
          </Button>
        </Col>
        <Col className="d-none d-lg-block">
          <Image srcSet={formatImageSet([virginRed, virginRed2x, virginRed3x])} alt="" />
        </Col>
      </Row>
    </Container>
  </Section>
)
