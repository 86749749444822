import React, { FC } from 'react'
import { Body, CookieTrackerContainer, TitleText, ToggleSwitchContainer, TopSection } from './CookieTracker.styles'
import ToggleSwitch from './ToggleSwitch'

export interface CookieTrackerProps {
  title: string
  content: string
  isSelected: boolean
  toggleCookiePreference: null | (() => void)
  isLast?: boolean
}

const CookieTracker: FC<CookieTrackerProps> = ({ title, content, isSelected, toggleCookiePreference, isLast }) => (
  <CookieTrackerContainer isLast>
    <TopSection>
      <TitleText style={{ textAlign: 'left' }}>{title}</TitleText>
      <ToggleSwitchContainer>
        <ToggleSwitch isSelected={isSelected} toggleCookiePreference={toggleCookiePreference} />
      </ToggleSwitchContainer>
    </TopSection>
    <Body>{content}</Body>
  </CookieTrackerContainer>
)

export default CookieTracker
