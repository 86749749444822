import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import { Col } from 'react-bootstrap'

export const StyledSection = styled.section`
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0 0 0;
  border-style: solid;
`

export const HeaderSection = styled.section`
  background: ${colors.PRIMARY_RED_100};
  padding-bottom: 48px;
`

export const HeaderTitle = styled(Typography.H1White)`
  margin-bottom: 16px;
`

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  > :not(:first-child) {
    margin-top: 29px;
  }

  @media ${media.desktopAndHigher} {
    flex-direction: row;

    > :not(:first-child) {
      margin-top: 0;
      margin-left: 29px;
    }
  }
`

export const HeaderImg = styled.img`
  @media ${media.desktopAndHigher} {
    margin-top: -155px;
    float: right;
  }
`

export const ScrollContainer = styled.section``

export const Section1 = styled.section``

export const Section1Title = styled(Typography.H2Red)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 36px;
  }
`

export const Section1IntroBold = styled(Typography.IntroBold)`
  margin-top: 36px;
`

export const Section1Intro = styled(Typography.Intro)`
  margin-top: 16px;
  margin-bottom: 36px;
`

export const Section1Images = styled.div`
  display: flex;
  justify-content: center;

  @media ${media.desktopAndHigher} {
    align-items: flex-start;
    justify-content: flex-end;

    margin-bottom: -32px;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

export const Section2 = styled(StyledSection)`
  background: ${colors.COLOUR_WARM_WHITE_100};
  padding: 36px 0;

  @media ${media.desktopAndHigher} {
    padding: 64px 0;
  }
`

export const Section2TopCol = styled(Col)`
  margin-bottom: 32px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 0;
  }
`

export const Section2Title = styled(Typography.H2)`
  margin-bottom: 48px;
`

export const Section2ItemTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 12px;

  img {
    margin-right: 12px;
  }

  * {
    margin-bottom: 0;
  }
`

export const Section2Body = styled(Typography.Body)`
  margin-bottom: 12px;
`
