import React from 'react'
import { FooterSection } from '../../components/FooterSection'
import { FixedHeader } from '../../components/FixedHeader'
import { TandCsSection } from '../../components/TandCsSection'

export const TermsAndConditionsPage = () => (
  <>
    <FixedHeader />
    <TandCsSection />
    <FooterSection />
  </>
)
