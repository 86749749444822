import React from 'react'
import QRCode from 'react-qr-code'
import { QRContainer, OuterContainer, LogoContainer } from './HandWithQRCode.styles'
import handWithPhone from '../../img/hand-with-phone.png'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'

interface HandWithQRCodeProps {
  url: string
}

export const HandWithQRCode = ({ url }: HandWithQRCodeProps) => {
  return (
    <OuterContainer>
      <img src={handWithPhone} alt="Hand holding a phone" />
      <QRContainer>
        <QRCode value={url} size={105} />
      </QRContainer>
      <LogoContainer>
        <img src={vttLogoDiagonal} width={128} alt="Hand holding a phone" />
      </LogoContainer>
    </OuterContainer>
  )
}
