import styled from 'styled-components'
import { colors, media, Typography, ZIndex } from '../../style'

export const CookieConsentContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ZIndex.cookieConsent};
`

export const CookieConsentModal = styled.div`
  background-color: ${colors.COLOUR_WHITE};
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  flex-shrink: 0;
  padding: 25px 25px;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media ${media.desktopAndHigher} {
    padding: 40px 36px;
  }
`

export const ModalContentWrapper = styled.div`
  @media ${media.desktopAndHigher} {
    margin: 0 auto;
    max-width: 1000px;
  }
`

export const Subheading = styled(Typography.Intro)`
  text-align: left;
`

export const Spacer = styled.div`
  margin-bottom: 16px;
  @media ${media.desktopAndHigher} {
    margin-bottom: 40px;
  }
`

export const Copy = styled(Typography.Body)`
  display: inline-block;
`

export const StyledCopyAnchor = styled(Typography.BodyBold)`
  display: inline-block;
  color: ${colors.PRIMARY_RED_100};
  text-decoration: none;
  overflow-wrap: break-word;
  word-wrap: break-word;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const CookieButtons = styled.div`
  display: flex;
  margin-top: 24px;
  @media ${media.tabletAndHigher} {
    margin-bottom: 40px;
    float: right;
  }
`

export interface ButtonProps {
  primary?: boolean
}

export const Button = styled(Typography.Button1)<ButtonProps>`
  width: 50%;

  &:first-child {
    margin-right: 12px;
  }

  @media ${media.tabletAndHigher} {
    width: 149px;
    &:first-child {
      margin-right: 24px;
    }
  }
`

export const ChooseButton = styled(Typography.Button1Outline)`
  width: 50%;

  @media ${media.tabletAndHigher} {
    width: 149px;
  }
`
