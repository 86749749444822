import React, { FC } from 'react'
import {
  TandCsAccordionContainer,
  TandCsAccordionBody,
  TandCsAccordionHeader,
  TandCsAccordionItem,
  TandCsAccordionHeaderSection,
  TandCsAccordionHeaderTitle,
  TandCsAccordionHeaderTextContainer,
  TandCsAccordionHeaderButton,
  BeveledDiv,
  TandCsAccordionRow,
  TandCsAccordionCol,
  TandCsAccordionHeaderBevelShadow,
  TandCsAccordionBodyContainer,
  TandCsAccordionHeaderBevelShadowOpen,
} from './TandCsAccordian.style'

interface TC {
  section: string
  title: string
  active: string[]
  onClick: (item: string) => void
  onClickRemove: (item: string) => void
  children?: any
}

export const TandCsAccordion: FC<TC> = (props) => {
  const handleAccordionHeaderButton = () => {
    if (props.active.indexOf(String(props.section)) > 0) {
      props.onClickRemove(String(props.section))
    } else {
      props.onClick(String(props.section))
    }
  }
  return (
    <TandCsAccordionContainer flush id={props.section} activeKey={props.active} alwaysOpen={true}>
      <TandCsAccordionItem eventKey={String(props.section)}>
        <TandCsAccordionHeader>
          <TandCsAccordionHeaderBevelShadow>
            <BeveledDiv cornerAngleRadians={[0, 0, 45, 45].map((a) => (a * Math.PI) / 180)}>
              <TandCsAccordionRow>
                <TandCsAccordionCol xs={10}>
                  <TandCsAccordionHeaderTextContainer>
                    <TandCsAccordionHeaderSection as="div">{props.section}</TandCsAccordionHeaderSection>
                    <TandCsAccordionHeaderTitle as="h3">{props.title}</TandCsAccordionHeaderTitle>
                  </TandCsAccordionHeaderTextContainer>
                </TandCsAccordionCol>
                <TandCsAccordionCol xs={2}>
                  <TandCsAccordionHeaderButton onClick={handleAccordionHeaderButton} aria-label="toggle accordion item" />
                </TandCsAccordionCol>
              </TandCsAccordionRow>
            </BeveledDiv>
          </TandCsAccordionHeaderBevelShadow>
        </TandCsAccordionHeader>
        <TandCsAccordionBody>
          <TandCsAccordionHeaderBevelShadowOpen>
            <BeveledDiv cornerAngleRadians={[45, 45, 0, 0].map((a) => (a * Math.PI) / 180)}>
              <TandCsAccordionBodyContainer>{props.children && props.children}</TandCsAccordionBodyContainer>
            </BeveledDiv>
          </TandCsAccordionHeaderBevelShadowOpen>
        </TandCsAccordionBody>
      </TandCsAccordionItem>
    </TandCsAccordionContainer>
  )
}
