import styled from 'styled-components'
import { colors, media, Typography } from '../../../style'
import { Accordion as AccordionBS } from 'react-bootstrap'
import chevron from '../../../img/chevron-down-blue.svg'

export const Section = styled.section`
  background: ${colors.PRIMARY_RED_10};
  padding: 36px 0 0;

  @media ${media.desktopAndHigher} {
    padding: 80px 0 0;
  }
`

export const Title = styled(Typography.H2Red)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    text-align: center;
  }
`

export const SubTitle = styled(Typography.Caption)`
  margin-bottom: 20px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 48px;
    text-align: center;
  }
`

export const Accordion = styled(AccordionBS)`
  margin-bottom: 36px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 72px;
  }
`

export const AccordionItem = styled(Accordion.Item)`
  background: transparent;
`

export const AccordionButton = styled(Accordion.Button)`
  background: transparent;

  @media ${media.belowDesktop} {
    padding-left: 0;
    padding-right: 0;
  }

  :after {
    background-image: url(${chevron});
    background-size: 32px;
    height: 32px;
    width: 32px;
  }

  :focus {
    box-shadow: none;
  }

  :not(.collapsed) {
    background-color: transparent;
  }

  :not(.collapsed)::after {
    background-image: url(${chevron});
  }
`

export const AccordionBody = styled(Accordion.Body)`
  @media ${media.belowDesktop} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const FooterText = styled(Typography.BodyBold)`
  margin-bottom: 36px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 44px;
    text-align: center;
  }
`
