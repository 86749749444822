import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import {
  StyledSection,
  StyledH1Bold,
  StyledH1,
  VTTLogo,
  VTTLogoPlaceholder,
  StyledSurferImg,
  StyledStoreImage,
  ButtonContainer,
} from './HeaderSection.styles'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'
import surfer from '../../img/illustration-surfer-commuter.svg'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import { ANDROID_STORE_URL, IOS_STORE_URL } from '../../config'
import { ScrollAnimationContainer } from '../ScrollAnimationContainer'

export const HeaderSection = () => {
  return (
    <StyledSection>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center justify-content-lg-start">
            <VTTLogo className="d-lg-none" src={vttLogoDiagonal} alt="VTT Logo" width='150' height='168' />
            <VTTLogoPlaceholder className="d-none d-lg-block" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6}>
            <StyledH1>Save money on your train travel and get rewarded too.</StyledH1>
            <StyledH1Bold>Book with us.</StyledH1Bold>
            <ButtonContainer>
              <a aria-label='Download on the App Store' href={IOS_STORE_URL} target="_blank" rel="noopener noreferrer">
                <StyledStoreImage className="d-none d-lg-inline" src={appStoreButton} alt="Download on the App Store" />
              </a>
              <a aria-label='Get it on Google Play' href={ANDROID_STORE_URL} target="_blank" rel="noopener noreferrer">
                <StyledStoreImage className="d-none d-lg-inline" src={googlePlayButton} alt="Get it on Google Play" />
              </a>
            </ButtonContainer>
          </Col>
          <Col lg={4} className="offset-lg-2 d-flex justify-content-center">
            <ScrollAnimationContainer animationName="fadeInRight">
              <StyledSurferImg src={surfer} alt="" width={271} height={250}></StyledSurferImg>
            </ScrollAnimationContainer>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}
