import React from 'react'
import { ArrowImg, DividerContainer, DividerLine } from './Divider.styles'
import arrowRight from '../../img/nifty-little-arrow-pointing-left.svg'

type Props = {
  top?: number
}

export const Divider = ({ top }: Props) => {
  return (
    <DividerContainer top={top} aria-hidden>
      <DividerLine />
      <ArrowImg src={arrowRight} />
    </DividerContainer>
  )
}
