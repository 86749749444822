import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const StyledSection = styled.section`
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0 0 0;
  border-style: solid;
`

export const HeaderSection = styled.section`
  background: ${colors.PRIMARY_RED_100};
  padding-bottom: 48px;
`

export const HeaderTitle = styled(Typography.H1White)`
  margin-bottom: 16px;
`

export const HeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  > :not(:first-child) {
    margin-top: 29px;
  }

  @media ${media.desktopAndHigher} {
    flex-direction: row;

    > :not(:first-child) {
      margin-top: 0;
      margin-left: 29px;
    }
  }
`

export const ScrollContainer = styled.section``

export const Section1 = styled.section``

export const Section1Title = styled(Typography.H2Red)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 36px;
  }
`

export const Section1IntroBold = styled(Typography.IntroBold)`
  margin-top: 36px;
`

export const Section1Intro = styled(Typography.Intro)`
  margin-top: 16px;
  margin-bottom: 36px;
`

export const Section1Images = styled.div`
  display: flex;
  justify-content: center;

  @media ${media.desktopAndHigher} {
    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: -32px;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

export const Section2 = styled(StyledSection)`
  background: ${colors.COLOUR_WARM_WHITE_100};
  padding: 36px 0;

  @media ${media.desktopAndHigher} {
    padding: 64px 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

export const Section2Title = styled(Typography.H2)`
  margin-bottom: 48px;
`

export const Section2ItemTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 12px;

  img {
    margin-right: 12px;
  }

  * {
    line-height: 1em;
    margin-bottom: 0;
  }
`

export const Section2Body = styled(Typography.Body)`
  margin-top: 36px;

  @media ${media.desktopAndHigher} {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`

export const Section3 = styled(StyledSection)`
  padding: 36px 0;

  @media ${media.desktopAndHigher} {
    padding: 64px 0 50px 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`

export const Section3Title = styled(Typography.H2Red)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 48px;
  }
`

export const Section3Item = styled.div`
  margin-bottom: 28px;

  > :first-child {
    margin-right: 18px;
    width: 23px;
  }

  > :last-child {
    flex: 1;
  }

  img {
    vertical-align: baseline;
  }

  @media ${media.desktopAndHigher} {
    display: flex;
  }
`

export const Section4 = styled(StyledSection)`
  background: ${colors.PRIMARY_NAVY_100};
  padding: 36px 0;

  @media ${media.desktopAndHigher} {
    padding: 28px 0 18px 0;
  }

  img {
    align-self: center;
    max-width: 100%;

    @media ${media.desktopAndHigher} {
      width: unset;
    }
  }
`

export const Section4Title = styled(Typography.H2White)`
  margin-top: 8px;
  margin-bottom: 28px;

  @media ${media.desktopAndHigher} {
    margin-top: 40px;
  }
`

export const Section4Image = styled.img`
  margin-left: 48px;
`

export const Section4Button = styled(Typography.Button1)`
  margin-top: 36px;

  @media ${media.desktopAndHigher} {
    margin-top: 40px;
    width: 267px;
  }
`
