import styled from 'styled-components'
import { media, Typography } from '../../../style'

export const Section = styled.section`
  padding-top: 36px;
  padding-bottom: 48px;

  @media ${media.desktopAndHigher} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

export const Title = styled(Typography.H1)`
  margin-bottom: 28px;
`

export const Subtitle = styled(Typography.IntroBold)`
  margin-bottom: 16px;
`

export const Body = styled(Typography.Intro)`
  margin-bottom: 32px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 52px;
  }
`

export const Images = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${media.desktopAndHigher} {
    align-items: flex-start;
    flex-direction: row;
  }
`

export const RailcardImage = styled.img`
  margin-bottom: 8px;
  max-width: 75%;
`

export const AccreditedImage = styled.img`
  margin-bottom: 32px;
  max-width: 40%;
  vertical-align: top;

  @media ${media.desktopAndHigher} {
    margin-left: -20px;
  }
`

export const DesktopCTA = styled(Typography.ButtonLink)`
  width: 270px;

  @media ${media.belowDesktop} {
    display: none;
  }
`

export const MobileCTA = styled(Typography.ButtonLink)`
  width: 325px;

  @media ${media.desktopAndHigher} {
    display: none;
  }
`
