import React, { useEffect } from 'react'
import { LandingPage } from './pages/LandingPage'
import { CookiePolicyProvider } from './context'
import { CookieConsent } from './components/CookieConsent'
import { CookieModal } from './components/CookieModal'
import { setConsentSettings } from './util/helpers/cookiePreferences'
import { useCookiePolicy } from './context/CookiePolicyProvider'
import { NoScrollContainer } from './App.styles'

const AppInside = () => {
  const { isCookieBannerVisible } = useCookiePolicy()
  return (
    <>
      <NoScrollContainer className={isCookieBannerVisible ? 'noScroll' : ''}>
        <LandingPage></LandingPage>
      </NoScrollContainer>
      <div className="cookie-banner">
        <CookieConsent />
      </div>
      <CookieModal />
    </>
  )
}

function App() {
  useEffect(() => {
    setConsentSettings()
  }, [])
  return (
    <CookiePolicyProvider>
      <AppInside />
    </CookiePolicyProvider>
  )
}

export default App
