import React from 'react'
import { ScrollableBar } from '../../components/ScrollableBar/ScrollableBar'
import { Col, Container, Row } from 'react-bootstrap'
import { Typography } from '../../style'
import {
  HeaderButtonContainer,
  HeaderSection,
  HeaderTitle,
  ScrollContainer,
  Section1,
  Section1Images,
  Section1Intro,
  Section1IntroBold,
  Section1Title,
} from './Redemption.styles'
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb'
import { formatImageSet } from '../../util/helpers/image'
import { FooterSection } from '../../components/FooterSection'
import { StyledStoreImage, VTTLogo, VTTLogoPlaceholder } from '../../components/HeaderSection/HeaderSection.styles'
import { REDEMPTION_LINK } from '../../config'
import { StickyHeader } from '../../components/StickyHeader'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'
import skater from '../../img/illustration-skater.png'
import skater2x from '../../img/illustration-skater@2x.png'
import skater3x from '../../img/illustration-skater@3x.png'
import howToUseStep1 from '../../img/how-to-use-step-1.png'
import howToUseStep12x from '../../img/how-to-use-step-1@2x.png'
import howToUseStep13x from '../../img/how-to-use-step-1@3x.png'
import howToUseStep2 from '../../img/how-to-use-step-2.png'
import howToUseStep22x from '../../img/how-to-use-step-2@2x.png'
import howToUseStep23x from '../../img/how-to-use-step-2@3x.png'
import howToUseStep3 from '../../img/how-to-use-step-3.png'
import howToUseStep32x from '../../img/how-to-use-step-3@2x.png'
import howToUseStep33x from '../../img/how-to-use-step-3@3x.png'
import howToUseStep4 from '../../img/how-to-use-step-4.png'
import howToUseStep42x from '../../img/how-to-use-step-4@2x.png'
import howToUseStep43x from '../../img/how-to-use-step-4@3x.png'
import { ScrollImage, ScrollItem } from '../../components/ScrollableBar/ScrollableBar.styles'
import iconBrandTicket from '../../img/icon-brand-ticket.svg'
import threePointsPer from '../../img/graphic-3-points-per.svg'
import iconBrandVttVrPoints from '../../img/icon-brand-vrvtt-points.svg'
import { AppLinksSection } from '../../components/AppLinksSection/AppLinksSection'
import { PATHS } from '../../util/helpers/paths'
import { MetaTags } from '../../components/MetaTags'
import { EarnSection } from '../../components/EarnSection'
import bookTicketTarrow from '../../img/redemption-tarrow-graphics-01-book.png'
import bookTicketTarrow2x from '../../img/redemption-tarrow-graphics-01-book@2x.png'
import bookTicketTarrow3x from '../../img/redemption-tarrow-graphics-01-book@3x.png'
import earnPointsTarrow from '../../img/redemption-tarrow-graphics-02-earn.png'
import earnPointsTarrow2x from '../../img/redemption-tarrow-graphics-02-earn@2x.png'
import earnPointsTarrow3x from '../../img/redemption-tarrow-graphics-02-earn@3x.png'
import spendPointsTarrow from '../../img/redemption-tarrow-graphics-03-spend.png'
import spendPointsTarrow2x from '../../img/redemption-tarrow-graphics-03-spend@2x.png'
import spendPointsTarrow3x from '../../img/redemption-tarrow-graphics-03-spend@3x.png'
import { FourSteps } from '../../components/FourSteps/FourSteps'
import { VirginRedHero } from '../../components/VirginRedHero/VirginRedHero'

const breadcrumbItems = [
  { text: 'Find cheap train tickets', url: PATHS.CHEAP_TICKETS },
  { text: 'Cut the cost of train travel with Virgin Points', url: '' },
]

export const Redemption = () => (
  <>
    <MetaTags
      title="Virgin Trains Ticketing | Cheaper trains with Virgin Points"
      description="Turn Virgin Points into epic savings on all train tickets across Britain ✓ Claim 3 Virgin Points for every £1 you spend ✓ Unlock discounts from only 200 points!"
    />
    <StickyHeader
      breadcrumb={breadcrumbItems}
      desktopText={
        <>
          The more you travel, the more you earn, the more you save.
          <br />
          Download the app today!
        </>
      }
      isLogoClickable
      storeUrl={REDEMPTION_LINK}
    />
    <HeaderSection>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center justify-content-lg-start">
            <a aria-label="VTT Logo" href="/">
              <VTTLogo className="d-lg-none" src={vttLogoDiagonal} alt="VTT Logo" />
            </a>
            <VTTLogoPlaceholder className="d-none d-lg-block" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6}>
            <HeaderTitle>Cut the cost of train travel with Virgin Points</HeaderTitle>
            <Typography.IntroBoldWhite>
              The more you travel, the more you earn, the more you save. Download the app and book today!
            </Typography.IntroBoldWhite>
          </Col>
          <Col lg={{ span: 4, offset: 2 }} className="d-none d-lg-flex justify-content-center">
            <HeaderButtonContainer>
              <a aria-label="Download on the App Store" href={REDEMPTION_LINK} target="_blank" rel="noopener noreferrer">
                <StyledStoreImage src={appStoreButton} alt="Download on the App Store" />
              </a>
              <a aria-label="Get it on Google Play" href={REDEMPTION_LINK} target="_blank" rel="noopener noreferrer">
                <StyledStoreImage src={googlePlayButton} alt="Get it on Google Play" />
              </a>
            </HeaderButtonContainer>
          </Col>
        </Row>
      </Container>
    </HeaderSection>
    <ScrollContainer>
      <ScrollableBar>
        <ScrollItem>
          <ScrollImage src={iconBrandTicket} alt="" />
          <Typography.BodyBold>Book tickets for any train across Great Britain</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={threePointsPer} alt="" />
          <Typography.BodyBold>Earn Virgin Points with every £1 you spend</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={iconBrandVttVrPoints} alt="" />
          <Typography.BodyBold>Use your points to unlock discounts on future train travel</Typography.BodyBold>
        </ScrollItem>
      </ScrollableBar>
    </ScrollContainer>
    <Section1>
      <Container>
        <Row>
          <Col>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="d-none d-lg-block">
            <Section1Images>
              <img
                srcSet={formatImageSet([skater, skater2x, skater3x])}
                width="510"
                height="344"
                alt="App screenshot showing split ticket saving and discounting cost with Red points"
              />
            </Section1Images>
          </Col>
          <Col lg={6}>
            <Section1Title>Turn Virgin Points into epic savings on train tickets</Section1Title>
            <Section1Images className="d-lg-none">
              <img
                srcSet={formatImageSet([skater, skater2x, skater3x])}
                width="510"
                height="344"
                alt="App screenshot showing split ticket saving and discounting cost with Red points"
              />
            </Section1Images>
            <Section1IntroBold>Start saving on your train travel and earn rewards on top.</Section1IntroBold>
            <Section1Intro>
              No kidding. When you book through Virgin Trains Ticketing, the Virgin Points you earn pay off in a big way — like money off
              your next train journey.
            </Section1Intro>
          </Col>
        </Row>
      </Container>
    </Section1>
    <EarnSection
      lightMode
      imageSet1={[bookTicketTarrow, bookTicketTarrow2x, bookTicketTarrow3x]}
      imageSet2={[earnPointsTarrow, earnPointsTarrow2x, earnPointsTarrow3x]}
      imageSet3={[spendPointsTarrow, spendPointsTarrow2x, spendPointsTarrow3x]}
    />
    <FourSteps
      title="How to use Virgin Points to book train tickets in our app"
      text1="Get the Virgin Trains Ticketing app, login or sign up to Virgin Red, and search for your ticket."
      imageSet1={[howToUseStep1, howToUseStep12x, howToUseStep13x]}
      text2="Select your ticket — we’ll always point out the cheapest one."
      imageSet2={[howToUseStep2, howToUseStep22x, howToUseStep23x]}
      text3="If you have 200 points or more, you can choose to use them to cut the cost of your ticket. Simply choose how much you want to save."
      imageSet3={[howToUseStep3, howToUseStep32x, howToUseStep33x]}
      text4="Nice one! You’ve cut the cost of your journey using Virgin Points. And, you’ve also earned points on any cash payment part."
      imageSet4={[howToUseStep4, howToUseStep42x, howToUseStep43x]}
    />
    <VirginRedHero
      title="Unlock savings on train tickets even quicker with Virgin Red"
      body={
        <>
          <p>Looking for a catch? We can’t give you one. But we can pile on the points.</p>
          <p>Earn Virgin Points when you buy flights, holidays, goodies from your favourite brands and more with Virgin Red.</p>
        </>
      }
      buttonTitle="Join Virgin Red"
    />
    {REDEMPTION_LINK && <AppLinksSection url={REDEMPTION_LINK} />}
    <FooterSection />
  </>
)
