import { PURCHASE_RAILCARD_LINK } from '../../config'
import { getFRTParameters } from '../helpers/getFRTParameters'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'

const generatePurchaseRailcardURL = (searchParams: URLSearchParams, defaultCampaignId?:string, defaultSourceId?:string, defaultMediumId?:string) => {
  const baseURL = PURCHASE_RAILCARD_LINK
  const queryParameters = getFRTParameters(defaultCampaignId, defaultSourceId, defaultMediumId, searchParams)
  const queryString = queryParameters ? `?${encodeURI(queryParameters)}` : ''

  return `${baseURL}${queryString}`
}

export const usePurchaseURLGenerator = () => {
  let location = useLocation()
  let search = location.search

  return useCallback((defaultCampaignId?: string, defaultSourceId?: string, defaultMediumId?: string) => {
    const searchParams = new URLSearchParams(search)
    return generatePurchaseRailcardURL(searchParams, defaultCampaignId, defaultSourceId, defaultMediumId)
  }, [search])
}

export const exportedForTesting = {
  generatePurchaseRailcardURL,
}
