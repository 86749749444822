import React from 'react'
import { ScrollableBar } from '../../components/ScrollableBar/ScrollableBar'
import { Col, Container, Row } from 'react-bootstrap'
import { Typography } from '../../style'
import {
  HeaderButtonContainer,
  HeaderImg,
  HeaderSection,
  HeaderTitle,
  ScrollContainer,
  Section1,
  Section1Images,
  Section1Intro,
  Section1IntroBold,
  Section1Title,
  Section2,
  Section2Body,
  Section2ItemTitle,
  Section2Title,
  Section2TopCol,
} from './CheapTickets.styles'
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb'
import { formatImageSet } from '../../util/helpers/image'
import { FooterSection } from '../../components/FooterSection'
import { StyledStoreImage, VTTLogo, VTTLogoPlaceholder } from '../../components/HeaderSection/HeaderSection.styles'
import { CHEAP_TICKETS_LINK } from '../../config'
import { ScrollAnimationContainer } from '../../components/ScrollAnimationContainer'
import { StickyHeader } from '../../components/StickyHeader'
import appStoreButton from '../../img/button-app-store.svg'
import googlePlayButton from '../../img/button-google-play.svg'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'
import vttIntroGraphic from '../../img/vtt-intro-graphic.png'
import vttIntroGraphic2x from '../../img/vtt-intro-graphic@2x.png'
import vttIntroGraphic3x from '../../img/vtt-intro-graphic@3x.png'
import iconSplitTicketing from '../../img/icon-split-ticketing-vtt-st-identity.svg'
import cutTheCost from '../../img/cut-the-cost.svg'
import shopper from '../../img/masthead-illustration-shopper.svg'
import { ScrollImage, ScrollItem } from '../../components/ScrollableBar/ScrollableBar.styles'
import iconBrandTicket from '../../img/icon-brand-ticket.svg'
import threePointsPer from '../../img/graphic-3-points-per.svg'
import iconBrandVttVrPoints from '../../img/icon-brand-vrvtt-points.svg'
import { MetaTags } from '../../components/MetaTags'
import { AppLinksSection } from '../../components/AppLinksSection/AppLinksSection'
import { isAndroid, isIOS } from '../../util/helpers/os'
import { PATHS } from '../../util/helpers/paths'
import { DiscoverMoreWays } from '../../components/DiscoverMoreWays/DiscoverMoreWays'
import { bookTicketsInAdvance, groupSave, travelOffPeak, useARailcard } from '../../components/DiscoverMoreWays/constants'

const breadcrumbItems = [{ text: 'Find cheap train tickets', url: '' }]

export const CheapTickets = () => (
  <>
    <MetaTags
      title="Virgin Trains Ticketing | how to get cheap train tickets"
      description="Buy cheap train tickets for any train across Great Britain – No booking fees ✓ Save with a Railcard ✓ Split tickets ✓ Get discount tickets with Virgin Points"
    />
    <StickyHeader
      breadcrumb={breadcrumbItems}
      desktopText={
        <>
          Save wherever you go with Virgin Trains Ticketing
          <br />
          Download the app today!
        </>
      }
      isLogoClickable
      storeUrl={CHEAP_TICKETS_LINK}
    />
    <HeaderSection>
      <Container>
        <Row>
          <Col className="d-flex justify-content-center justify-content-lg-start">
            <a aria-label="VTT Logo" href="/">
              <VTTLogo className="d-lg-none" src={vttLogoDiagonal} alt="VTT Logo" />
            </a>
            <VTTLogoPlaceholder className="d-none d-lg-block" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={6}>
            <HeaderTitle>How to find cheap train tickets</HeaderTitle>
            <Typography.IntroBoldWhite>
              Unlock ways to save money and find cheap train tickets with Virgin Trains Ticketing.
              <br />
              Download the app and book today!
            </Typography.IntroBoldWhite>
            <HeaderButtonContainer>
              {!isAndroid() && (
                <a aria-label="Download on the App Store" href={CHEAP_TICKETS_LINK} target="_blank" rel="noopener noreferrer">
                  <StyledStoreImage src={appStoreButton} alt="Download on the App Store" />
                </a>
              )}
              {!isIOS() && (
                <a aria-label="Get it on Google Play" href={CHEAP_TICKETS_LINK} target="_blank" rel="noopener noreferrer">
                  <StyledStoreImage src={googlePlayButton} alt="Get it on Google Play" />
                </a>
              )}
            </HeaderButtonContainer>
          </Col>
          <Col lg={{ span: 4, offset: 2 }} className="d-none d-lg-flex justify-content-center">
            <ScrollAnimationContainer animationName="fadeInRight">
              <HeaderImg src={shopper} alt="" />
            </ScrollAnimationContainer>
          </Col>
        </Row>
      </Container>
    </HeaderSection>
    <ScrollContainer>
      <ScrollableBar>
        <ScrollItem>
          <ScrollImage src={iconBrandTicket} alt="" />
          <Typography.BodyBold>Book tickets for any train across Great Britain</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={threePointsPer} alt="" />
          <Typography.BodyBold>Earn Virgin Points with every £1 you spend</Typography.BodyBold>
        </ScrollItem>
        <ScrollItem>
          <ScrollImage src={iconBrandVttVrPoints} alt="" />
          <Typography.BodyBold>Use your points to unlock discounts on future train travel</Typography.BodyBold>
        </ScrollItem>
      </ScrollableBar>
    </ScrollContainer>
    <Section1>
      <Container>
        <Row>
          <Col>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Section1Title>The savvy traveller's guide to finding cheap and discount train tickets</Section1Title>
            <Section1Images className="d-lg-none">
              <img
                srcSet={formatImageSet([vttIntroGraphic, vttIntroGraphic2x, vttIntroGraphic3x])}
                width="510"
                height="574"
                alt="App screenshot showing split ticket saving and discounting cost with Virgin points"
              />
            </Section1Images>
            <Section1IntroBold>
              With all the different ticket types, rail operators and ways to buy train tickets, it’s hard to know if you’re getting the
              best deal.
            </Section1IntroBold>
            <Section1Intro>
              <p>
                Whether it's cheap trains to London, Edinburgh, Manchester or elsewhere, we'll show you how to save on train tickets for any
                train across Great Britain.
              </p>
              <p>Yep, any train, any line, any train operator.</p>
            </Section1Intro>
          </Col>
          <Col lg={6} className="d-none d-lg-block">
            <Section1Images>
              <img
                srcSet={formatImageSet([vttIntroGraphic, vttIntroGraphic2x, vttIntroGraphic3x])}
                width="510"
                height="574"
                alt="App screenshot showing split ticket saving and discounting cost with Red points"
              />
            </Section1Images>
          </Col>
        </Row>
      </Container>
    </Section1>
    <Section2>
      <Container>
        <Row>
          <Col lg={{ span: 6 }}>
            <Section2Title>Start saving on your train tickets with Virgin Trains Ticketing</Section2Title>
          </Col>
        </Row>
        <Row>
          <Section2TopCol lg={{ span: 5 }}>
            <Section2ItemTitle>
              <img src={iconSplitTicketing} alt="Split Tickets" />
            </Section2ItemTitle>
            <Section2Body>
              Save on journeys across Great Britain with our nifty split tickets. They save you money by splitting one train journey into
              multiple tickets, so you get the same journey, for less.
            </Section2Body>
            <Typography.LinkStyle to={PATHS.SPLIT_TICKETS}>Learn how to save with split tickets</Typography.LinkStyle>
          </Section2TopCol>
          <Col lg={{ span: 5, offset: 1 }}>
            <Section2ItemTitle>
              <img src={cutTheCost} alt="Cut the cost" />
              <Typography.H3>Cut the cost of travel with Virgin Points</Typography.H3>
            </Section2ItemTitle>
            <Section2Body>
              Use your points to get part or even fully discounted tickets on any journey! We're not kidding. Start saving with as little as
              200 points and you'll earn even more points when you book. The more you travel, the more you earn, the more you save
            </Section2Body>
            <Typography.LinkStyle aria-label="Learn how to save by using Virgin Points" to={PATHS.REDEMPTION}>
              Learn how to save by using Virgin Points
            </Typography.LinkStyle>
          </Col>
        </Row>
      </Container>
    </Section2>
    <DiscoverMoreWays items={[bookTicketsInAdvance, useARailcard, travelOffPeak, groupSave]} />
    {CHEAP_TICKETS_LINK && <AppLinksSection url={CHEAP_TICKETS_LINK} />}
    <FooterSection />
  </>
)
