import styled from 'styled-components'
import { media } from '../../style'

export const OuterContainer = styled.div`
  position: relative;
`

export const QRContainer = styled.div`
  display: none;
  position: absolute;
  top: 100px;
  left: 76px;

  background: #ffffff;
  padding: 8px;

  @media ${media.desktopAndHigher} {
    display: block;
  }
`

export const LogoContainer = styled.div`
  position: absolute;
  top: 90px;
  left: 75px;

  @media ${media.desktopAndHigher} {
    display: none;
  }
`
