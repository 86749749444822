export const PATHS = {
  VIRGIN: 'https://www.virgin.com/',
  VIRGIN_RED: 'https://www.virgin.com/virgin-red',
  COOKIES: '/cookie-policy.pdf',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  PRIVACY: 'https://www.virgin.com/virgin-red/privacy-policy',
  MODERN_SLAVERY_STATEMENT: 'https://www.virgin.com/modern-slavery-act',
  SUPPORT: 'https://trains.virgin.com/',
  FAQ: 'https://trains.virgin.com/hc/en-gb/articles/360018289878-FAQs',
  CHEAP_TICKETS: '/cheap-train-tickets',
  SPLIT_TICKETS: '/cheap-train-tickets/split-tickets',
  REDEMPTION: '/cheap-train-tickets/cut-the-cost-of-train-travel-with-virgin-points',
  TRAINS_REFUND_URL: 'https://virgintrains-refunds.fastrailticketing.com',
  RAILCARDS: '/railcards',
}

export const openPath = (pathLink?: string) => {
  gtag('event', 'click', {
    link_url: pathLink,
  })
  window.open(pathLink)
}
