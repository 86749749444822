import { colors } from '../../../../style'
import styled from 'styled-components'

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.TERTIARY_CHARCOAL_100};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 19px;
    width: 19px;
    left: 0px;
    bottom: -3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid black;
    border-radius: 50%;
  }
`

interface InputProps {
  isToggleable?: boolean
}

export const Input = styled.input.attrs({ type: 'checkbox' })<InputProps>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${(props) => (props.isToggleable ? colors.PRIMARY_RED_100 : colors.PRIMARY_RED_A60)};
  }

  &:focus + ${Slider} {
    box-shadow: 0 0 1px ${colors.PRIMARY_RED_100};
  }

  &:checked + ${Slider}:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
`
