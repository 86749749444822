import styled from 'styled-components'
import { media, Typography } from '../../style'
import { StyledSection } from '../../pages/CheapTickets/CheapTickets.styles'

export const Section = styled(StyledSection)`
  padding: 36px 0 4px 0;

  @media ${media.desktopAndHigher} {
    padding: 64px 0 28px 0;
  }

  img {
    max-width: 100%;
  }
`

export const Title = styled(Typography.H2)`
  margin-bottom: 24px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 48px;
  }
`

export const ItemTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  * {
    margin-bottom: 0;
  }

  img {
    margin-right: 16px;
  }
`

export const Body = styled(Typography.Paragraph)`
  margin-bottom: 32px;

  @media ${media.desktopAndHigher} {
    margin-bottom: 36px;
  }
`

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  order: 3 !important;
`
