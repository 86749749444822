import React, { FC } from 'react'
import { Row } from 'react-bootstrap'
import {
  CookieButtons,
  CookieConsentContainer,
  CookieConsentModal,
  ModalContentWrapper,
  Subheading,
  Spacer,
  Copy,
  StyledCopyAnchor,
  Button,
  ChooseButton,
} from './CookieConsent.styles'
import { useCookiePolicy } from '../../context/CookiePolicyProvider'
import { setAllCookiePreferences } from '../../util/helpers/cookiePreferences'
import { openPath, PATHS } from '../../util/helpers/paths'

const CookieConsent: FC = () => {
  const { isCookieBannerVisible, showCookiePolicyModal, hideCookiePolicyBanner } = useCookiePolicy()
  if (!isCookieBannerVisible) return null
  return (
    <>
      <CookieConsentContainer>
        <CookieConsentModal>
          <ModalContentWrapper>
            <Subheading>We use cookies to improve your experience on our website.</Subheading>
            <Spacer />
            <Row className="d-md-none">
              <Copy>
                You can read our full <StyledCopyAnchor onClick={() => openPath(PATHS.PRIVACY)}>privacy policy</StyledCopyAnchor> and{' '}
                <StyledCopyAnchor onClick={() => openPath(PATHS.COOKIES)}>cookie policy</StyledCopyAnchor> which explains how we use
                cookies.
              </Copy>
            </Row>
            <Row className="d-none d-md-block">
              <Copy>
                We use cookies (yum!) and other technology to track how you interact with us, our partners and to understand you. They
                enable us to personalise your experience and provide you with more relevant content based on things you’ve shown an interest
                in or we think you might like. They also help us keep this site running smoothly. If you fancy a cookie then ‘accept all’
                and continue. However if you’re watching your waistline then adjust your settings below. You can read our full{' '}
                <StyledCopyAnchor onClick={() => openPath(PATHS.PRIVACY)}>privacy policy</StyledCopyAnchor> and{' '}
                <StyledCopyAnchor onClick={() => openPath(PATHS.COOKIES)}>cookie policy</StyledCopyAnchor> which explains how we use
                cookies.
              </Copy>
            </Row>
            <CookieButtons>
              <Button
                primary
                onClick={(): void => {
                  hideCookiePolicyBanner()
                  setAllCookiePreferences()
                }}>
                Accept all
              </Button>
              <ChooseButton onClick={showCookiePolicyModal}>Let me choose</ChooseButton>
            </CookieButtons>
          </ModalContentWrapper>
        </CookieConsentModal>
      </CookieConsentContainer>
    </>
  )
}

export { CookieConsent }
