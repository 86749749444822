import { CookiePreferenceId } from '../../util/helpers/cookiePreferences'

export interface CookiePreferenceData {
  title: string
  content: string
}

export const necessaryCookiesData: CookiePreferenceData = {
  title: 'Strictly necessary cookies',
  content: 'We need to use cookies to enable core site functionality (like saving these preferences)—sorry you can’t switch this one off',
}

export const cookieDataMap: {
  [K in CookiePreferenceId]: CookiePreferenceData
} = {
  [CookiePreferenceId.Analytics]: {
    title: 'Visit tracking',
    content:
      'We’ll give you a personalised experience of our website across multiple visits. This also helps us understand whether you like our website enough to return.',
  },
}
