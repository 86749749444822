import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import vrMobileBackground from '../../img/virgin-red-section-mobile-background.svg'

export const StyledSection = styled.section`
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0px 0px 0px;
  border-style: solid;
  background-color: ${colors.COLOR_LIGHT_PEACH};
  padding-bottom: 48px;

  background-image: url(${vrMobileBackground});
  background-repeat: no-repeat;
  background-position: center bottom;
  @media ${media.desktopAndHigher} {
    background-position: center 112%;
    padding-bottom: 95px;
  }
`

export const VRLogo = styled.img`
  margin-top: 30px;

  @media ${media.desktopAndHigher} {
    width: 110px;
    height: 110px;

    margin-top: 64px;
  }
`

export const Title = styled(Typography.H1Red)`
  margin-top: 20px;

  @media ${media.desktopAndHigher} {
    margin-top: 64px;
  }
`

export const StyledIntroBold = styled(Typography.IntroBold)`
  margin-top: 24px;

  @media ${media.desktopAndHigher} {
    margin-top: 28px;
  }
`

export const StyledIntro = styled(Typography.Intro)`
  margin-top: 16px;

  @media ${media.desktopAndHigher} {
    margin-top: 28px;
  }
`

export const StyledFamilyImg = styled.img`
  margin-top: 16px;
  width: 100%;

  @media ${media.desktopAndHigher} {
    position: absolute;
    width: 540px;
    margin-top: 10px;
    padding-left: 22px;
  }
`

export const VRButton = styled(Typography.Button1)`
  margin-top: 30px;

  @media ${media.desktopAndHigher} {
    width: 269px;
    margin-top: 32px;
  }
`
