import { useEffect } from 'react'

interface MetaTagsProps {
  title: string
  description: string
}

export const MetaTags = ({ title, description }: MetaTagsProps) => {
  useEffect(() => {
    const findOrCreateElement = (tagName: string, attributes: { [key: string]: string } = {}) => {
      let attributesAsString = Object.keys(attributes)
        .map((key) => {
          return `[${key}="${attributes[key]}"]`
        })
        .join()
      const element = document.head.querySelector<HTMLElement>(`${tagName}${attributesAsString}`)
      if (element !== null) {
        return element
      }

      return document.head.appendChild(createElement(tagName, attributes))
    }

    const createElement = (tagName: string, attributes: { [key: string]: string } = {}) => {
      const element = document.createElement(tagName)
      Object.keys(attributes).forEach((key) => {
        element.setAttribute(key, attributes[key])
      })
      return element
    }

    const titleElement = findOrCreateElement('title')
    titleElement.innerText = title

    const ogTitleElement = findOrCreateElement('meta[property="og:title"]')
    ogTitleElement.setAttribute('content', title)

    const twitterTitleElement = findOrCreateElement('meta[name="twitter:title"]')
    twitterTitleElement.setAttribute('content', title)

    const descriptionElement = findOrCreateElement('meta[name="description"]')
    descriptionElement.setAttribute('content', description)

    const ogDescriptionElement = findOrCreateElement('meta[property="og:description"]')
    ogDescriptionElement.setAttribute('content', description)

    const twitterDescriptionElement = findOrCreateElement('meta[name="twitter:description"]')
    twitterDescriptionElement.setAttribute('content', description)
  }, [description, title])

  return null
}
