import styled from 'styled-components'
import { ListGroupItem, Row, Col } from 'react-bootstrap'
import { Typography, colors } from '../../style'

export const TOCListGroupItem = styled(ListGroupItem)`
  border: 0;
  border-left: 4px solid ${colors.PRIMARY_RED_100};
  border-radius: 0;
  background-color: transparent;

  :first-child {
    border-top-left-radius: 0;
    padding-top: 0 !important;
  }

  :last-child {
    border-bottom-left-radius: 0;
    border-left: 4px solid ${colors.PRIMARY_RED_100} !important;
    height: 25px;
  }

  &.list-item-anchor {
    text-decoration: none;
    color: ${colors.TERTIARY_CHARCOAL_140};
  }
`

export const TOCSectionItemText = styled(Typography.Body)`
  padding: 15px 0 0 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;

  :hover {
    text-decoration: underline;
    color: ${colors.PRIMARY_RED_100};
  }
`
export const TOCImg = styled.img`
  position: relative;
  left: 0;
  top: 50%;
  margin-top: -7px;

  &.lastItem {
    margin-left: -9px;
    margin-top: -14px;
  }
`

export const TOCRow = styled(Row)``

export const TOCCol = styled(Col)`
  vertical-align: middle;
`
