import { FooterSection } from '../../components/FooterSection'
import React from 'react'
import {
  CenteredCol,
  CTAButton,
  FlexCol,
  HeaderIllustration,
  HeaderImage,
  HeaderImageMobile,
  HeaderOfficialRetailerImage,
  HeaderPromoIllustration,
  HeaderSection,
  HeaderText,
  HeaderTitle,
  WhiteCTAButton,
} from './Railcards.styles'
import { Container, Row } from 'react-bootstrap'
import vttLogoHorizontalOnWhite from '../../img/vtt-logo-horizontal-white-gradient.png'
import vttLogoDiagonal from '../../img/vtt-logo-diagonal-white.svg'
import officialRetailer from '../../img/graphic-railcard-official-retailer-horizontal-reversed.svg'
import { WhyUs } from './WhyUsSection/WhyUs'
import { FindingTheRightCard } from './FindingTheRightCard/FindingTheRightCard'
import { FourSteps } from '../../components/FourSteps/FourSteps'
import howToUseStep1 from '../../img/railcards/how-to-use-step-1.png'
import howToUseStep12x from '../../img/railcards/how-to-use-step-1@2x.png'
import howToUseStep13x from '../../img/railcards/how-to-use-step-1@3x.png'
import howToUseStep1Mobile from '../../img/railcards/how-to-use-step-1-mobile.png'
import howToUseStep1Mobile2x from '../../img/railcards/how-to-use-step-1-mobile@2x.png'
import howToUseStep1Mobile3x from '../../img/railcards/how-to-use-step-1-mobile@3x.png'
import howToUseStep2 from '../../img/railcards/how-to-use-step-2.png'
import howToUseStep22x from '../../img/railcards/how-to-use-step-2@2x.png'
import howToUseStep23x from '../../img/railcards/how-to-use-step-2@3x.png'
import howToUseStep2Mobile from '../../img/railcards/how-to-use-step-2-mobile.png'
import howToUseStep2Mobile2x from '../../img/railcards/how-to-use-step-2-mobile@2x.png'
import howToUseStep2Mobile3x from '../../img/railcards/how-to-use-step-2-mobile@3x.png'
import howToUseStep3 from '../../img/railcards/how-to-use-step-3.png'
import howToUseStep32x from '../../img/railcards/how-to-use-step-3@2x.png'
import howToUseStep33x from '../../img/railcards/how-to-use-step-3@3x.png'
import howToUseStep3Mobile from '../../img/railcards/how-to-use-step-3-mobile.png'
import howToUseStep3Mobile2x from '../../img/railcards/how-to-use-step-3-mobile@2x.png'
import howToUseStep3Mobile3x from '../../img/railcards/how-to-use-step-3-mobile@3x.png'
import howToUseStep4 from '../../img/railcards/how-to-use-step-4.png'
import howToUseStep42x from '../../img/railcards/how-to-use-step-4@2x.png'
import howToUseStep43x from '../../img/railcards/how-to-use-step-4@3x.png'
import howToUseStep4Mobile from '../../img/railcards/how-to-use-step-4-mobile.png'
import howToUseStep4Mobile2x from '../../img/railcards/how-to-use-step-4-mobile@2x.png'
import howToUseStep4Mobile3x from '../../img/railcards/how-to-use-step-4-mobile@3x.png'
import knitting from '../../img/vtt-knitting-illustration-01.svg'
import { VirginRedHero } from '../../components/VirginRedHero/VirginRedHero'
import { FAQ } from './FAQ/FAQ'
import { bookTicketsInAdvance, groupSave, splitTickets, travelOffPeak } from '../../components/DiscoverMoreWays/constants'
import { DiscoverMoreWays } from '../../components/DiscoverMoreWays/DiscoverMoreWays'
import { FEATURE_FLAGS, RAILCARDS_LINK, RAILCARDS_OPEN_OR_DOWNLOAD_LINK } from '../../config'
import { AppLinksSection } from '../../components/AppLinksSection/AppLinksSection'
import { usePurchaseURLGenerator } from '../../util/hooks/usePurchaseURLGenerator'
import { Typography } from '../../style'
import { PromoBar } from '../../components/PromoBar/PromoBar'
import { formatImageSet } from '../../util/helpers/image'
import smartyPants from '../../img/railcards-promo/railcard-retailing-smarty-pants-desktop.png'
import smartyPants2x from '../../img/railcards-promo/railcard-retailing-smarty-pants-desktop@2x.png'
import smartyPants3x from '../../img/railcards-promo/railcard-retailing-smarty-pants-desktop@3x.png'

export const Railcards = () => {
  const generatePurchaseRailcardURL = usePurchaseURLGenerator()
  return (
    <>
      <HeaderSection>
        <Container>
          <Row>
            <FlexCol lg={6}>
              <a aria-label="VTT Logo" href="/">
                <HeaderImage className="d-none d-lg-block" src={vttLogoHorizontalOnWhite}
                             alt="Virgin Trains Ticketing logo" />
              </a>
              <div className="d-flex justify-content-center">
                <a aria-label="VTT Logo" href="/">
                  <HeaderImageMobile className="d-lg-none" src={vttLogoDiagonal} alt="VTT Logo" />
                </a>
              </div>
              <HeaderTitle>Save 1/3 on train travel with a Railcard</HeaderTitle>
              <HeaderText>
                Be a Railcard smarty pants and get cheaper train tickets on eligible journeys when you buy a digital
                Railcard with Virgin
                Trains Ticketing.
              </HeaderText>
              <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                <HeaderOfficialRetailerImage src={officialRetailer} />
                <WhiteCTAButton
                  href={generatePurchaseRailcardURL('VTTWebsite', 'VTTWebsite-HeaderSection', 'VTTWebsite')}>
                  Buy your Railcard now
                </WhiteCTAButton>
              </div>
            </FlexCol>
            <FlexCol>
              {FEATURE_FLAGS.railcardsPromo &&
                <HeaderPromoIllustration srcSet={formatImageSet([smartyPants, smartyPants2x, smartyPants3x])} alt="" />}
            </FlexCol>
          </Row>
        </Container>
        {!FEATURE_FLAGS.railcardsPromo && <HeaderIllustration src={knitting} alt="" />}
      </HeaderSection>
      {FEATURE_FLAGS.railcardsPromo && <PromoBar />}
      <WhyUs />
      <FindingTheRightCard />
      <FourSteps
        title="It's simple to buy a digital Railcard with us"
        text1="Tap the button below to buy your Railcard. You’ll then be walked through the step-by-step purchase journey."
        imageSet1={[howToUseStep1, howToUseStep12x, howToUseStep13x]}
        imageSet1Mobile={[howToUseStep1Mobile, howToUseStep1Mobile2x, howToUseStep1Mobile3x]}
        text2="Check your email — you’ll have been sent a digital Railcard download code which you’ll need to copy or note down."
        imageSet2={[howToUseStep2, howToUseStep22x, howToUseStep23x]}
        imageSet2Mobile={[howToUseStep2Mobile, howToUseStep2Mobile2x, howToUseStep2Mobile3x]}
        text3={
          <>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Typography.anchorStyle href={RAILCARDS_OPEN_OR_DOWNLOAD_LINK}>Open or download</Typography.anchorStyle> the
            Virgin Trains Ticketing app. Go to the
            Account tab &gt; Railcards, tap “Add your Railcard download code”, and enter the code from the email.
          </>
        }
        imageSet3={[howToUseStep3, howToUseStep32x, howToUseStep33x]}
        imageSet3Mobile={[howToUseStep3Mobile, howToUseStep3Mobile2x, howToUseStep3Mobile3x]}
        text4="You can now view your Railcard within the Virgin Trains Ticketing app. Nice!"
        imageSet4={[howToUseStep4, howToUseStep42x, howToUseStep43x]}
        imageSet4Mobile={[howToUseStep4Mobile, howToUseStep4Mobile2x, howToUseStep4Mobile3x]}>
        <Row>
          <CenteredCol>
            <CTAButton href={generatePurchaseRailcardURL('VTTWebsite', 'VTTWebsite-FourStepsSection', 'VTTWebsite')}>
              Start saving — buy a Railcard now!
            </CTAButton>
          </CenteredCol>
        </Row>
      </FourSteps>
      <VirginRedHero
        title="Earn Virgin Points when you buy your Railcard with us"
        body={
          <>
            <p>
              Earn 3 points per £1 on all your Virgin Train Ticketing purchases when you sign up to Virgin Red. And you
              can spend your points
              on over 200+ retailers.
            </p>
          </>
        }
        buttonTitle="Find out more"
      />
      <FAQ />
      <DiscoverMoreWays items={[bookTicketsInAdvance, splitTickets, travelOffPeak, groupSave]} />
      {RAILCARDS_LINK && <AppLinksSection url={RAILCARDS_LINK} />}
      <FooterSection />
    </>
  )
}
