import styled from 'styled-components'
import { colors } from '../../style'

export const DividerContainer = styled.div<{ top?: number }>`
  height: 9px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${(p) => p.top ?? 8}px;
  padding-left: 15px;
  gap: 0;
`
export const DividerLine = styled.div`
  height: 1px;
  flex-grow: 1;
  margin: 4px 0 0 0;
  background: ${colors.TERTIARY_CHARCOAL_30};
`
export const ArrowImg = styled.img`
  width: 4px;
  height: 9px;
  object-fit: contain;
`
