import styled from 'styled-components'
import { colors, media, Typography } from '../../style'

export const StyledSection = styled.section`
  border-image: linear-gradient(to top, rgba(34, 36, 78, 0) 0, 35%, rgba(34, 36, 78, 0.2)) 100% 0 0 0 repeat;
  border-width: 7px 0px 0px 0px;
  border-style: solid;
  background: ${colors.COLOUR_WARM_WHITE_100};
`

export const Title = styled(Typography.H1Red)`
  padding-top: 36px;

  @media ${media.desktopAndHigher} {
    padding-top: 65px;
  }
`

export const Subtitle = styled(Typography.IntroBold)`
  padding: 24px 0;

  @media ${media.desktopAndHigher} {
    padding-top: 28px;
    padding-bottom: 24px;
  }
`

export const RedText = styled.span`
  color: ${colors.PRIMARY_RED_100_ACCESSIBILITY};
`

export const BodyItem = styled.div`
  margin-bottom: 28px;

  > :first-child {
    margin-right: 12px;
    width: 23px;
  }

  img {
    vertical-align: baseline;
  }

  @media ${media.desktopAndHigher} {
    display: flex;
  }
`

export const Body = styled(Typography.Intro)`
  padding: 20px 0;
  margin-bottom: 28px;
`

export const ImagesContainer = styled.div`
  display: flex;
  margin: -5px 10px 42px 10px;

  @media ${media.desktopAndHigher} {
    margin-top: -30px;
    margin-left: 50px;
    margin-bottom: -35px;
  }
`

export const ImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transform: rotate(-35deg);

  // Values used to compensate for 35deg rotation
  margin: -40px 10px;
`

export const MiddleImagesWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  &,
  img {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const ImageContainer = styled.div`
  flex: 1;
  width: 100%;
  min-width: 160px;
`

export const Image = styled.img`
  width: 100%;
`
