import styled from 'styled-components'
import { media, Typography, ZIndex } from '../../style'

export const CookieModalContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${ZIndex.cookieConsent};

  @media ${media.tabletAndHigher} {
    padding: 20px 0;
  }
`

export const HeaderText = styled(Typography.H1)`
  text-align: center;
`

export const CookieModalBox = styled.div`
  background-color: #fefefe;
  border: 1px solid #888;
  flex-shrink: 0;
  margin: auto;
  max-width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;

  @media ${media.tabletAndHigher} {
    width: 880px;
    display: unset;
    flex-direction: unset;
    flex-grow: unset;
  }
`

export const Close = styled.div`
  cursor: pointer;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;

  @media ${media.tabletAndHigher} {
    right: 20px;
    top: 30px;
  }
`

export const CookieModalHeader = styled.div`
  padding: 24px 0 16px;

  @media ${media.tabletAndHigher} {
    padding: 40px 0 24px;
  }
`

export const CookieModalContent = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 24px;

  @media ${media.tabletAndHigher} {
    padding: 0 100px;
  }
`

export const CookieModalText = styled.div`
  margin-bottom: 24px;
  @media ${media.tabletAndHigher} {
    margin-bottom: 40px;
  }
`
export const CookieModalBottom = styled.div`
  margin: 0 auto;
  padding: 16px 24px 24px;
  width: 100%;

  @media ${media.tabletAndHigher} {
    padding: 40px 0;
    width: 200px;
  }
`

export const Copy = styled(Typography.Body)`
  display: inline-block;
`
