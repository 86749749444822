import styled from 'styled-components'
import { colors, media } from '../../style'
import { Col } from 'react-bootstrap'

interface TancCsColRightProps {
  background?: boolean
}

export const TandCsContentCol = styled(Col)<TancCsColRightProps>`
  background-color: ${(props) => (props.background ? colors.COLOUR_WHITE : 'transparent')};
  padding: 34px 25px 25px 25px;
  margin-bottom: 24px;
  box-shadow: 0 1px 5px 0 rgb(34 36 78 / 20%), 0 3px 4px 0 rgb(34 36 78 / 12%), 0 2px 4px 0 rgb(34 36 78 / 14%);
  scroll-margin: 200px;
  @media ${media.desktopAndHigher} {
    padding: 64px 40px 40px 40px;
  }
`
