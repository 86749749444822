import styled from 'styled-components'
import { colors, media, Typography } from '../../style'
import svgVttBackground from '../../img/illustration-trainAndPlatform_background.svg'

export const StyledSection = styled.section`
  background: ${colors.PRIMARY_RED_100};
  padding-bottom: 33px;

  background-image: url(${svgVttBackground});
  background-repeat: no-repeat;
  background-position: center bottom;

  @media ${media.desktopAndHigher} {
    padding-bottom: 44px;

    padding-right: 30px; // CSS trick to position background image
    margin-right: -30px;
  }
`

export const VTTLogo = styled.img`
  margin-top: 17px;
  margin-bottom: 30px;

  @media ${media.desktopAndHigher} {
    height: 133px;
    margin-top: 32px;
    margin-bottom: 40px;
  }
`

export const VTTLogoPlaceholder = styled.div`
  height: 133px;
  margin-top: 32px;
  margin-bottom: 40px;
`

export const StyledH1 = styled(Typography.H1)`
  color: ${colors.COLOUR_WHITE};
`
export const StyledH1Bold = styled(Typography.H1Bold)`
  color: ${colors.COLOUR_WHITE};
  margin-top: 17px;
`

export const StyledSurferImg = styled.img`
  width: 271px;

  @media ${media.desktopAndHigher} {
    width: 387px;
    margin-top: -78px;
    float: right;
  }
`

export const ButtonContainer = styled.div`
  margin-top: 50px;

  > :not(:first-child) {
    margin-left: 29px;
  }
`

export const StyledStoreImage = styled.img`
  cursor: pointer;

  &:hover,
  &:active {
    border-radius: 10px;
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.07), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  }
`
