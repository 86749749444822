import React, { createContext, FC, useContext, useState, useEffect, ReactNode } from 'react'
import { getAnalyticsPreferenceCookie, getCookiePreferences } from '../../util/helpers/cookiePreferences'

export interface CookiePolicyProviderProps {
  children?: ReactNode
}

interface CookiePolicyState {
  isCookieBannerVisible: boolean
  isCookieModalVisible: boolean
}

interface CookiePolicyContextModel extends CookiePolicyState {
  hideCookiePolicyBanner: () => void
  showCookiePolicyBanner: () => void
  hideCookiePolicyModal: () => void
  showCookiePolicyModal: () => void
}

const CookiePolicyContext = createContext<CookiePolicyContextModel>({
  isCookieBannerVisible: false,
  isCookieModalVisible: false,
  showCookiePolicyBanner: () => {
    console.error('Cookie policy context is not initialized')
  },
  hideCookiePolicyBanner: () => {
    console.error('Cookie policy context is not initialized')
  },
  showCookiePolicyModal: () => {
    console.error('Cookie policy context is not initialized')
  },
  hideCookiePolicyModal: () => {
    console.error('Cookie policy context is not initialized')
  },
})

const useCookiePolicy = (): CookiePolicyContextModel => useContext(CookiePolicyContext)

const CookiePolicyProvider: FC<CookiePolicyProviderProps> = ({ children }) => {
  const [isCookieBannerVisible, setShowCookieBanner] = useState(false)
  const [isCookieModalVisible, setShowCookieModal] = useState(false)

  useEffect(() => {
    if (getCookiePreferences() === null || getAnalyticsPreferenceCookie() === undefined) {
      setShowCookieBanner(true)
    }
  }, [])

  return (
    <CookiePolicyContext.Provider
      value={{
        isCookieBannerVisible,
        isCookieModalVisible,
        showCookiePolicyBanner: (): void => setShowCookieBanner(true),
        hideCookiePolicyBanner: (): void => setShowCookieBanner(false),
        showCookiePolicyModal: (): void => setShowCookieModal(true),
        hideCookiePolicyModal: (): void => setShowCookieModal(false),
      }}
    >
      {children}
    </CookiePolicyContext.Provider>
  )
}

export { useCookiePolicy, CookiePolicyProvider, CookiePolicyContext }
