import React, { LegacyRef, useCallback, useEffect, useRef, useState } from 'react'
import { useOnScroll } from '../../util/hooks/scroll'
import { isVerticalLineOnScreen } from '../../util/helpers/layout'
import { useIsDesktop } from '../../util/hooks/screen'
import '../../style/customAnimations.css'

interface ScrollAnimationContainerProps {
  animationName: string
  threshold?: number
  animationDuration?: number
  animateOnce?: boolean
  doNotAnimate?: boolean
  onAnimationCompleted?: () => void
  getBoundingRect?: () => DOMRect | undefined
  style?: React.CSSProperties
  children: React.ReactNode
}

export const defaultStyles: React.CSSProperties = {
  animationTimingFunction: 'ease',
  animationFillMode: 'both',
}

const pageLoadDelayInMs = 100

/**
 * Runs the specified animation on it's content when the ScrollAnimationContainer scrolls into view.
 *
 * @param animationName         CSS animation name
 * @param threshold             Threshold at which to trigger animation, as a percentage of the screen height
 * @param animationDuration     The duration of the animation in seconds
 * @param animateOnce           If true, only run the animation once
 * @param doNotAnimate          If true, do not animate at all
 * @param onAnimationCompleted    Called when the animation has completed
 * @param getBoundingRect       Override the bounding rect with which to check for visibility
 * @param style                 Override wrapper style
 * @param children
 * @constructor
 */
export const ScrollAnimationContainer = ({
  animationName,
  threshold,
  animationDuration = 1.5,
  animateOnce = true,
  doNotAnimate = false,
  onAnimationCompleted,
  getBoundingRect,
  style,
  children,
}: ScrollAnimationContainerProps) => {
  const [visible, setVisible] = useState(false)
  const [animationNameInternal, setAnimationNameInternal] = useState(animationName)
  const ref = useRef<HTMLDivElement>()
  const defaultThreshold = useIsDesktop() ? 0.25 : 0

  const checkVisible = useCallback(() => {
    if (doNotAnimate) {
      return
    }
    const offset = window.innerHeight * (threshold ?? defaultThreshold)
    const rect = getBoundingRect && getBoundingRect() ? getBoundingRect() : ref.current?.getBoundingClientRect()
    const rectIsValid = !!rect && (rect.y !== 0 || rect.height !== 0)
    const visible = rectIsValid && isVerticalLineOnScreen(rect.y + offset, rect.height)
    if (visible) {
      setVisible(true)
    } else if (!animateOnce) {
      setVisible(visible)
    }
  }, [doNotAnimate, threshold, defaultThreshold, getBoundingRect, setVisible, animateOnce])

  // Check visibility when component mounts (waiting for 100ms for page to properly layout)
  useEffect(() => {
    const timout = setTimeout(() => {
      checkVisible()
    }, pageLoadDelayInMs)
    return () => clearTimeout(timout)
  }, [checkVisible])

  // Check visibility when page scrolls
  useOnScroll(checkVisible)

  // Ensure changing the animation name does not cause a rerender if animateOnce is true
  useEffect(() => {
    if (!animateOnce) {
      setAnimationNameInternal(animationName)
    }
  }, [animationName, animateOnce, setAnimationNameInternal])

  // Fire the animationCompleted callback after a delay
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (visible) {
      timeout = setTimeout(() => {
        onAnimationCompleted && onAnimationCompleted()
      }, pageLoadDelayInMs + animationDuration * 1000)
    }
    return () => clearTimeout(timeout)
  }, [visible, onAnimationCompleted, animationDuration])

  return (
    <div
      className={visible && !doNotAnimate ? animationNameInternal : ''}
      ref={ref as LegacyRef<HTMLDivElement>}
      style={{
        ...defaultStyles,
        ...style,
        animationDuration: `${animationDuration}s`,
        opacity: visible || doNotAnimate ? style?.opacity ?? 1 : 0,
      }}
    >
      {children}
    </div>
  )
}
