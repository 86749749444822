import React from 'react'
import { Nav } from './Breadcrumb.styles'

export interface BreadcrumbItem {
  text: string
  url: string
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  inHeader?: boolean
}

export const Breadcrumb = ({ items, inHeader = false }: BreadcrumbProps) => (
  <Nav aria-label="breadcrumb" inHeader={inHeader}>
    <span className="breadcrumb-item">
      <a aria-label='home' href="/">Home</a>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.343 20.647a1.14 1.14 0 0 0 1.648 0l7.737-7.97a.976.976 0 0 0 0-1.353L9.99 3.353a1.14 1.14 0 0 0-1.648 0 1.224 1.224 0 0 0 0 1.697l6.74 6.955-6.749 6.954a1.221 1.221 0 0 0 .01 1.688z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>
    </span>
    {items.map((value, index, array) => {
      if (index === array.length - 1) {
        return <span className="breadcrumb-item">{value.text}</span>
      } else {
        return (
          <span className="breadcrumb-item">
            <a aria-label={value.text} href={value.url}>{value.text}</a>
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.343 20.647a1.14 1.14 0 0 0 1.648 0l7.737-7.97a.976.976 0 0 0 0-1.353L9.99 3.353a1.14 1.14 0 0 0-1.648 0 1.224 1.224 0 0 0 0 1.697l6.74 6.955-6.749 6.954a1.221 1.221 0 0 0 .01 1.688z"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </span>
        )
      }
    })}
  </Nav>
)
