import React, { FC } from "react";
import { WeatherWarningSection, WeatherWarningContainer, WeatherWarningHeaderText, WeatherWarningHeaderParagraph, WeatherWarningLink } from "./WeatherWarning.styles";
import { NothingToSeeContainer, NothingToSeeHeader, NothingToSeeIcon, NothingToSeeLink, NothingToSeeParagraph } from "../IndustrialAction/IndustrialAction.styles";
import noTrainIcon from "../../img/icon-brand-no-train.svg";
import { WeatherWarningProps } from "./types";

// This array should contain any action. Follow the pattern of this object when adding any new weather warning.
const weatherWarnings: WeatherWarningProps[] = [
  {
    title: 'Story Ciaran weather warning',
    description: 'Severe weather conditions may impact journeys across Wales, the South of England, the Midlands and North East England. As a result trains may be cancelled, delayed or revised.'
  }
]
export const WeatherWarning: FC = () => {
  return (
    <WeatherWarningContainer>
      <WeatherWarningSection>
      {
        weatherWarnings.length > 0 ? (
          weatherWarnings.map((item) => (
            <>
              <WeatherWarningHeaderText>
                {item.title}
              </WeatherWarningHeaderText>
              <WeatherWarningHeaderParagraph>
                {
                  item.description
                }
              </WeatherWarningHeaderParagraph>
              <WeatherWarningHeaderParagraph>
                Please check the <WeatherWarningLink href={'https://www.nationalrail.co.uk/status-and-disruptions/'} target={'_blank'} aria-label='National Rail Status and Disruptions'>National Rail</WeatherWarningLink> website before you travel.
              </WeatherWarningHeaderParagraph>
            </>
          ))
        ) : (
        <NothingToSeeContainer>
          <NothingToSeeIcon src={noTrainIcon}/>
          <NothingToSeeHeader>Nothing to see here…</NothingToSeeHeader>
          <NothingToSeeParagraph>
          There may be an error with the content of this page — Please check the <NothingToSeeLink target={'_blank'} href={'https://www.nationalrail.co.uk/status-and-disruptions/'} aria-label='National Rail Status and Disruptions' >National Rail</NothingToSeeLink> website before you travel.
          </NothingToSeeParagraph>
        </NothingToSeeContainer>
        )
      }
      </WeatherWarningSection>
    </WeatherWarningContainer>
  )
}
