import Cookies from 'js-cookie'
import { COOKIE_DOMAIN } from '../../config'

// Declaring this on @types/index.d.ts doesn't work
declare global {
  interface Window {
    dataLayer: Array<object>
  }
}

export const cookieName = 'user-cookie-preferences'

export enum CookiePreferenceId {
  Analytics = 'analytics_storage',
}

export type CookiePreferences = {
  [K in CookiePreferenceId]: boolean
}

enum Trigger {
  PageLoad = 'page-load',
  AcceptAll = 'accept-all',
  SavePreferences = 'save-preferences',
}

export const getDefaultCookiePreferences = (): CookiePreferences => ({
  [CookiePreferenceId.Analytics]: false,
})

export const getAnalyticsPreferenceCookie = (): boolean | undefined => {
  const cookie = Cookies.get(cookieName)
  return cookie ? JSON.parse(cookie).analytics_storage : undefined
}

export const getCookiePreferences = (): CookiePreferences | null => {
  const cookie = Cookies.get(cookieName)
  let savedPreferences = null
  if (cookie) {
    try {
      savedPreferences = JSON.parse(cookie)
    } catch (e) {
      console.error(`Failed to parse cookie preferences cookie: ${(e as Error).message}`)
    }
  }

  // Make sure we initialise cookie preferences with safe defaults for BC when we add new preference keys
  return savedPreferences ? { ...getDefaultCookiePreferences(), ...(savedPreferences as any) } : null
}

export const setConsentSettings = (cookiePrefs?: CookiePreferences): void => {
  const cookie = cookiePrefs || (getCookiePreferences() ?? getDefaultCookiePreferences())
  gtag('consent', 'update', {
    analytics_storage: cookie.analytics_storage ? 'granted' : 'denied',
  })
}

const persistCookiePreferences = (preferences: CookiePreferences, trigger: Trigger): void => {
  Cookies.set(cookieName, JSON.stringify(preferences), {
    expires: 365,
    domain: COOKIE_DOMAIN,
  })
  setConsentSettings(preferences)
}

export const setCookiePreferences = (preferences: CookiePreferences): void => {
  persistCookiePreferences(preferences, Trigger.SavePreferences)
}

export const setAllCookiePreferences = (): void => {
  persistCookiePreferences(
    {
      [CookiePreferenceId.Analytics]: true,
    },
    Trigger.AcceptAll
  )
}
