import React, { FC } from 'react'
import { TandCsContentCol } from './TandCsSectionItem.style'

interface SectionProp {
  children?: any
  sectionNumber?: string
  id?: string
  onClick?: () => void
}

export const TandCsSectionItem: FC<SectionProp> = (props) => {
  return (
    <TandCsContentCol onClick={props.onClick} background={true} xs={12} id={props.id || props.sectionNumber}>
      {props.children && props.children}
    </TandCsContentCol>
  )
}
