import { useEffect, useState } from 'react'
import { breakpoints } from '../../style/responsive'

export function useScreenDimensions(): { width: number; height: number } {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const resizeHandler = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', resizeHandler)

    // Remove the event listener on unmount
    return () => window.removeEventListener('scroll', resizeHandler)
  }, [])

  return {
    width,
    height,
  }
}

export function useIsDesktop() {
  const { width } = useScreenDimensions()
  return width >= breakpoints.desktopMin
}
